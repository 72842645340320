type FolderProps = {
  name: string;
  onClick: () => void;
};

export const Folder: React.FC<FolderProps> = ({ name, onClick }) => {
  return (
    <div
      className="folder-modal-folder b-secondary b-secondary-hover"
      onClick={onClick}
    >
      <i className="far fa-folder-open folder-modal-folder-icon"></i> {name}
    </div>
  );
};
