import React from "react";
import DocumentSection from "./components/DocumentSection/DocumentSection";
import StatusBar from "./components/StatusBar/StatusBar";
import Text from "../../../../uiComponents/Text";

import "./styles.css";

type Section = {
  section: string;
};

type Data = {
  sections: Section[];
};

type LeftSectionProps = {
  data: Data;
  currentSection: number;
  setCurrentSection: (section: number) => void;
};

const LeftSection: React.FC<LeftSectionProps> = ({
  data,
  currentSection,
  setCurrentSection,
}) => {
  const onClick = (index: number) => {
    if (currentSection > index) {
      setCurrentSection(index + 1);
    }
  };

  const checkSection = currentSection > data.sections.length;

  return (
    <div className="left-section white">
      <Text className="margin-t">Progreso</Text>
      <StatusBar progress={currentSection / (data.sections.length + 1)} />

      <div className="left-section-title-div">
        <i className="fas fa-list-ol left-section-icon primary"></i>
        <Text size="medium">Secciones </Text>
      </div>

      <div className="left-section-section-div">
        <DocumentSection
          name={"Introducción"}
          number={0}
          currentSection={currentSection}
          onClick={() => {
            setCurrentSection(0);
          }}
        />
        {data.sections.map((section, index) => {
          return (
            <DocumentSection
              name={section.section}
              currentSection={currentSection}
              number={index + 1}
              onClick={() => {
                onClick(index);
              }}
            />
          );
        })}
        <DocumentSection
          name={"Revisión y Descarga"}
          number={data.sections.length + 1}
          currentSection={currentSection}
          checkSection={checkSection}
          onClick={() => {}} // P
        />
      </div>
    </div>
  );
};

export default LeftSection;
