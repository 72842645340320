import React, { useState } from "react";
import IconButton from "../../../../uiComponents/IconButton/IconButton";
import Modal from "../../../../uiComponents/Modal/Modal";
import { useAuth0 } from "@auth0/auth0-react";
import { resetModals, setModal } from "../../../../redux/modal/modal.actions";
import Loader from "react-loader-spinner";
import { APIPost } from "../../../../Services/authenticated";
import { connect } from "react-redux";

import "./styles.css";

type FolderNameModalProps = {
  resetModals: () => void;
  setModal: (modal: any) => void;
  data: any;
};

const FolderNameModal: React.FC<FolderNameModalProps> = ({
  resetModals,
  setModal,
  data,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [folderName, setFolderName] = useState<string | null>(null);

  const { getAccessTokenSilently } = useAuth0();

  const onSaveFolder = async () => {
    setIsLoading(true);

    const payload = {
      newFolderName: folderName,
      folderId: data.folder.folderId,
    };

    try {
      const accessToken = await getAccessTokenSilently();
      const res = await APIPost(
        "/folders/update-folder-name",
        accessToken,
        payload
      );
      if (res.folderUpdated) {
        window.location.reload();
      } else {
        setIsLoading(false);
        alert("Error, intentelo nuevamente");
      }
    } catch (e) {
      setIsLoading(false);
      alert("Error, intentelo nuevamente");
    }
  };

  return (
    <Modal>
      <div className="folder-name-modal-body">
        <i
          className="far fa-window-close folder-name-modal-close"
          onClick={() => resetModals()}
        ></i>
        <p className="bold folder-name-modal-title">Modificar Carpeta</p>
        {isLoading && (
          <div style={{ marginTop: "30px", marginBottom: "20px" }}>
            <Loader type="Bars" color="#e8eeff" height={40} width={40} />
            <span>Cargando ...</span>
          </div>
        )}
        {!isLoading && (
          <div>
            <p className="folder-name-modal-input-label primary">
              Nombre de la carpeta:
            </p>
            <input
              type="text"
              className="folder-name-modal-input"
              maxLength={40}
              onChange={(e) => {
                setFolderName(e.target.value);
              }}
              defaultValue={data.folder.folderName}
            />
            <IconButton
              label="Guardar"
              icon="fas fa-plus"
              className="folder-name-modal-create"
              color="secondary"
              onClick={() => onSaveFolder()}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    data: state.modal.folderNameModal,
  };
};

const mapDispatchToProps = {
  resetModals,
  setModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(FolderNameModal);
