import React, { useMemo } from "react";
import { MRT_ColumnDef } from "material-react-table";
import TableEntryName from "../../../uiComponents/AndesTable/TableEntries/Name.component";
import {
  faTrashAlt,
  faDownload,
  faCheckCircle,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import TableDate from "../../../uiComponents/AndesTable/TableEntries/TableDate.component";
import DaysToExpiration from "../TableEntries/DaysToExpiration.component";
import TableActions, {
  TableAction,
} from "../../../uiComponents/AndesTable/TableEntries/TableActions.components";
import {
  downloadDocumentFromS3,
  findUserNameById,
  findUserPhotoUrl,
} from "../../../Utils/commonTableActions";
import { modalOpen } from "../../../Utils/modalMethods";
import AndesTable, {
  renderHeader,
} from "../../../uiComponents/AndesTable/AndesTable.component";
import { ProfilePicAndName } from "../../../uiComponents/ProfilePicAndName/ProfilePicAndName";

const alarmTableActions: TableAction[] = [
  {
    name: "Descargar",
    icon: faDownload,
    onClickAsync: (data: any, accessToken: any) =>
      downloadDocumentFromS3(data, accessToken),
  },
  {
    name: "Marcar como renovado",
    icon: faCheckCircle,
    onClickWithParams: (data: any) =>
      modalOpen({
        confirmModal: {
          show: true,
          message: "Confirme Renovación",
          submessage:
            "¿Seguro desea marcar el documento seleccionado como renovado?",
          disclaimer: "",
          document: data,
          isAsync: true,
          confirmMethodName: "markDocumentAlarmAsHandled",
          fileName: data.fileName,
        },
      }),
  },
  {
    name: "Editar Fecha",
    icon: faEdit,
    onClickWithParams: (data: any) =>
      modalOpen({
        expirationModal: {
          show: true,
          document: data,
          shouldShowSuccess: false,
        },
      }),
  },
  {
    name: "Eliminar",
    icon: faTrashAlt,
    onClickWithParams: (data: any) =>
      modalOpen({
        confirmModal: {
          show: true,
          message: "Confirme eliminación",
          submessage: "¿Seguro desea eliminar el vencimiento seleccionado?",
          disclaimer:
            "*No se eliminara el documento, solamente el vencimiento.",
          document: data,
          isAsync: true,
          confirmMethodName: "deleteDocumentAlarm",
          fileName: data.fileName,
        },
      }),
    color: "light-red",
  },
];

type Props = {
  documents: any[];
};

const AlarmTable: React.FC<Props> = ({ documents }) => {
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "fileName",
        header: "Nombre",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        Cell: ({ cell }: any) => {
          return (
            <TableEntryName
              name={cell.getValue()}
              image={
                cell.row.original.isExpirationHandled
                  ? "alarm-renewed.png"
                  : "alarm.png"
              }
            />
          );
        },
        maxSize: 400,
        minSize: 310,
      },
      {
        accessorKey: "expirationDate",
        header: "Vencimiento",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 190,
        maxSize: 170,
        Cell: ({ cell }: any) => {
          return (
            <TableDate date={cell.getValue()} fallback="Sin definir" hideTime />
          );
        },
      },
      {
        accessorKey: "daysToExpiration",
        header: "Dias para vencer",
        minSize: 210,
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        Cell: ({ cell }: any) => {
          return (
            <DaysToExpiration
              date={cell.getValue()}
              isHandled={cell.row.original.isExpirationHandled || false}
            />
          );
        },
        sortDescFirst: false,
      },
      {
        accessorKey: "userId",
        header: "Creado por",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 220,
        Cell: ({ cell }: any) => {
          const userPhotoUrl = findUserPhotoUrl(cell.getValue());
          const userName = findUserNameById(cell.getValue());
          return <ProfilePicAndName image={userPhotoUrl} name={userName} />;
        },
      },
      {
        header: "Acciones",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        enableColumnActions: false,
        minSize: 220,
        Cell: ({ cell }) => (
          <TableActions
            actions={alarmTableActions}
            rowData={cell.row.original}
          />
        ),
      },
    ],
    []
  );

  return (
    <AndesTable
      documents={documents}
      columns={columns}
      initialSort={[{ id: "daysToExpiration", desc: false }]}
    />
  );
};

export default AlarmTable;
