type Answers = {
  [key: string]: {
    [key: string]: any;
  };
};

type SetAnswers = React.Dispatch<React.SetStateAction<Answers>>;

const emptyQuestionEraser = (
  answers: Answers,
  setAnswers: SetAnswers,
  currentSection: number
): void => {
  if (sessionStorage.activeInputs) {
    const activeInputs: string[] =
      JSON.parse(sessionStorage.activeInputs) || [];
    const sectionName = `section${currentSection}`;
    const newAnswers = { ...answers };
    let filteredAnswersObject: { [key: string]: any } = {};

    activeInputs.forEach((e) => {
      filteredAnswersObject[e] = newAnswers[sectionName][e];
    });

    newAnswers[sectionName] = filteredAnswersObject;
    setAnswers(newAnswers);
    sessionStorage.removeItem("activeInputs");
  }
};

export default emptyQuestionEraser;
