import React from "react";
import Person from "../../../../../../components/InputTypes/Person/Person";
import Path from "../../../../../../components/InputTypes/Path/Path";
import QuestionMapper from "../../../../../../components/InputTypes/QuestionMapper/QuestionMapper";
import { connect } from "react-redux";
import { setAnswers } from "../../../../../../redux/answers/answers.actions";
import emptyQuestionEraser from "../../../../../../Utils/EmptyQuestionEraser";
import "./styles.css";

type QuestionsSectionProps = {
  data: any;
  currentSection: any;
  answers: any;
  setAnswers: any;
  setCurrentSection: any;
};

const QuestionsSection: React.FC<QuestionsSectionProps> = ({
  data,
  currentSection,
  answers,
  setAnswers,
  setCurrentSection,
}) => {
  const thisSection = data.sections[currentSection - 1];
  const type = thisSection.type;
  const sectionId = thisSection.id;

  return (
    <form
      style={{ marginTop: 40 }}
      onSubmit={() => {
        emptyQuestionEraser(answers, setAnswers, currentSection);
      }}
      className="questions-form"
    >
      {type === "questions" && (
        <QuestionMapper
          data={data}
          currentSection={currentSection}
          sectionId={sectionId}
          setCurrentSection={setCurrentSection}
        />
      )}
      {type === "person" && (
        <Person
          data={data}
          currentSection={currentSection}
          sectionId={sectionId}
          setCurrentSection={setCurrentSection}
        />
      )}
      {type === "path" && (
        <Path
          data={data}
          currentSection={currentSection}
          sectionId={sectionId}
          setCurrentSection={setCurrentSection}
        />
      )}
      <div className="next-section-button-div ">
        <div>
          {type !== "person" && currentSection > 1 && (
            <span
              className="prev-section-button primary"
              onClick={() => {
                setCurrentSection(currentSection - 1);
              }}
            >
              Anterior
            </span>
          )}
        </div>
        {type !== "person" && (
          <div className="next-section-div">
            <input
              type="submit"
              value="Siguiente"
              formAction={`/redirect/${currentSection}`}
              className="next-section-button b-secondary"
            ></input>
            {/* <div className="next-section-trapezoid"></div> */}
          </div>
        )}
      </div>
    </form>
  );
};

const mapStateToProps = (state: any) => {
  return {
    answers: state.answers,
  };
};

const mapDispatchToProps = {
  setAnswers,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsSection);
