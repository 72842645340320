import React, { useMemo } from "react";
import { MRT_ColumnDef } from "material-react-table";
import TableEntryName from "../../../uiComponents/AndesTable/TableEntries/Name.component";
import TableDate from "../../../uiComponents/AndesTable/TableEntries/TableDate.component";
import TableActions, {
  TableAction,
} from "../../../uiComponents/AndesTable/TableEntries/TableActions.components";
import AndesTable, {
  renderHeader,
} from "../../../uiComponents/AndesTable/AndesTable.component";
import {
  downloadDocumentFromS3,
  findUserNameById,
  findUserPhotoUrl,
} from "../../../Utils/commonTableActions";
import { modalOpen, setAppError } from "../../../Utils/modalMethods";
import {
  faTrashAlt,
  faDownload,
  faPlus,
  faStopwatch20,
  faFileWord,
} from "@fortawesome/free-solid-svg-icons";
import TableEntryAproval from "../TableEntries/Aproval";
import { useDispatch } from "react-redux";
import { setEditorDocument } from "../../../redux/slices/EditorDataSlice";
import { useHistory } from "react-router-dom";
import { ProfilePicAndName } from "../../../uiComponents/ProfilePicAndName/ProfilePicAndName";

type Props = {
  documents: any[];
  hideSearch?: boolean;
};

const DocumentsManagerTable: React.FC<Props> = ({ documents, hideSearch }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const getImageName = (format: string) => {
    if (format === "pdf") {
      return "pdf2-icon.png";
    }
    if (format === "xls" || format === "xlsx") {
      return "excel-icon.png";
    }
    if (format === "ppt" || format === "pptx") {
      return "ppt-icon.png";
    }
    if (
      format === "png" ||
      format === "jpg" ||
      format === "jpeg" ||
      format === "heic"
    ) {
      return "pic-icon.png";
    }

    return "word-icon.png";
  };

  const alarmTableActions: TableAction[] = [
    {
      name: "Editar documento",
      icon: faFileWord,
      onClickWithParams: (data: any) => {
        if (
          (data.format && data.format === "word") ||
          (data.format && data.format === "doc")
        ) {
          dispatch(setEditorDocument(data));
          history.push("/dashboard/docx-editor");
        } else {
          setAppError(
            "Solo los archivos Word (docx) pueden editarse",
            "Si quiere editar este archivo descárguelo"
          );
        }
      },
    },
    {
      name: "Descargar",
      icon: faDownload,
      onClickAsync: (data: any, accessToken: any) =>
        downloadDocumentFromS3(data, accessToken),
    },
    {
      name: "Datos del documento",
      icon: faPlus,
      onClickWithParams: (data: any) =>
        modalOpen({
          documentDetailModal: {
            show: true,
            document: data,
          },
        }),
    },
    {
      name: "Definir Vencimiento",
      icon: faStopwatch20,
      onClickWithParams: (data: any) =>
        modalOpen({
          expirationModal: {
            show: true,
            document: data,
            shouldShowSuccess: false,
          },
        }),
    },
    {
      name: "Eliminar",
      icon: faTrashAlt,
      onClickWithParams: (data: any) =>
        modalOpen({
          confirmModal: {
            show: true,
            message: "Confirme eliminación",
            submessage: "¿Seguro desea eliminar el documento seleccionado?",
            disclaimer:
              "*No se podrá deshacer, asegursese que no necesitará el documento en el futuro.",
            document: data,
            isAsync: true,
            confirmMethodName: "deleteDocumentAndReference",
            fileName: data.fileName,
          },
        }),
      color: "light-red",
    },
  ];

  const columns = useMemo<MRT_ColumnDef<any>[]>(() => {
    return [
      {
        accessorKey: "fileName",
        header: "Nombre",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        Cell: ({ cell }: any) => {
          return (
            <TableEntryName
              name={cell.getValue()}
              image={getImageName(cell.row.original.format || "")}
              imageLarge
              subtitle={cell.row.original.format}
            />
          );
        },
        maxSize: 400,
        minSize: 310,
      },
      {
        accessorKey: "status",
        header: "Status",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 170,
        maxSize: 170,
        Cell: ({ cell }: any) => {
          return (
            <TableEntryAproval
              status={cell.getValue()}
              approvedBy={cell.row.original.approvedBy}
              document={cell.row.original}
            />
          );
        },
      },
      {
        accessorKey: "dateCreated",
        header: "Creación",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 160,
        maxSize: 160,
        Cell: ({ cell }: any) => {
          return <TableDate date={cell.getValue()} fallback="Sin definir" />;
        },
      },
      {
        accessorKey: "expirationDate",
        header: "Vence",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 145,
        maxSize: 145,
        Cell: ({ cell }: any) => {
          return (
            <TableDate
              date={cell.getValue()}
              fallback="Sin definir"
              color="b-light-red"
              hideTime
            />
          );
        },
      },
      {
        accessorKey: "userId",
        header: "Creado por",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 180,
        Cell: ({ cell }: any) => {
          const userPhotoUrl = findUserPhotoUrl(cell.getValue());
          const userName = findUserNameById(cell.getValue());
          return <ProfilePicAndName image={userPhotoUrl} name={userName} />;
        },
      },
      {
        header: "Acciones",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        enableColumnActions: false,
        minSize: 190,
        Cell: ({ cell }) => (
          <TableActions
            actions={alarmTableActions}
            rowData={cell.row.original}
          />
        ),
      },
    ];
  }, []);

  return (
    <AndesTable
      documents={documents}
      columns={columns}
      initialSort={[{ id: "dateCreated", desc: true }]}
      hideSearch={hideSearch}
    />
  );
};

export default DocumentsManagerTable;
