import React, { useEffect, useState } from "react";
import Text from "../../../uiComponents/Text";
import "../styles.css";
import "./styles.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, differenceInDays } from "date-fns";
import es from "date-fns/locale/es"; // the locale you want
import { setAppError } from "../../../Utils/modalMethods";
registerLocale("es", es); // register it with the name you want

export const formatDate = (date: Date): string => {
  if (date) return format(date, "yyyyMMdd");
  return "";
};

const mes = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];

type DateCalculatorProps = {
  question: any;
  onChange: any;
  sectionId: any;
  currentSectionAnswers: any;
  currentAnswer: any;
};

const DateCalculator: React.FC<DateCalculatorProps> = ({
  question,
  onChange,
  sectionId,
  currentSectionAnswers,
  currentAnswer,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    if (currentAnswer?.startDateData) {
      setStartDate(new Date(currentAnswer.startDateData));
    }
    if (currentAnswer?.endDateData) {
      setEndDate(new Date(currentAnswer.endDateData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dateFormater = (e: any) => {
    let dateFormat = `${new window.Date(e).getUTCDate()} de ${
      mes[new window.Date(e).getUTCMonth()]
    } del ${new window.Date(e).getUTCFullYear()}`;
    return dateFormat;
  };

  const handleStartDate = (newDate: any) => {
    if (!endDate) {
      setStartDate(newDate);
      onChange({
        [sectionId]: {
          ...currentSectionAnswers,
          [question.id]: {
            question: question.question,
            answer: {
              ...currentAnswer,
              startDate: dateFormater(newDate),
              startDateData: newDate,
            },
          },
        },
      });
      return;
    }
    const difference = differenceInDays(endDate, newDate);

    if (difference < 0) {
      setAppError(
        "Fecha Iválida",
        "Elija una fecha anterior a la de finalización"
      );
      return;
    }
    setStartDate(newDate);
    onChange({
      [sectionId]: {
        ...currentSectionAnswers,
        [question.id]: {
          question: question.question,
          answer: {
            ...currentAnswer,
            startDate: dateFormater(newDate),
            days: difference + 1,
            startDateData: newDate,
          },
        },
      },
    });
  };

  const handleEndDate = (newDate: any) => {
    if (!startDate) {
      setEndDate(newDate);
      onChange({
        [sectionId]: {
          ...currentSectionAnswers,
          [question.id]: {
            question: question.question,
            answer: {
              ...currentAnswer,
              endDate: dateFormater(newDate),
              endDateData: newDate,
            },
          },
        },
      });

      return;
    }
    const difference = differenceInDays(newDate, startDate);

    if (difference < 0) {
      setAppError("Fecha Iválida", "Elija una fecha posterior a la de inicio");
      return;
    }
    setEndDate(newDate);
    onChange({
      [sectionId]: {
        ...currentSectionAnswers,
        [question.id]: {
          question: question.question,
          answer: {
            ...currentAnswer,
            endDate: dateFormater(newDate),
            days: difference + 1,
            endDateData: newDate,
          },
        },
      },
    });
  };

  return (
    <div className="date-calculator">
      <div className="date-calculator-box">
        <div className="date-calculator-input">
          <p> - Fecha de inicio:</p>
          <DatePicker
            selected={startDate}
            className="question-format-input"
            locale="es"
            onChange={(e) => handleStartDate(e)}
            dateFormat={"dd/MM/yyyy"}
            required
          />
        </div>
        <div className="date-calculator-input">
          <p> - Fecha de Finalización:</p>
          <DatePicker
            selected={endDate}
            onChange={(date) => handleEndDate(date)}
            className="question-format-input date-calculatorin"
            locale="es"
            dateFormat={"dd/MM/yyyy"}
            required
          />
        </div>
      </div>
      <p className="date-calculator-days">
        - Duración total:{" "}
        <span className="table-green bold">
          {startDate && endDate
            ? differenceInDays(endDate, startDate) + 1
            : "0"}{" "}
          Días
        </span>
      </p>
      <Text
        blue
        size="small"
        className="margin-v question-format-info-bottom"
        style={{ marginBottom: 20 }}
      >
        *La duración es calculada incluyendo ambas fechas, la de inicio y la de
        finalización
      </Text>
    </div>
  );
};

export default DateCalculator;
