import { Box, Stack, Typography } from "@mui/material";
import { colorPallette } from "../../Common/theme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const clients = [
  {
    name: "Eduardo Stulich",
    businessName: "Soldati Propiedades",
    photo: "/images/eduardo-sol-removebg-preview.png",
    logo: "https://andes-photos.s3.us-east-2.amazonaws.com/soldati-company-logo.png",
    opinion:
      "Nuestros Agentes asociados aman la aplicación, por la velocidad y facilidad con la que generan los documentos. A su vez, es muy ágil la interacción con los desarrolladores al momento de hacer correcciones necesarias a los documentos .",
  },

  {
    name: "Sofia Derudder",
    businessName: "Flechabus",
    photo: "/images/SofiaFlechabus-removebg-preview.png",
    logo: "https://andes-photos.s3.us-east-2.amazonaws.com/flecha-bus-company-logo.png",
    opinion:
      "AndesDocs nos permitió optimizar nuestros procesos y ahorrar costos en la firma de contratos en todo el país. Nuestro departamento de legales y comercial lo utilizan a diario . La herramienta es intuitiva, ágil y potente.",
  },
  {
    name: "Iuri Izrastzoff",
    businessName: "Izrastzoff Inmobiliaria",
    photo: "/images/Iuri-izra-removebg-preview.png",
    logo: "https://andes-photos.s3.us-east-2.amazonaws.com/IZR-home-logo.png",
    opinion:
      "Desde que implementamos Andes Docs, eficientizamos nuestra gestión contractual y logramos minimizar los errores en la confección de contratos, ahorrando tiempo y dinero.",
  },
];

const stars = [1, 2, 3, 4, 5];

export const ClientsCarousel: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
  };

  return (
    <Stack
      sx={{
        width: "80%",
        gap: 5,
        pb: 10,
        pt: 0,
        // overflow: "hidden",
        position: "relative",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 10,
        }}
      ></Box>
      <Box maxWidth={"100%"}>
        <Slider {...settings}>
          {clients.map((client, index) => (
            <Box
              key={index}
              gap={2}
              sx={{
                alignItems: "center !important",
                display: "flex !important",
                flexDirection: "column",
                "@media (max-width: 600px)": {
                  py: 2,
                },
              }}
            >
              <Stack
                justifyContent="center"
                alignItems={"center"}
                sx={{
                  minHeight: { xs: 500, sm: 250 },
                }}
              >
                <Typography
                  fontWeight="bold"
                  fontStyle={"italic"}
                  maxWidth="1200px"
                  sx={{ fontSize: "1.2rem" }}
                  color={colorPallette.dark}
                >
                  "{client.opinion}"
                </Typography>
                <Stack direction={"row"} gap={1} mt={5}>
                  {stars.map((star, index) => (
                    <FontAwesomeIcon
                      key={index}
                      icon={faStar}
                      color={colorPallette.primary}
                      fontSize={13}
                    />
                  ))}
                </Stack>
              </Stack>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  pt: 5,
                  justifyContent: "space-between",
                  flexDirection: { xs: "column", sm: "row" },
                  width: "500px",
                  maxWidth: "90%",
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    sx={{
                      width: "100px",
                      borderRadius: "5%",
                      mr: 3,
                    }}
                    src={process.env.PUBLIC_URL + client.photo}
                  />
                  <Stack sx={{ textAlign: "left" }}>
                    <Typography fontWeight={"bold"}>{client.name}</Typography>
                    <Typography color={colorPallette.primary}>
                      {client.businessName}
                    </Typography>
                  </Stack>
                </Stack>
                <Box
                  component={"img"}
                  sx={{
                    width: "120px",
                    borderRadius: "5%",
                  }}
                  src={process.env.PUBLIC_URL + client.logo}
                />
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    </Stack>
  );
};
