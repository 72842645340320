import React from "react";
import "../styles.css";
import "./styles.css";
import Text from "../../../uiComponents/Text";
import { Card } from "./Card";

type BigOptionsProps = {
  question: any;
  onChange: any;
  currentAnswer: any;
  sectionId: any;
  currentSectionAnswers: any;
};

const BigOptions: React.FC<BigOptionsProps> = ({
  question,
  onChange,
  currentAnswer,
  sectionId,
  currentSectionAnswers,
}) => {
  // this sets redux and session state answers
  const reduxAnswerSetter = (newAnswer: any) => {
    onChange({
      [sectionId]: {
        ...currentSectionAnswers,
        [question.id]: {
          question: question.question,
          answer: newAnswer,
        },
      },
    });
  };

  //this method handles clicks

  const handleClick = (newItem: any) => {
    reduxAnswerSetter(newItem);
  };
  return (
    <div>
      <Text
        size="small"
        className=" question-format-info"
        style={{ marginTop: 10 }}
      >
        *Seleccionar solo 1 opción
      </Text>
      {question.options.map((e: any) => {
        return (
          <Card
            item={e}
            handleClick={handleClick}
            key={e}
            currentAnswer={currentAnswer}
          />
        );
      })}
      <input
        required
        type="text"
        value={currentAnswer}
        className="big-options-invisible"
        maxLength={350}
        tabIndex={-1}
      />
    </div>
  );
};

export default BigOptions;
