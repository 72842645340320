import {
  AlignmentType,
  Footer,
  Header,
  ImageRun,
  PageNumber,
  Paragraph,
  TextRun,
} from "docx";
import { clickApartsLogo } from "../docImages/clickAparts";

export const styles_clickAparts = {
  companyId: ["12395"],
  companyName: "Click Aparts",
  font: "Arial",
  titleSize: 21,
  textSize: 21,
  lineSpacing: 450,
  marginRight: 880,
  marginLeft: 880,
  beforeParagraph: 300,

  header: new Header({
    children: [
      new Paragraph({
        spacing: {
          before: 0,
          after: 450,
        },
        alignment: AlignmentType.RIGHT,
        children: [
          new ImageRun({
            data: clickApartsLogo,
            transformation: {
              width: 100,
              height: 40,
            },
          }),
        ],
      }),
    ],
  }),

  footer: new Footer({
    children: [
      new Paragraph({
        spacing: {
          before: 300,
          after: 0,
        },
        alignment: AlignmentType.CENTER,
        children: [],
      }),
      new Paragraph({
        alignment: AlignmentType.RIGHT,
        children: [
          new TextRun({
            children: [PageNumber.CURRENT],
          }),
        ],
      }),
    ],
  }),
};
