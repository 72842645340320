import "./TrustedCompanies.styles.css";
import CompaniesGrid from "../../../uiComponents/CompaniesGrid/CompaniesGrid";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";

const links = [
  "https://andes-photos.s3.us-east-2.amazonaws.com/flecha-bus-company-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/soldati-company-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/andesmar-home-logos.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/IZR-home-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/okeefe-logo-home.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/click-aparts-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/fabian-company-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/chevallier-logo-home.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/guimat-logo-home.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/cata-logo-home.png",
];

const TrustedCompanies = () => {
  return (
    <Box sx={{ mb: { xs: "2px", md: "20px" } }}>
      <Box className="center-text f-standard" mb={"20px"}>
        <FontAwesomeIcon icon={faBolt} className="mr1 primary" />
        Confían en <span className="primary roboto bold">Andes Docs:</span>
      </Box>
      <CompaniesGrid urls={links} />
    </Box>
  );
};

export default TrustedCompanies;
