import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "../../../../../uiComponents/IconButton/IconButton";
import {
  faCheckCircle,
  faHourglassHalf,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import "./styles.css";
import TableDate from "../../../../../uiComponents/AndesTable/TableEntries/TableDate.component";
import Image from "../../../../../uiComponents/Image/Image";
import SignerActions from "./SignerActions";
import { modalOpen } from "../../../../../Utils/modalMethods";
import { ProfilePicAndName } from "../../../../../uiComponents/ProfilePicAndName/ProfilePicAndName";
import { findUserNameById } from "../../../../../Utils/commonTableActions";

const getStatus = (status: string) => {
  if (status === "signed") {
    return "Firma Completa";
  }
  return "Pendiente de Firma";
};

const getSignerStatus = (status: string) => {
  if (status === "signed") {
    return "Ya firmó";
  }
  return "Aun no firmó";
};

const getIcon = (status: string) => {
  if (status === "signed") {
    return faCheckCircle;
  }
  return faHourglassHalf;
};

type SignatureSummaryProps = {
  data: any;
};

const SignatureSummary: React.FC<SignatureSummaryProps> = ({ data }) => {
  const userName = findUserNameById(data.userId);

  return (
    <div className="sig-summary">
      <div className="sig-title">
        <Image name="signature.png" className="sig-pic" />
        {data.name}
      </div>
      <div>
        <div className="sig-creator flex">
          <span className={`margin-icon primary`}>
            {getStatus(data.status)}
          </span>
          <FontAwesomeIcon
            icon={getIcon(data.status)}
            className={`margin-icon ${
              data.status === "signed" ? "primary" : "light-red"
            }`}
          />
        </div>
        <div className="sig-creator flex sig-date">
          <TableDate date={data.dateCreated} />
        </div>
        <div className="sig-creator flex">
          <ProfilePicAndName name={userName} image={data.creatorPhotoUrl} />
        </div>
      </div>
    </div>
  );
};

const Signers: React.FC<any> = ({ signers }) => {
  return (
    <div className="sig-signer-wrapper">
      <span className="bold">Firmantes:</span>
      {signers &&
        signers.length > 0 &&
        signers.map((sig: any) => {
          return (
            <div>
              <div
                className="sig-signer"
                style={{
                  opacity: sig.status === "signed" ? 1 : 0.5,
                  color: sig.status === "signed" ? "#50c950" : "#5b55a0",
                }}
              >
                <div className="sig-square secondary">
                  {sig.status === "signed" && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="sig-check green2"
                    />
                  )}
                </div>
                <div className="sig-name">{sig.name}</div>
              </div>
              <div>
                <div className="email">
                  <span className="primary">{sig.email} </span>
                  <span className="sig-status-2">
                    ({getSignerStatus(sig.status)})
                  </span>
                </div>
              </div>
              <SignerActions data={sig} signerCount={signers.length} />
            </div>
          );
        })}
    </div>
  );
};

const SignatureActions: React.FC<any> = ({ data, isModal }) => {
  return (
    <div className="sig-actions-wrapper">
      <div className="bold">Acciones:</div>
      <a
        className="sig-button icon-button b-primary b-primary-hover elipsis"
        target="_blank"
        href={data.signed_file}
        rel="noreferrer"
      >
        <i className={`fas fa-download icon-button-icon`} />
        Descargar Firmado
      </a>
      {!isModal && (
        <IconButton
          icon="fas fa-trash-alt"
          label={"Eliminar"}
          className="sig-button"
          color="secondary"
          onClick={() =>
            modalOpen({
              deleteSignatureModal: {
                show: true,
                signature: data,
              },
            })
          }
        />
      )}
    </div>
  );
};

const SignatureRow: React.FC<any> = ({ data, reload, isModal = false }) => {
  if (!data || !data.signers) {
    return <></>;
  }

  return (
    <div className="sig-wrapper">
      <SignatureSummary data={data} />
      <Signers signers={data.signers} />
      <SignatureActions data={data} reload={reload} isModal={isModal} />
    </div>
  );
};

export default SignatureRow;
