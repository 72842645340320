import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import pattern from "../../Assets/svg/pattern.svg";

const GlowingPattern: React.FC = () => {
  const [glowPattern, setGlowPattern] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setGlowPattern((prevBox: number) => (prevBox === 0 ? 1 : 0));
    }, 1500); // Change every 500ms
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {/* left side */}
      <Box
        component={"img"}
        sx={{
          width: "1800px",
          opacity: glowPattern,
          transition: "all 1.5s ease-in-out",
          maxWidth: "100%",
          position: "absolute",
          display: { xs: "none", sm: "none", md: "block" },
          filter: "invert(1)",
          top: {
            xl: 0,
            md: "calc(200px - (10vw))",
          },
          objectFit: "cover", // Ensures the image covers the box area
          objectPosition: "right", // Positions the focus on the right side of the image
          clipPath: "inset(0 50% 0 0)",
          transform: glowPattern === 0 ? "scale(1)" : "scale(0.99)",
        }}
        src={pattern}
        alt="chat icon"
        title="Patron 1"
      />
      {/* right side */}
      <Box
        component={"img"}
        sx={{
          width: "1800px",
          opacity: glowPattern === 0 ? 0.7 : 0,
          transition: "all 1.5s ease-in-out",
          maxWidth: "100%",
          position: "absolute",
          display: { xs: "none", sm: "none", md: "block" },
          filter: "invert(1)",
          top: {
            xl: 0,
            md: "calc(200px - (10vw))",
          },
          objectFit: "cover", // Ensures the image covers the box area
          objectPosition: "right", // Positions the focus on the right side of the image
          clipPath: "inset(0 0 0 50%)",
          transform: glowPattern === 0 ? "scale(1)" : "scale(0.99)",
        }}
        src={pattern}
        alt="chat icon"
        title="Patron 1"
      />
      <Box
        component={"img"}
        sx={{
          width: "1800px",
          opacity: 0.1,

          maxWidth: "100%",
          position: "absolute",
          display: { xs: "none", sm: "none", md: "block" },
          filter: "invert(1)",
          top: {
            xl: 0,
            md: "calc(200px - (10vw))",
          },
        }}
        src={pattern}
        alt="chat icon"
        title="Patron 1"
      />
    </>
  );
};

export default GlowingPattern;
