import Text from "../../../../../uiComponents/Text";
import Button from "../../../../../uiComponents/Button/Button";
import "./styles.css";
import IntroMobile from "./IntroMobile/IntroMobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

type IntroductionProps = {
  data: any;
  currentSection: any;
  setCurrentSection: any;
  setHelp?: any;
};

const Introduction: React.FC<IntroductionProps> = ({
  data,
  currentSection,
  setCurrentSection,
  setHelp,
}) => {
  return (
    <div>
      <div className="introduction-section">
        <div className="main-title-div">
          <Text size="medium" align="left" className="main-title">
            <i className="fas fa-play main-title-icon primary"></i>
            {data.name}
          </Text>
          <div className="flex"></div>
        </div>
        <div className="flex introduction-price-box b-secondary">
          <div>
            Estado del documento:{" "}
            <span className="primary" style={{ marginLeft: 5 }}>
              {"   "}
              Actualizado{" "}
            </span>
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="primary"
              style={{ fontSize: 14, marginLeft: 2 }}
            />
          </div>
          <i className="fas fa-info-circle introduction-info-icon"></i>
        </div>

        <div className="introduction-div-1">
          <div className="introduction-card-1">
            <Text roboto size="medium">
              <i className="fas fa-chevron-circle-right primary margin-r"></i>
              {data.question}
            </Text>
            {data.definition.map((e: any) => (
              <p className="margin-v a-left f-1">{e}</p>
            ))}
            <div className="flex-space-between">
              <Button
                label={`Comenzar!`}
                color="secondary"
                onClick={() => setCurrentSection(currentSection + 1)}
                className="introduction-start-button"
                shadow={false}
              />
            </div>
          </div>
        </div>
      </div>
      <IntroMobile
        data={data}
        currentSection={currentSection}
        setCurrentSection={setCurrentSection}
        setHelp={setHelp}
      />
    </div>
  );
};

export default Introduction;
