import React, { useMemo } from "react";
import { MRT_ColumnDef } from "material-react-table";
import TableEntryName from "../../../uiComponents/AndesTable/TableEntries/Name.component";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import TableDate from "../../../uiComponents/AndesTable/TableEntries/TableDate.component";

import AndesTable, {
  renderHeader,
} from "../../../uiComponents/AndesTable/AndesTable.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setAppError } from "../../../Utils/modalMethods";
import {
  findUserNameById,
  findUserPhotoUrl,
} from "../../../Utils/commonTableActions";
import { ProfilePicAndName } from "../../../uiComponents/ProfilePicAndName/ProfilePicAndName";

type Props = {
  documents: any[];
  setEsignDocumentData?: (doc: any) => void;
  hideSearch?: boolean;
};

const ESignatureTable: React.FC<Props> = ({
  documents,
  setEsignDocumentData,
  hideSearch,
}) => {
  const handleSignClick = (document: any) => {
    const allowedFormats = ["pdf", "word"];
    const fileFormat = document.format.toLowerCase();

    if (allowedFormats.includes(fileFormat)) {
      if (setEsignDocumentData) {
        setEsignDocumentData(document);
      } else {
        setAppError();
      }
    } else {
      setAppError(
        "Formato de archivo incorrecto",
        "Para Firmar Electrónicamente el formato del archivo deberá ser convertido a PDF o DOCX."
      );
    }
  };

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "fileName",
        header: "Nombre",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        Cell: ({ cell }: any) => {
          return (
            <TableEntryName
              name={cell.getValue()}
              image={"signature.png"}
              // icon={faSignature}
            />
          );
        },
        maxSize: 400,
        minSize: 310,
      },
      {
        accessorKey: "dateCreated",
        header: "Creación",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 190,
        maxSize: 170,
        Cell: ({ cell }: any) => {
          return <TableDate date={cell.getValue()} fallback="Sin definir" />;
        },
      },
      {
        accessorKey: "format",
        header: "Formato",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 190,
        maxSize: 170,
      },
      {
        accessorKey: "userId",
        header: "Creado por",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 220,
        Cell: ({ cell }: any) => {
          const userPhotoUrl = findUserPhotoUrl(cell.getValue());
          const userName = findUserNameById(cell.getValue());
          return <ProfilePicAndName image={userPhotoUrl} name={userName} />;
        },
      },
      {
        header: "Acciones",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        enableColumnActions: false,
        minSize: 280,
        Cell: ({ cell }) => (
          <div>
            <div
              className="btn-outline"
              onClick={() => handleSignClick(cell.row.original)}
            >
              <FontAwesomeIcon icon={faBolt} className="margin-icon" />
              Firmar Electrónicamente
            </div>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <AndesTable
        documents={documents}
        columns={columns}
        initialSort={[{ id: "dateCreated", desc: true }]}
        hideSearch={hideSearch}
      />
    </>
  );
};

export default ESignatureTable;
