import React, { useState, useEffect } from "react";
import LeftSection from "./sections/LeftSection/LeftSection";
import MainSection from "./sections/MainSection/MainSection";
import { connect } from "react-redux";
import { setAnswers, resetAnswers } from "../../redux/answers/answers.actions";
import Loader from "react-loader-spinner";
import { useAuth0 } from "@auth0/auth0-react";
import PleaseLogIn from "./sections/PleaseLogIn/PleaseLogin";

import "./styles.css";

type DocumentCreatorProps = {
  data: {
    name: string;
    codeName: string;
    url: string;
    sections: { section: string }[];
  };
  answers: any;
  setAnswers: (answers: any) => void;
};

interface StoredAnswers {
  currentDocument?: string;
  [key: string]: any;
}

const DocumentCreator: React.FC<DocumentCreatorProps> = ({
  data,
  answers,
  setAnswers,
}) => {
  const [currentSection, setCurrentSection] = useState<any>(0);
  const [isNewDocument, setIsNewDocument] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentSection]);

  const setSessionCurrentSection = (sec: any) => {
    sessionStorage.setItem("currentSection", sec);
    setCurrentSection(sec);
  };

  const setSessionUrl = (url: string) => {
    if (window.location.pathname.includes("company-document-creator")) {
      sessionStorage.setItem("sessionUrl", "company-document-creator");
      return;
    }
    sessionStorage.setItem("sessionUrl", url);
    return;
  };

  const resetDocumentToStart = () => {
    resetAnswers();
    setAnswers({ currentDocument: data.name, codeName: data.codeName });
    setSessionCurrentSection(0);
    setSessionUrl(data.url);
  };

  useEffect(() => {
    let storedAnswers: StoredAnswers = {};
    let sessionCurrentSection: number | undefined;

    if (sessionStorage.answers && sessionStorage.currentSection) {
      storedAnswers = JSON.parse(sessionStorage.answers);
      sessionCurrentSection = JSON.parse(sessionStorage.currentSection);
    }

    if (isNewDocument) {
      if (!storedAnswers.currentDocument) {
        resetDocumentToStart();
      } else if (storedAnswers.currentDocument !== data.name) {
        resetAnswers();
        setAnswers({ currentDocument: data.name, codeName: data.codeName });
        setSessionCurrentSection(0);
        setSessionUrl(data.url);
        window.location.reload();
      } else if (sessionCurrentSection) {
        setCurrentSection(sessionCurrentSection);
      }
      setIsNewDocument(false);
    }
    setLoading(false);
  }, [isNewDocument, data.name, data.codeName, data.url, setAnswers]);

  // useEffect(() => {
  //   //this code will check wether the document you are editing is the same as before
  //   // if it isnt it will reset de answers
  //   // if it is it will keep the answers so that te user can continue where he left
  //   let storedAnswers: StoredAnswers = {};
  //   let sessionCurrentSection = undefined;
  //   if (sessionStorage.answers && sessionStorage.currentSection) {
  //     storedAnswers = JSON.parse(sessionStorage.answers);
  //     sessionCurrentSection = JSON.parse(sessionStorage.currentSection);
  //   }
  //   if (isNewDocument) {
  //     if (!storedAnswers.currentDocument) {
  //       resetDocumentToStart();
  //     } else if (
  //       storedAnswers.currentDocument &&
  //       storedAnswers.currentDocument !== data.name
  //     ) {
  //       resetAnswers();
  //       setAnswers({ currentDocument: data.name, codeName: data.codeName });
  //       setSessionCurrentSection(0);
  //       setSessionUrl(data.url);
  //       window.location.reload();
  //     } else if (sessionCurrentSection) {
  //       setCurrentSection(sessionCurrentSection);
  //     }
  //     setIsNewDocument(false);
  //   }
  //   setLoading(false);
  // }, []);

  return (
    <div>
      {isAuthenticated && (
        <div>
          <div className="document-creator b-tertiary">
            <LeftSection
              data={data}
              currentSection={currentSection}
              setCurrentSection={setCurrentSection}
            />
            {loading ? (
              <div className="document-creator-loading-div">
                <Loader
                  type="TailSpin"
                  color="rgb(106, 250, 106)"
                  height={60}
                  width={60}
                  // className="document-creator-loader"
                />
                <span>Cargando ...</span>
              </div>
            ) : (
              <MainSection
                data={data}
                currentSection={currentSection}
                setCurrentSection={setSessionCurrentSection}
                resetDocumentToStart={resetDocumentToStart}
              />
            )}
          </div>
          <div className="document-creator-mobile">
            {/* // THIS IS MOBILE VERSION OF DocumentCreator DONT DELETE */}
            {/* {loading ? (
          <div className="document-creator-loading-div">
            <Loader
              type="TailSpin"
              color="rgb(106, 250, 106)"
              height={60}
              width={60}
              className="document-creator-loader"
            />
            <span>Cargando ...</span>
          </div>
        ) : (
          <div>
            <TopMobileSection
              data={data}
              currentSection={currentSection}
              setCurrentSection={setCurrentSection}
            />
            <MiddleSection
              data={data}
              currentSection={currentSection}
              setCurrentSection={setSessionCurrentSection}
            />
          </div>
        )} */}
            <div className="center" style={{ padding: 40 }}>
              <h1 className="margin-v-l">Ingrese desde una Computadora</h1>
              <p>
                Para poder ingresar al creador de documentos por favor ingrese
                desde una computadora. Si aún ve este mensaje, establezca el
                zoom del navegador en 100% ó contáctese con soporte
              </p>
            </div>
          </div>
        </div>
      )}
      {!isAuthenticated && (
        <div>
          <PleaseLogIn />
          <div className="center mobile-only" style={{ padding: 40 }}>
            <h1 className="margin-v-l">Ingrese desde una Computadora</h1>
            <p>
              Para poder ingresar al creador de documentos por favor ingrese
              desde una computadora. Si aún ve este mensaje, establezca el zoom
              del navegador en 100% ó contáctese con soporte
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    answers: state.answers,
  };
};

const mapDispatchToProps = () => ({
  setAnswers,
  resetAnswers,
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentCreator);
