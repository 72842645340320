import React, { useEffect, useState } from "react";
import "../styles.css";

const DocumentName = () => {
  const [value, setValue] = useState<string | null>("");

  const handleUpdate = (val: any) => {
    setValue(val);
    window.sessionStorage.setItem("custom_document_name", `${val}`);
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("custom_document_name")) {
      setValue(window.sessionStorage.getItem("custom_document_name"));
    }
  }, []);

  return (
    <div className="question-format-form">
      <input
        type="text"
        className="question-format-input"
        maxLength={150}
        value={String(value)}
        onChange={(e) => {
          let valueNormalized =
            typeof e.target.value === "string" ? e.target.value.split("") : [];
          let newValue = valueNormalized
            .filter((val) =>
              /^[.áÁéÉíÍóÓúÚñÑa-zA-Z0-9,!?@$¿¡(): ´%+"-_]*$/.test(val)
            )
            .join("");
          handleUpdate(newValue);
        }}
      ></input>
    </div>
  );
};

export default DocumentName;
