import { Box, Button, Stack, Typography } from "@mui/material";
import { colorPallette } from "../../Common/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faBolt,
  faDisplay,
  faTelevision,
  faTv,
  faTvAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";

export const YoutubeVideoSection: React.FC = () => {
  const handleClick = () => {
    window.open("https://www.youtube.com/watch?v=uJBUtOm3_Tk&t=1s", "_blank");
  };

  return (
    <Stack
      sx={{
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        my: 16,
        position: "relative",
      }}
    >
      <Box
        component="img"
        src={process.env.PUBLIC_URL + "/images/tute back.jpg"}
        onClick={handleClick}
        sx={{
          display: { md: "block", xs: "none" },
          width: { md: "95%", xs: "100%" },
          maxWidth: "1250px",
          boxShadow: "0px 27px 20px -25px rgba(0,0,0,0.6)",
          borderRadius: { md: "25px", xs: 0 },
          cursor: "pointer",
          transition: "all 0.15s",
          "&:hover": {
            transform: "scale(0.993)",
          },
        }}
      />{" "}
      <Box
        component="img"
        src={process.env.PUBLIC_URL + "/images/thumb_tute.jpg"}
        className="por-que-automation"
        alt="Product Image"
        title="Imagen del producto 1"
        onClick={handleClick}
        sx={{
          display: { md: "none", xs: "block" },
          width: "80%",
          borderRadius: "4px",
          border: "solid 8px",
          borderColor: colorPallette.tertiary,
          transition: "all 0.15s",
          "&:hover": {
            transform: "scale(0.98)",
            opacity: 0.9,
          },

          boxShadow: "0px 30px 16px -20px rgba(0,0,0,0.19)",
        }}
      />{" "}
      <Button
        variant="contained"
        sx={{
          width: "700px",
          maxWidth: "90vw",
          bgcolor: "transparent",
          color: colorPallette.dark,
          fontSize: "1.2rem",
          mt: 12,
          transition: "all 0.15s",
          "&:hover": {
            bgcolor: "transparent",
            transform: "scale(0.98)",
            "& .go-icon": {
              transform: "translateY(1px) translateX(6px) !important",
            },
          },
        }}
        component="a"
        href="https://www.youtube.com/watch?v=uJBUtOm3_Tk&t=1s"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon
          icon={faBolt}
          style={{
            marginRight: "8px",
            color: colorPallette.primary,
            opacity: 0.6,
          }}
        />
        Ver video{" "}
        <FontAwesomeIcon
          icon={faArrowRight}
          className="go-icon"
          style={{
            marginLeft: "8px",
            color: colorPallette.primary,
            transform: "translateY(1px)",
            transition: "all 0.15s",
          }}
        />
      </Button>
    </Stack>
  );
};
