import React, { useEffect } from "react";
import IconButton from "../IconButton/IconButton";
import { connect } from "react-redux";
import { closeErrorModal } from "../../Utils/modalMethods";

import "./styles.css";

type ErrorModalProps = {
  data: {
    message?: string;
    submessage?: string;
    disclaimer?: string;
  };
};

const ErrorModal: React.FC<ErrorModalProps> = ({ data }) => {
  const { message, submessage, disclaimer } = data;

  return (
    <div className="ui-error-modal-background">
      <div className="ui-error-modal-background-children">
        <div className="ui-error-modal">
          <i
            className="far fa-window-close ui-error-modal-close"
            onClick={closeErrorModal}
          ></i>
          <p className="ui-error-modal-message">
            {message ? message : "Error"}
          </p>
          <p className="ui-error-modal-sub-message">
            {submessage
              ? submessage
              : "Ha ocurrido un error, por favor inténtelo nuevamente."}
          </p>
          <p className="ui-error-modal-disclaimer">
            {disclaimer
              ? disclaimer
              : "*En caso de tener un problema persistente por favor comuníquese con soporte."}
          </p>
          <IconButton
            label="Cerrar"
            color="secondary"
            onClick={() => {
              closeErrorModal();
            }}
            className="center ui-error-modal-button"
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return { data: state.modal.errorModal };
};

export default connect(mapStateToProps, null)(ErrorModal);
