import React, { useEffect, useState } from "react";
import IconButton from "../../../../uiComponents/IconButton/IconButton";
import CreatorField from "./CreatorField/CreatorField";
import DetailField from "./DetailField/DetailField";
import TextAreaField from "./TextAreaField/TextAreaField";
import NameField from "./NameField/NameField";
import { useAuth0 } from "@auth0/auth0-react";
import { downloadWordS3 } from "../../../../Utils/S3Download";
import DateField from "./DateField/DateField";
import Modal from "../../../../uiComponents/Modal/Modal";
import {
  setDetails,
  resetDetails,
} from "../../../../redux/fileDetail/fileDetail.actions";
import { setModal, resetModals } from "../../../../redux/modal/modal.actions";
import { connect, useDispatch } from "react-redux";
import {
  modalOpen,
  setAppError,
  setLoading,
} from "../../../../Utils/modalMethods";
import {
  editDocument,
  editReduxDocument,
} from "../../../../Utils/documentMethods";
import { setEditedDocumentName } from "../../../../redux/slices/triggers";
import { Document } from "../../../../Types/Document.types";

import "./styles.css";
import { ActionButton } from "./ActionButton/ActionButton";

type DocumentDetailModalProps = {
  details: any;
  setDetails: any;
  resetDetails: any;
  setModal: any;
  modal: any;
  resetModals: any;
};

type RelevantField = {
  fieldName: string;
  logicalName: string;
  editable: boolean;
};

const DocumentDetailModal: React.FC<DocumentDetailModalProps> = ({
  details,
  setDetails,
  resetDetails,
  setModal,
  modal,
  resetModals,
}) => {
  const { getAccessTokenSilently, user } = useAuth0();

  const [document, setDocument] = useState<Document | null>(null);
  const [editor, setEditor] = useState<boolean>(false);
  const [shouldReloadOnClose, setShouldReloadOnClose] =
    useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    resetDetails();
    if (document) {
      const payload = { ...document };
      setDetails(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  useEffect(() => {
    if (modal && modal.documentDetailModal.document) {
      setDocument(modal.documentDetailModal.document);
    }
  }, []);

  const relevantFields: RelevantField[] = [
    {
      fieldName: "Tipo de documento",
      logicalName: "documentType",
      editable: false,
    },
    {
      fieldName: "Fecha de creación",
      logicalName: "dateCreated",
      editable: false,
    },
  ];

  const getDocument = async () => {
    try {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      await downloadWordS3(document, accessToken);
    } catch (error) {
      setAppError();
    } finally {
      setLoading(false);
    }
  };

  const saveDocument = async () => {
    setLoading(true);
    const payload = { ...details };
    try {
      if (
        payload &&
        payload.documentId === document?.documentId &&
        payload.companyId === document?.companyId &&
        payload.filePath === document?.filePath &&
        payload.fileName.trim() !== ""
      ) {
        const accessToken = await getAccessTokenSilently();
        const res = await editDocument(payload, accessToken, editReduxDocument);
        // await APIPost("/doc-ref/edit-document", accessToken, payload);
        // const newDoc = await APIPost("/doc-ref/get-one-doc", accessToken, {
        //   documentId: document.documentId,
        // });
        if (res) {
          if (window.location.href.includes("docx-")) {
            dispatch(setEditedDocumentName(payload.fileName));
          }
          setDocument(payload);
          setEditor(false);
        } else {
          setAppError();
        }
      } else {
        setAppError();
      }
    } catch (e) {
      setAppError();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal>
      <div className="document-detail-modal-scroller">
        <div className="document-detail-modal-background-color b-tertiary">
          <div
            className="document-detail-modal-back b-secondary"
            onClick={() => {
              if (!shouldReloadOnClose) {
                resetModals();
              } else {
                window.location.reload();
              }
            }}
          >
            <i className="fas fa-chevron-left document-detail-modal-back-icon"></i>
            Cerrar
          </div>
          {document && <CreatorField document={document} />}
          <div className="document-detail-modal-content">
            {document && (
              <div className="document-detail-modal-box">
                <div className="document-detail-top-row">
                  <NameField
                    field={document.fileName}
                    fieldName="Nombre"
                    edit={editor}
                    format={document.format}
                  />
                  <div className="document-detail-modal-button-div">
                    <ActionButton
                      label="Descargar"
                      icon="fas fa-cloud-download-alt"
                      className="document-detail-modal-download-button"
                      color="secondary"
                      onClick={getDocument}
                    />
                    <ActionButton
                      label={editor ? "Guardar" : "Editar"}
                      icon={editor ? "far fa-save" : "far fa-edit"}
                      className="document-detail-modal-edit-button"
                      color={"secondary"}
                      onClick={() => {
                        if (editor) {
                          saveDocument();
                        } else {
                          setEditor((prev) => !prev);
                        }
                      }}
                    />
                    <ActionButton
                      label="Mover a"
                      icon="far fa-folder-open"
                      className="document-detail-modal-move-button"
                      color="secondary"
                      onClick={() => {
                        setModal({
                          documentDetailModal: {
                            show: false,
                            document: null,
                          },
                          folderModal: {
                            show: true,
                            document: document,
                          },
                        });
                      }}
                    />
                    <ActionButton
                      label="Eliminar"
                      icon="fas fa-trash-alt"
                      className="document-detail-modal-delete-button"
                      color="primary"
                      onClick={() =>
                        modalOpen({
                          documentDetailModal: {
                            show: false,
                            document: null,
                          },
                          confirmModal: {
                            show: true,
                            message: "Confirme eliminación",
                            submessage:
                              "¿Seguro desea eliminar el documento seleccionado?",
                            disclaimer:
                              "*No se podrá deshacer, asegursese que no necesitará el documento en el futuro.",
                            document: document,
                            isAsync: true,
                            confirmMethodName: "deleteDocumentAndReference",
                            fileName: document.fileName,
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <div
                  className={`document-detail-modal-grid ${
                    editor && "document-detail-modal-grid-editor"
                  }`}
                >
                  {document &&
                    relevantFields.map((field) => {
                      if (field.fieldName === "Fecha de creación") {
                        return (
                          <DateField
                            fieldName={field.fieldName}
                            field={document[field.logicalName]}
                            edit={editor}
                            editable={field.editable}
                            logicalName={field.logicalName}
                          />
                        );
                      }

                      return (
                        <DetailField
                          field={document[field.logicalName]}
                          fieldName={field.fieldName}
                          edit={editor}
                          editable={field.editable}
                          logicalName={field.logicalName}
                        />
                      );
                    })}
                </div>
                <TextAreaField
                  fieldName={"Descripción"}
                  field={document.description}
                  edit={editor}
                  editable
                  logicalName={"description"}
                />
                {editor && (
                  <IconButton
                    label="Guardar"
                    icon="far fa-save"
                    className="document-detail-modal-save-button"
                    color={"secondary"}
                    onClick={() => {
                      if (editor) {
                        saveDocument();
                      } else {
                        setEditor((prev) => !prev);
                      }
                    }}
                  />
                )}
                {editor && (
                  <IconButton
                    label="Cancelar"
                    icon="far fa-window-close"
                    className="document-detail-modal-save-button"
                    color={"primary"}
                    onClick={() => {
                      setEditor((prev) => !prev);
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = {
  setDetails,
  resetDetails,
  setModal,
  resetModals,
};

const mapStateToProps = (state: any) => {
  return {
    details: state.details,
    modal: state.modal,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentDetailModal);
