import React from "react";
import "./styles.css";

type DateFieldProps = {
  field: string;
  fieldName: string;
  edit: boolean;
  editable: boolean;
  logicalName: string;
};

const DateField: React.FC<DateFieldProps> = ({
  field,
  fieldName,
  edit,
  editable,
  logicalName,
}) => {
  const date = new Date(parseInt(field));

  const displayDate = `${date.getDate()} /  ${
    date.getMonth() + 1
  } / ${date.getFullYear()}`;

  return (
    <div>
      <div className="date-field-wrapper">
        <i className="far fa-play-circle secondary margin-r"></i>
        <div>
          {fieldName}:{" "}
          {!edit && (
            <span>
              {field ? (
                <span className="date-field b-primary">{displayDate}</span>
              ) : (
                <span className="date-field-undefined">Sin Definir</span>
              )}
            </span>
          )}
        </div>
      </div>
      {edit && editable && (
        <input type="text" className="date-field-input" maxLength={50} />
      )}
      {edit && !editable && (
        <p className="bold date-field-not-editable">
          {displayDate}{" "}
          <span className="date-field-not-remark">- No editable </span>
        </p>
      )}
    </div>
  );
};

export default DateField;
