import React, { useMemo } from "react";
import { MRT_ColumnDef } from "material-react-table";
import {
  faTrashAlt,
  faEdit,
  faUser,
  faCrown,
} from "@fortawesome/free-solid-svg-icons";
import TableActions, {
  TableAction,
} from "../../../uiComponents/AndesTable/TableEntries/TableActions.components";
import AndesTable, {
  renderHeader,
} from "../../../uiComponents/AndesTable/AndesTable.component";
import { Box, Typography } from "@mui/material";
import { colorPallette } from "../../../Common/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { useSetAtom } from "jotai";
import {
  confirmationModalAtom,
  initialConfirmationModalState,
} from "../../../jotai/atoms.jotai";
import { ProfilePicAndName } from "../../../uiComponents/ProfilePicAndName/ProfilePicAndName";

type Props = {
  documents: any[];
  onEditUser: (user: any) => void;
  onDeleteUser: (user: any) => void;
};

const UsersManagementTable: React.FC<Props> = ({
  documents,
  onEditUser,
  onDeleteUser,
}) => {
  const setConfirmationAtom = useSetAtom(confirmationModalAtom);

  const userTableActions: TableAction[] = [
    {
      name: "Editar",
      icon: faEdit,
      onClickWithParams: (data: any) => onEditUser(data),
    },
    {
      name: "Eliminar",
      icon: faTrashAlt,
      onClickWithParams: (data: any) => {
        if (data.userId) {
          setConfirmationAtom({
            message: "¿Estás seguro que quieres eliminar este usuario?",
            subMessage: `Usuario: ${data.email}`,
            show: true,
            onConfirm: async () => {
              await onDeleteUser(data.userId);
              setConfirmationAtom(initialConfirmationModalState);
            },
            onClose: () => setConfirmationAtom(initialConfirmationModalState),
          });
        }
      },
      color: "light-red",
    },
  ];

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Nombre",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        Cell: ({ cell }: any) => {
          let user = cell.row.original;
          if (!user || !user.name || !user.photoUrl)
            user = { name: user?.name || "", photoUrl: user?.photoUrl || "" };
          return <ProfilePicAndName image={user.photoUrl} name={user.name} />;
        },
        maxSize: 300,
        minSize: 250,
      },
      {
        accessorKey: "email",
        header: "Email",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 250,
        Cell: ({ cell }: any) => {
          return (
            <Typography
              color={colorPallette.primary}
              variant="body2"
              fontWeight={"bold"}
            >
              {cell.getValue()}
            </Typography>
          );
        },
      },
      {
        accessorKey: "role",
        header: "Rol",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 200,
        Cell: ({ cell }: any) => {
          return (
            <Box
              sx={{
                color: colorPallette.primary,
              }}
            >
              {cell.getValue() === "admin" ? (
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  color={"green"}
                >
                  <FontAwesomeIcon icon={faUserTie} fontSize={15} />
                  <Typography variant="body2" fontWeight={"bold"}>
                    Admin
                  </Typography>
                </Box>
              ) : cell.getValue() === "superadmin" ? (
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  color={"green"}
                >
                  <FontAwesomeIcon icon={faCrown} fontSize={15} />{" "}
                  <Typography variant="body2" fontWeight={"bold"}>
                    Superadmin
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  color={colorPallette.dark}
                >
                  <FontAwesomeIcon icon={faUser} fontSize={15} />
                  <Typography variant="body2" fontWeight={"bold"}>
                    User
                  </Typography>
                </Box>
              )}
            </Box>
          );
        },
      },
      {
        accessorKey: "documentShare",
        header: "Acceso",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 150,
        Cell: ({ cell }: any) => {
          const documentShare = cell.getValue();
          return (
            <Box
              sx={{
                color: documentShare
                  ? colorPallette.primary
                  : colorPallette.lightRed,
                fontWeight: "bold",
              }}
            >
              {documentShare
                ? "Todos los documentos de la empresa"
                : "Solo los documentos del usuario"}
            </Box>
          );
        },
      },
      {
        header: "Acciones",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        enableColumnActions: false,
        minSize: 200,
        Cell: ({ cell }: any) => (
          <TableActions
            actions={userTableActions}
            rowData={cell.row.original}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <AndesTable
        documents={documents}
        columns={columns}
        initialSort={[{ id: "name", desc: false }]}
      />
    </>
  );
};

export default UsersManagementTable;
