import React from "react";
import "../styles.css";

type NumberOfProps = {
  question: any;
  onChange: any;
  currentAnswer: any;
  sectionId: any;
  currentSectionAnswers: any;
};

const NumberOf: React.FC<NumberOfProps> = ({
  question,
  onChange,
  currentAnswer,
  sectionId,
  currentSectionAnswers,
}) => {
  const answerNormalizer = (value: any) => {
    let valueNormalized = typeof value === "string" ? value.split("") : [];
    let newValue = valueNormalized
      .filter((val) => /[\d|\\,.]/.test(val))
      .join("");
    return newValue;
  };

  const answerFormatter = (value: any) => {
    if (value.toString() === "1") {
      return `${value} ${question.fixedValueSingular}`;
    } else {
      return `${value} ${question.fixedValue}`;
    }
  };

  return (
    <div className="question-format-form-numberOf">
      <input
        required
        type="text"
        maxLength={20}
        value={answerNormalizer(currentAnswer)}
        className="question-format-input-numberOf"
        onChange={(e) => {
          let valueNormalized =
            typeof e.target.value === "string" ? e.target.value.split("") : [];
          let newValue = valueNormalized
            .filter((val) => /[\d|\\,.]/.test(val))
            .join("");
          onChange({
            [sectionId]: {
              ...currentSectionAnswers,
              [question.id]: {
                question: question.question,
                answer: answerFormatter(newValue),
              },
            },
          });
        }}
      ></input>
      <span className="question-format-endfix">
        {currentAnswer && answerNormalizer(currentAnswer).toString() === "1"
          ? question.fixedValueSingular
          : question.fixedValue}
      </span>
    </div>
  );
};

export default NumberOf;
