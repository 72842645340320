import React, { useEffect } from "react";
import Image from "../../uiComponents/Image/Image";
import Button from "../../uiComponents/Button/Button";

import "./styles.css";

const LawyerHelp = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="policy-background">
      <div className="policy-wrapper-small center">
        <Image name="logoGrey.png" className="policy-logo" />
        <div>
          <p
            style={{
              marginTop: "32.0pt",
              marginRight: "0cm",
              marginBottom: "52.0pt",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: "18px",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center",

              verticalAlign: "baseline",
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: "33px",
                  fontFamily: "Roboto",
                }}
                className="secondary"
              >
                Consulta a un Abogado
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: "19.0pt",
              marginRight: "0cm",
              marginBottom: "6.0pt",
              marginLeft: "0cm",
              lineHeight: "150%",
              fontSize: "18px",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center",

              verticalAlign: "baseline",
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: "22px",
                  lineHeight: "150%",
                  fontFamily: "Roboto",
                  color: "#343843",
                }}
              >
                <br />
                <i className="fas fa-chevron-circle-right primary margin-r"></i>
                &nbsp;¿Necesitas que te ayude un Abogado?
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: "19.0pt",
              marginRight: "0cm",
              marginBottom: "6.0pt",
              marginLeft: "0cm",
              lineHeight: "150%",
              fontSize: "18px",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",

              verticalAlign: "baseline",
            }}
          >
            <span
              style={{
                lineHeight: "150%",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#343843",
              }}
            >
              Te ofrecemos la posibilidad de contactar un abogado que trabajará
              para vos en una&nbsp;
            </span>
            <strong>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: '"Source Sans Pro",sans-serif',
                  color: "#131418",
                }}
              >
                sesión de consulta verbal de 30 minutos
              </span>
            </strong>
            <span
              style={{
                lineHeight: "150%",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#343843",
              }}
            >
              .
            </span>
          </p>
          <p
            style={{
              marginTop: "19.0pt",
              marginRight: "0cm",
              marginBottom: "6.0pt",
              marginLeft: "0cm",
              lineHeight: "150%",
              fontSize: "18px",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",

              verticalAlign: "baseline",
            }}
          >
            <span
              style={{
                lineHeight: "150%",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#343843",
              }}
            >
              Podes plantear&nbsp;
            </span>
            <strong>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: '"Source Sans Pro",sans-serif',
                  color: "#131418",
                }}
              >
                todo tipo de preguntas sobre cualquier tema
              </span>
            </strong>
            <span
              style={{
                lineHeight: "150%",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#343843",
              }}
            >
              &nbsp;y no solo relativas a un documento en concreto.
            </span>
          </p>
          <p
            style={{
              marginTop: "19.0pt",
              marginRight: "0cm",
              marginBottom: "6.0pt",
              marginLeft: "0cm",
              lineHeight: "150%",
              fontSize: "18px",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",

              verticalAlign: "baseline",
            }}
          >
            <span
              style={{
                lineHeight: "150%",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#343843",
              }}
            >
              Precio: $2.500 Pesos Argentinos.
            </span>
            <span
              style={{
                lineHeight: "150%",
                fontFamily: '"Arial",sans-serif',
                color: "#343843",
              }}
            >
              &nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: "19.0pt",
              marginRight: "0cm",
              marginBottom: "6.0pt",
              marginLeft: "0cm",
              lineHeight: "150%",
              fontSize: "18px",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",

              verticalAlign: "baseline",
            }}
          >
            <span
              style={{
                fontSize: "22px",
                lineHeight: "150%",
                fontFamily: '"Arial",sans-serif',
                color: "#343843",
              }}
            >
              &nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "150%",
              fontSize: "18px",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center",

              verticalAlign: "baseline",
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: "22px",
                  lineHeight: "150%",
                  fontFamily: "Roboto",
                  color: "#343843",
                }}
              >
                <i className="fas fa-chevron-circle-right primary margin-r"></i>
                ¿Cómo se realiza esta consulta?
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: "19.0pt",
              marginRight: "0cm",
              marginBottom: "6.0pt",
              marginLeft: "0cm",
              lineHeight: "150%",
              fontSize: "18px",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",

              verticalAlign: "baseline",
            }}
          >
            <span
              style={{
                lineHeight: "150%",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#343843",
              }}
            >
              Tendrás una cita de 30 minutos con tu abogado.
            </span>
          </p>
          <p
            style={{
              marginTop: "19.0pt",
              marginRight: "0cm",
              marginBottom: "6.0pt",
              marginLeft: "0cm",
              lineHeight: "150%",
              fontSize: "18px",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",

              verticalAlign: "baseline",
            }}
          >
            <span
              style={{
                lineHeight: "150%",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#343843",
              }}
            >
              Durante esos 30 minutos hablarás con el abogado por teléfono. Le
              planteas tus preguntas y él te contesta. Tus preguntas pueden
              referirse a un documento que has completado, o a otros temas.
            </span>
          </p>
          <p
            style={{
              marginTop: "19.0pt",
              marginRight: "0cm",
              marginBottom: "6.0pt",
              marginLeft: "0cm",
              lineHeight: "150%",
              fontSize: "18px",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",

              verticalAlign: "baseline",
            }}
          >
            <span
              style={{
                lineHeight: "150%",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#343843",
              }}
            >
              Si el abogado necesita buscar información complementaria para
              contestar mejor a tus preguntas, de esos 30 minutos se tomará un
              tiempo para hacerlo y a continuación te llamará para contestar a
              tus preguntas.
            </span>
          </p>
          <p
            style={{
              marginTop: "19.0pt",
              marginRight: "0cm",
              marginBottom: "6.0pt",
              marginLeft: "0cm",
              lineHeight: "150%",
              fontSize: "18px",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center",

              verticalAlign: "baseline",
            }}
          >
            <span
              style={{
                fontSize: "22px",
                lineHeight: "150%",
                fontFamily: '"Arial",sans-serif',
                color: "#343843",
              }}
            >
              &nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "150%",
              fontSize: "18px",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center",

              verticalAlign: "baseline",
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: "22px",
                  lineHeight: "150%",
                  fontFamily: "Roboto",
                  color: "#343843",
                }}
              >
                <i className="fas fa-chevron-circle-right primary margin-r"></i>
                ¿Cuándo y cómo se pondrá el abogado en contacto conmigo?
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: "19.0pt",
              marginRight: "0cm",
              marginBottom: "6.0pt",
              marginLeft: "0cm",
              lineHeight: "150%",
              fontSize: "18px",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",

              verticalAlign: "baseline",
            }}
          >
            <span
              style={{
                lineHeight: "150%",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#343843",
              }}
            >
              Una vez iniciada la conversación por Whatsapp y/o teléfono, el
              abogado te propondrá para que, en una ventana de 24 horas hábiles
              y luego de haber realizado el pago, puedan coordinar una cita. El
              horario de atención del abogado es de 10.00hs a 17.00hs días
              hábiles.&nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: "19.0pt",
              marginRight: "0cm",
              marginBottom: "6.0pt",
              marginLeft: "0cm",
              lineHeight: "150%",
              fontSize: "18px",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",

              verticalAlign: "baseline",
            }}
          >
            <span
              style={{
                fontSize: "22px",
                lineHeight: "150%",
                fontFamily: '"Arial",sans-serif',
                color: "#343843",
              }}
            >
              &nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "150%",
              fontSize: "18px",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center",

              verticalAlign: "baseline",
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: "22px",
                  lineHeight: "150%",
                  fontFamily: "Roboto",
                  color: "#343843",
                }}
              >
                <i className="fas fa-chevron-circle-right primary margin-r"></i>
                ¿Por qué 30 minutos? ¿Y si necesito más tiempo?
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: "19.0pt",
              marginRight: "0cm",
              marginBottom: "6.0pt",
              marginLeft: "0cm",
              lineHeight: "150%",
              fontSize: "18px",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",

              verticalAlign: "baseline",
            }}
          >
            <span
              style={{
                lineHeight: "150%",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#343843",
              }}
            >
              Por experiencia, sabemos que 30 minutos es el tiempo óptimo para
              una primera sesión con un abogado. Con menos tiempo se corre el
              riesgo de que no tenga bastante tiempo para contestarte bien. Si
              necesitas más tiempo es que tu caso seguramente necesite ser
              tratado en profundidad.
            </span>
          </p>
          <p
            style={{
              marginTop: "19.0pt",
              marginRight: "0cm",
              marginBottom: "6.0pt",
              marginLeft: "0cm",
              lineHeight: "150%",
              fontSize: "18px",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",

              verticalAlign: "baseline",
            }}
          >
            <span
              style={{
                lineHeight: "150%",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#343843",
              }}
            >
              Si lo necesitas, puedes seguir trabajando con el abogado más allá
              de los 30 minutos iniciales. En ese caso, tendrás que tratar
              directamente con él, sin nuestra intervención. El abogado te dará
              sus tarifas y tendrás total libertad de trabajar con él o no.
            </span>
          </p>
          <p
            style={{
              marginTop: "19.0pt",
              marginRight: "0cm",
              marginBottom: "6.0pt",
              marginLeft: "0cm",
              lineHeight: "150%",
              fontSize: "18px",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",

              verticalAlign: "baseline",
            }}
          >
            <span
              style={{
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#343843",
              }}
            >
              Podrás encontrar mayor información en los Terms and Conditions.
            </span>
          </p>
          <p
            style={{
              marginTop: "19.0pt",
              marginRight: "0cm",
              marginBottom: "6.0pt",
              marginLeft: "0cm",
              lineHeight: "150%",
              fontSize: "18px",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",

              verticalAlign: "baseline",
            }}
          >
            <span
              style={{
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#343843",
              }}
            >
              Muchas Gracias por confiar en AndesDocs.&nbsp;
            </span>
          </p>
        </div>{" "}
        <Button
          label="Contactate por whatsapp!"
          className="policy-button"
          color="secondary"
          onClick={() => {
            window.open(
              "https://api.whatsapp.com/send?phone=5491131073107&text=Hola,%20te%20contacto%20por%20Andes%20Docs!"
            );
          }}
          center
          size="large"
          style={{ marginTop: 80 }}
        />
      </div>
    </div>
  );
};

export default LawyerHelp;
