import React, { useEffect, useState } from "react";
import "../styles.css";
import "./styles.css";

type CardProps = {
  item: any;
  handleClick: any;
  preSetter: any;
  answersAreSet: any;
};

export const Card: React.FC<CardProps> = ({
  item,
  handleClick,
  preSetter,
  answersAreSet,
}) => {
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    // this bit of code checks whether the item was already selected to mark it as so.
    if (!answersAreSet) {
      preSetter(setSelected, item);
    }
  }, [preSetter, item, answersAreSet]);
  return (
    <div
      onClick={() => {
        setSelected((prev) => !prev);
        handleClick(item);
      }}
      className={` multiple-options-option-card purple multiple-options-option-card-${selected}`}
    >
      {!selected ? (
        <i className="far fa-square multiple-options-square primary"></i>
      ) : (
        <i className="far fa-check-square multiple-options-check primary"></i>
      )}
      {item}
    </div>
  );
};
