import React, { useEffect, useState } from "react";
import "../styles.css";
import "./styles.css";
import { Card } from "./Card";

type MultipleOptionsProps = {
  question: any;
  onChange: any;
  currentAnswer: any;
  sectionId: any;
  currentSectionAnswers: any;
};

const MultipleOptions: React.FC<MultipleOptionsProps> = ({
  question,
  onChange,
  currentAnswer,
  sectionId,
  currentSectionAnswers,
}) => {
  const [answersAreSet, setAnswersAreSet] = useState(false);
  //this state checks wether answers are already set to previous answers or not
  const [answersArray, setAnswersArray] = useState(currentAnswer || []);
  useEffect(() => {
    if (!answersAreSet && !currentAnswer) {
      onChange({
        [sectionId]: {
          ...currentSectionAnswers,
          [question.id]: {
            question: question.question,
            answer: [],
          },
        },
      });
    }
  }, [currentSectionAnswers]);

  // this function will be passed to each option to check wether it was checked or not
  const preSetter = (func: any, item: any) => {
    if (!answersAreSet && currentAnswer && currentAnswer.includes(item)) {
      func(true);
    }
  };

  // this sets redux and session state answers
  const reduxAnswerSetter = (newArray: any) => {
    onChange({
      [sectionId]: {
        ...currentSectionAnswers,
        [question.id]: {
          question: question.question,
          answer: newArray,
        },
      },
    });
  };

  //this method handles clicks

  const handleClick = (newItem: any) => {
    setAnswersAreSet(true);
    const preHandleArray = currentAnswer || [];
    if (!answersArray.includes(newItem)) {
      reduxAnswerSetter([...preHandleArray, newItem]);
      setAnswersArray([...preHandleArray, newItem]);
    } else {
      const newArray = preHandleArray;
      const index = newArray.indexOf(newItem);
      if (index > -1) {
        newArray.splice(index, 1);
        reduxAnswerSetter([...newArray]);
        setAnswersArray([...newArray]);
      }
    }
  };
  return (
    <div>
      {question.options.map((e: any) => {
        return (
          <Card
            item={e}
            handleClick={handleClick}
            preSetter={preSetter}
            answersAreSet={answersAreSet}
            key={e}
          />
        );
      })}
    </div>
  );
};

export default MultipleOptions;
