import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { colorPallette } from "../../Common/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faRecycle,
  faSignature,
  faWandSparkles,
} from "@fortawesome/free-solid-svg-icons";
import Logo from "../../uiComponents/Logo/Logo";
import pattern from "../../Assets/svg/pattern.svg";
import ContractLifecycleMobile from "./ContractLifecycleMobile";

interface Step {
  title: string;
  description: string[];
  icon: any;
}

const steps: Step[] = [
  {
    title: "Pre Firma",
    description: [
      "Automatiza tus modelos de contratos",
      "Edita documentos en línea",
      "Negocia con otras áreas de la empresa y terceros",
    ],
    icon: faWandSparkles,
  },
  {
    title: "Firma Electrónica",
    description: [
      "Ahorra tiempo en la firma de documentos",
      "Reduce el uso de papel y costos de impresión",
      "Firma segura y legalmente válida",
    ],
    icon: faSignature,
  },
  {
    title: "Post Firma",
    description: [
      "Almacena y genera reportes",
      "Gestiona vencimientos",
      "Archivo y seguimiento",
    ],
    icon: faRecycle,
  },
];

const ContractLifecycle: React.FC = () => {
  const [activeBox, setActiveBox] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveBox((prevBox) => (prevBox + 1) % 3);
    }, 1900); // Change every 500ms
    return () => clearInterval(interval);
  }, []);

  return (
    <Box>
      <ContractLifecycleMobile steps={steps} />
      {/* <Box
        component="img"
        src={process.env.PUBLIC_URL + "/images/document-creator.png"}
        className="por-que-automation"
        alt="Product Image"
        sx={{
          width: "100%",
          maxWidth: "500px",
          minWidth: "375px",
          // display: { xs: "block", sm: "none" },
        }}
      /> */}
      <Stack
        sx={{
          width: "100%",
          my: "20px",
          py: "0px",
          position: "relative",
          display: {
            xs: "none",
            sm: "none",
            md: "flex",
          },
        }}
        alignItems="center"
      >
        <Box
          component={"img"}
          src={pattern}
          sx={{
            position: "absolute",
            top: "242px",
            width: "98%",
            opacity: 0.4,
            height: "645px",
            objectFit: "cover",
            maxWidth: "1830px",
          }}
        />
        <Stack
          alignItems="center"
          sx={{
            width: "100%",
            maxWidth: "1200px",
            py: 10,
            borderRadius: "6px",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            fontWeight="bold"
            sx={{ width: "90%", maxWidth: "1200px" }}
            mb={3}
          >
            Optimizá tus
            <span style={{ color: colorPallette.primary }}> procesos</span>{" "}
            contractuales de{" "}
            <span style={{ color: colorPallette.primary }}>
              principio a fin
            </span>
          </Typography>
          <Typography
            fontSize="1.1rem"
            sx={{ width: "90%", maxWidth: "1200px" }}
            textAlign="center"
            mb="120px"
          >
            Andes Docs te acompaña en cada paso del proceso de creación de
            contratos. Desde la creación del borrador hasta la firma y
            distribución, Andes Docs te ayuda a optimizar tus procesos
            contractuales.
          </Typography>
          <Logo sx={{ width: "180px", mb: "30px" }} />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "100px",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                width: "calc(100% - 400px)",
                maxWidth: "800px",
                backgroundColor: colorPallette.primary,
                height: 2,
                left: "50%",
                transform: "translateX(-50%) translateY(-46px)",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                width: "80px",
                backgroundColor: colorPallette.white,
                height: 2,
                left: "50%",
                transform: "translateX(-50%) translateY(-46px)",
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              {steps.map((step, index) => (
                <Box
                  key={index}
                  sx={{
                    textAlign: "center",
                    maxWidth: "30%",
                    position: "relative",
                    px: 4,
                    pt: 4,
                    width: "320px",
                    minHeight: "400px",
                    borderRadius: "12px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    texAlign: "left",
                    boxShadow:
                      activeBox === index
                        ? "0px 30px 14px -20px rgba(0, 0, 0, 0.42)"
                        : "0px 0px 80px rgba(0, 0, 0, 0.03)",
                    backgroundColor:
                      activeBox === index
                        ? colorPallette.veryDark
                        : colorPallette.darkest,
                    transition: "all 0.45s",
                    transform:
                      activeBox === index
                        ? "scale(0.99) translateY(-12px)"
                        : "",
                    color:
                      activeBox === index
                        ? colorPallette.primary
                        : colorPallette.dark,
                  }}
                  mt={6}
                >
                  <Box
                    sx={{
                      width: 50,
                      height: 50,
                      borderRadius: "12px",
                      transition: "all 0.45s",
                      backgroundColor:
                        activeBox === index
                          ? colorPallette.secondary
                          : colorPallette.tertiary,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // border: "2px solid ",
                      // borderColor: colorPallette.primary,
                      position: "absolute",
                      left: "50%",
                      color:
                        activeBox === index
                          ? colorPallette.dark
                          : colorPallette.secondary,
                      transform:
                        activeBox === index
                          ? "translateX(-50%) translateY(-164px) scale(1.1) rotate(360deg)"
                          : "translateX(-50%) translateY(-150px)",
                    }}
                  >
                    <Typography variant="h6" fontWeight={700} fontSize="24px">
                      {index + 1}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      width: "2px",
                      backgroundColor: colorPallette.primary,
                      height: 36,
                      left: "50%",
                      transform: "translateX(-50%) translateY(-86px)",
                    }}
                  />
                  {/* <img
                  style={{
                    width: "300px",
                  }}
                  src={pattern}
                  alt="chat icon"
                /> */}
                  <FontAwesomeIcon
                    icon={step.icon}
                    style={{
                      position: "absolute",
                      right: 20,
                      // color: colorPallette.dark,
                    }}
                  />
                  <Typography width="100%" fontSize={12} textAlign="left">
                    Etapa {index + 1}
                  </Typography>
                  <Typography
                    variant="h6"
                    fontWeight="700"
                    gutterBottom
                    mb={2}
                    mt={0}
                    width="100%"
                    textAlign="left"
                    color={colorPallette.primary}
                  >
                    {step.title}
                  </Typography>
                  {/* <Image
                  name={"sparkles.png"}
                  style={{
                    width: "120px",
                    position: "absolute",
                    top: "28px",
                  }}
                /> */}
                  <Box
                    sx={{
                      height: 2,
                      width: 70,
                      bgcolor: colorPallette.secondary,
                      transform: "translateY(-5px)",
                    }}
                  />
                  {step.description.map((desc, descIndex) => (
                    <Typography
                      key={descIndex}
                      variant="body1"
                      gutterBottom
                      width="100%"
                      textAlign="left"
                      my={2}
                      // pl={1}
                    >
                      <FontAwesomeIcon
                        icon={faBolt}
                        color={colorPallette.primary}
                        className="mr1"
                      />
                      {desc}
                    </Typography>
                  ))}
                </Box>
              ))}
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ContractLifecycle;
