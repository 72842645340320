import { L10n } from "@syncfusion/ej2-base";

export const initializeLocalization = () => {
  L10n.load({
    es: {
      documenteditorcontainer: {
        New: "Nuevo",
        Open: "Abrir",
        Undo: "Deshacer",
        Redo: "Rehacer",
        Image: "Imagen",
        "Form Fields": "Campos",
        Table: "Tabla",
        Link: "Link",
        Bookmark: "Favoritos",
        Header: "Encabezado",
        Footer: "Pie",
        "Page Setup": "Configurar Página",
        "Page Number": "Número de Página",
        Break: "Salto",
        Find: "Buscar",
        "Local Clipboard": "Portapapeles Local",
        "Restrict Editing": "Restringir Edición",
        "Upload from computer": "Cargar archivo local",
        "By URL": "Por URL",
        "Page Break": "Salto de página",
        "Header And Footer": "Encabezado y pie",
        Options: "Opciones",
        Width: "Ancho",
        Height: "Altura",
        Text: "Texto",
        Paragraph: "Párrafo",
        "Add or edit the footer": "Insertar nota al pie",
        Single: "Simple",
        Double: "Doble",
        "New comment": "Nuevo comentario",
        Comments: "Comentarios",
        "Track Changes": "Control de cambios",
        "Current Page Number": "Página actual",
        "Read only": "Solo lectura",
        "Insert Footnote": "Insertar nota al pie",
        "Insert Endnote": "Insertar nota al final",
        "Table of Contents": "Tabla de Contenido",
        TrackChanges: "Control de cambios",
        "Update Fields": "Actualizar Campos",
        "Content Control": "Control de Contenido",
        "XML Mapping Pane": "Panel de asignación XML",
      },
    },
  });
};

export const serviceUrl: string =
  "https://services.syncfusion.com/react/production/api/documenteditor/";
