import React from "react";
import { connect } from "react-redux";
import FolderModal from "../Dashboard/DocumentsManager/FolderModal/FolderModal";
import NewFolderModal from "../Dashboard/DocumentsManager/NewFolderModal/NewFolderModal";
import DocumentDetailModal from "../Dashboard/DocumentsManager/DocumentDetailModal/DocumentDetailModal";
import FolderNameModal from "../Dashboard/DocumentsManager/FolderNameModal/FolderNameModal";
import FileUploadModal from "../Dashboard/FileUploadModal/FileUploadModal";
import ErrorModal from "../../uiComponents/ErrorModal/ErrorModal";
import LoadingModal from "../../uiComponents/LoadingModal/LoadingModal";
import SuccessModal from "../../uiComponents/SuccessModal/SuccessModal";
import ExpirationModal from "../../components/Expiration/ExpirationModal";
import ConfirmModal from "../../uiComponents/ConfirmModal/ConfirmModal.component";
import SignatureStatusModal from "../Dashboard/ElectronicSignature/SignatureStatusTable/SignatureStatusModal";
import SignerEmailModal from "../Dashboard/ElectronicSignature/SignatureStatusTable/SignatureRow/SignerEmailModal";
import { useAtomValue } from "jotai";
import JotaiConfirmModal from "../../uiComponents/Modal/JotaiConfirmModal/JotaiConfirmModal";
import { confirmationModalAtom } from "../../jotai/atoms.jotai";

const ModalWrapper: React.FC<any> = ({ modal }) => {
  const confirmationModal = useAtomValue(confirmationModalAtom);

  return (
    <div>
      {modal.folderModal.show && <FolderModal />}
      {modal.newFolderModal.show && <NewFolderModal />}
      {modal.documentDetailModal.show && <DocumentDetailModal />}
      {modal.folderNameModal.show && <FolderNameModal />}
      {modal.uploadFileModal.show && <FileUploadModal />}
      {modal.errorModal.show && <ErrorModal />}
      {modal.loadingModal.show && <LoadingModal />}
      {modal.successModal.show && <SuccessModal />}
      {modal.expirationModal.show && <ExpirationModal />}
      {modal.confirmModal.show && <ConfirmModal />}
      {modal.signatureStatusModal.show && <SignatureStatusModal />}
      {modal.signerEmailModal.show && <SignerEmailModal />}
      {confirmationModal.show && <JotaiConfirmModal />}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return { modal: state.modal };
};

export default connect(mapStateToProps, null)(ModalWrapper);
