import "../styles.css";

type ActionButtonProps = {
  label: any;
  icon: any;
  className: string;
  color: string;
  onClick: any;
};

export const ActionButton: React.FC<ActionButtonProps> = ({
  label,
  icon,
  className,
  color,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={`details-action-button b-${color} ${className}`}
    >
      <p className="details-action-button-label">{label}</p>
      <i className={icon}></i>
    </div>
  );
};
