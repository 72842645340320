import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type CompanyUser = {
  userId: string;
  name: string;
  photoUrl: string;
  internalUser?: boolean;
  companyName?: string;
};

export interface masterDataState {
  company: any;
  documents: any[];
  folders: null | any[];
  companyDocuments: null | any[];
  electronicSignatures: null | any[];
  spaceUsage: number;
  allCompanyUsers: CompanyUser[];
  user: {
    userId: string;
    companyId: string;
    photoUrl: string;
    role: string;
    documentShare: boolean;
    createdAt: string;
    name: string | null;
    email: string | null;
    internalUser: boolean;
  } | null;
}

const initialState: masterDataState = {
  company: null,
  documents: [],
  folders: null,
  companyDocuments: null,
  electronicSignatures: null,
  spaceUsage: 0,
  allCompanyUsers: [],
  user: null,
};

export const masterDataSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setCompany: (state, action: PayloadAction<any>) => {
      state.company = action.payload;
    },
    setDocuments: (state, action: PayloadAction<any>) => {
      state.documents = action.payload;
    },
    setFolders: (state, action: PayloadAction<any>) => {
      state.folders = action.payload;
    },
    setCompanyDocuments: (state, action: PayloadAction<any>) => {
      state.companyDocuments = action.payload;
    },
    setElectronicSignatures: (state, action: PayloadAction<any>) => {
      state.electronicSignatures = action.payload;
    },
    setSpaceUsage: (state, action: PayloadAction<any>) => {
      state.spaceUsage = action.payload;
    },
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setAllCompanyUsers: (state, action: PayloadAction<any>) => {
      state.allCompanyUsers = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setDocuments,
  setCompany,
  setCompanyDocuments,
  setFolders,
  setSpaceUsage,
  setElectronicSignatures,
  setUser,
  setAllCompanyUsers,
} = masterDataSlice.actions;

export default masterDataSlice.reducer;
