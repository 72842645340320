import { atom } from "jotai";

type UserNameType = {
  name: string;
};

type ConfirmationModalType = {
  disclaimer?: string | null;
  message: string;
  subMessage: string;
  onClose?: () => void;
  onConfirm?: () => void;
  loading?: boolean;
  fileName?: string | null;
  show: boolean;
};

export interface Signer {
  external_id: string;
  sign_url: string;
  token: string;
  status: string;
  name: string;
  lock_name: boolean;
  email: string;
  lock_email: boolean;
  hide_email: boolean;
  blank_email: boolean;
  phone_country: string;
  phone_number: string;
  lock_phone: boolean;
  hide_phone: boolean;
  blank_phone: boolean;
  times_viewed: number;
  last_view_at: string | null;
  signed_at: string | null;
  auth_mode: string;
  qualification: string;
  require_selfie_photo: boolean;
  require_document_photo: boolean;
  geo_latitude: number | null;
  geo_longitude: number | null;
  redirect_link: string;
  signature_image: string | null;
  visto_image: string | null;
  document_photo_url: string;
  document_verse_photo_url: string;
  selfie_photo_url: string;
  selfie_photo_url2: string;
  send_via: string;
  resend_attempts: number | null;
  cpf: string;
  cnpj: string;
  send_automatic_whatsapp_signed_file: boolean | null;
  liveness_photo_url: string;
  selfie_validation_type: string;
  ip: string | null;
}

// Ejemplo de uso de Signer
export interface MockSignatureData {
  sandbox: boolean;
  external_id: string;
  open_id: number;
  token: string;
  name: string;
  folder_path: string;
  status: string;
  rejected_reason: string | null;
  lang: string;
  original_file: string;
  signed_file: string | null;
  extra_docs: any[];
  created_through: string;
  deleted: boolean;
  deleted_at: string | null;
  signed_file_only_finished: boolean;
  disable_signer_emails: boolean;
  brand_logo: string;
  brand_primary_color: string;
  created_at: string;
  last_update_at: string;
  created_by: { email: string };
  template: string | null;
  signers: Signer[];
  answers: any[];
  auto_reminder: number;
  signature_report: string | null;
  tsa_country: string | null;
  use_timestamp: boolean;
}

export const signersAtom = atom<Signer[]>([]);

export const initialConfirmationModalState: ConfirmationModalType = {
  message: "",
  subMessage: "",
  show: false,
};

export const confirmationModalAtom = atom<ConfirmationModalType>(
  initialConfirmationModalState
);

export const userNameAtom = atom<UserNameType>({ name: "" });

export const profilePicModal = atom(false);
