import React from "react";
import { useHistory } from "react-router-dom";
import Image from "../../../uiComponents/Image/Image";
import "./styles.css";

type DocumentCardProps = {
  title1?: string;
  title2?: string;
  onClick?: () => void;
  index?: number;
  logo?: any;
  to?: string;
  price?: string;
};

const DocumentCard: React.FC<DocumentCardProps> = ({
  title1,
  title2,
  to,
  onClick,
  logo,
}) => {
  let history = useHistory();

  const handleClick = () => {
    if (to) {
      history.push(`/${to}`);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className="document-card document-card-available"
      onClick={handleClick}
    >
      <div className="document-card-start-div">
        <p className="document-card-text">{title1}</p>
        <p className="document-card-text-bold">{title2}</p>
      </div>
      <div className="logo-background b-white">
        {logo ? (
          <img
            src={logo}
            className="document-card-logo-company"
            alt="Company logo"
            title="Logo de la empresa"
          />
        ) : (
          <Image name={"andesdocs.png"} className="document-card-logo" />
        )}
      </div>
      {/* <div className="document-card-end-div">
        <span className="document-card-green-span">Comenzar</span>
        <i className="fas fa-chevron-right green"></i>
        <i className="fas fa-chevron-right green"></i>
      </div>
      <div className="document-card-description-div"></div> */}
    </div>
  );
};

DocumentCard.defaultProps = {
  title1: "Acuerdo",
  title2: "de confidencialidad",
};

export default DocumentCard;
