import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { colorPallette } from "../../Common/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import FormInput from "../Form/FormInput";
import { useForm } from "react-hook-form";
import { setAppError, setLoading } from "../../Utils/modalMethods";
import { useAuth0 } from "@auth0/auth0-react";
import { APIPostWithError } from "../../Services/authenticated";
import { useSetAtom } from "jotai";
import { userNameAtom } from "../../jotai/atoms.jotai";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { CompanyUser, setAllCompanyUsers } from "../../redux/slices/masterData";

type EditProfileNameProps = {
  user: any;
  closeModal: () => void;
};

export const EditProfileNameModal: React.FC<EditProfileNameProps> = ({
  user,
  closeModal,
}) => {
  const dispatch = useDispatch();
  const { allCompanyUsers } = useSelector(
    (state: RootState) => state.masterData
  );
  const setUserName = useSetAtom(userNameAtom);
  const { getAccessTokenSilently } = useAuth0();
  const { control, handleSubmit } = useForm({
    defaultValues: { name: user.name || "" },
  });

  const handleSave = async (data: any) => {
    try {
      setLoading(true);
      const accesToken = await getAccessTokenSilently();
      const updatedName = await APIPostWithError(
        "/profile/update-profile-name",
        accesToken,
        data
      );

      if (updatedName.success) {
        setUserName(data);
        setLoading(false);
        const updatedCompanyUsers = allCompanyUsers.map((u: CompanyUser) => {
          if (u.userId === user.userId) {
            return { ...u, name: data.name };
          }
          return u;
        });
        dispatch(setAllCompanyUsers(updatedCompanyUsers));
        closeModal();
      } else {
        setAppError(updatedName.message || "Error al actualizar el nombre.");
      }
    } catch (error) {
      console.error(error);
      setAppError("Hubo un problema al actualizar el nombre.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={closeModal}
      aria-labelledby="crop-image-dialog"
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          bgcolor: colorPallette.white,
          color: "white",
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, pb: 10 }}>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: colorPallette.white,
            width: "30px",
            height: "30px",
            backgroundColor: colorPallette.primary,
            "&:hover": {
              backgroundColor: colorPallette.dark,
            },
          }}
        >
          <FontAwesomeIcon icon={faClose} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" color={colorPallette.dark} pb={3}>
          Cambie su nombre aquí:
        </Typography>
        <FormInput
          name="name"
          control={control}
          maxLength={30}
          defaultValue={user.name}
        />
      </DialogContent>
      <Box justifyContent="center" pb={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(handleSave)}
          sx={{ mr: 2 }}
        >
          Guardar
        </Button>
        <Button variant="outlined" onClick={closeModal} sx={{ height: "54px" }}>
          Cancelar
        </Button>
      </Box>
    </Dialog>
  );
};
