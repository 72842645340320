import React from "react";
import { Document } from "../../../../Types/Document.types";
import Image from "../../../../uiComponents/Image/Image";

type NoDocumentsCreatedProps = {
  documents: Document[];
};

const NoDocumentsCreated: React.FC<NoDocumentsCreatedProps> = ({
  documents,
}) => {
  return (
    <div>
      {/* <div style={{ marginTop: 50 }}></div> */}
      <p className="dashboard-message" style={{ opacity: 0.5 }}>
        <i className="fas fa-play grey margin-r" />
        <span className="grey">Aun no creaste ningun documento</span>
      </p>
      <Image
        name="empty-folder.png"
        style={{ opacity: 0.07, width: "30vw", marginTop: 40 }}
        className="center"
      />
    </div>
  );
};

export default NoDocumentsCreated;
