import React, { useEffect, useState } from "react";
import IconButton from "../../../../uiComponents/IconButton/IconButton";
import { connect, useDispatch } from "react-redux";
import { setModal } from "../../../../redux/modal/modal.actions";
import SignatureStatusTable from "../SignatureStatusTable/SignatureStatusTable";
import ESignatureTable from "../../../../components/Tables/ESignature/ESignatureTable.component";
import FoldersPage from "../../DocumentsManager/FoldersPage/FoldersPage";
import AllSignaturesPage from "../AllSignaturesPage/AllSignaturesPage";
import { useAuth0 } from "@auth0/auth0-react";
import { setAppError, setLoading } from "../../../../Utils/modalMethods";
import { APIGetWithError } from "../../../../Services/authenticated";
import { setElectronicSignatures } from "../../../../redux/slices/masterData";
import { Document } from "../../../../Types/Document.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import "../styles.css";
import { Box } from "@mui/material";
import { colorPallette } from "../../../../Common/theme";

type NewElectronicSignatureScreenProps = {
  documents: Document[];
  setModal: (modal: any) => void;
  setEsignDocumentData: any;
  company?: string;
};

const NewElectronicSignatureScreen: React.FC<
  NewElectronicSignatureScreenProps
> = ({ documents, setModal, setEsignDocumentData }) => {
  const [selectedPage, setSelectedPage] = useState<any>(4);
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const getSignatures = async () => {
    const accessToken = await getAccessTokenSilently();

    try {
      setLoading(true);
      const res = await APIGetWithError(
        "/electronic-signature/get-all-signatures-view-only",
        accessToken
      );
      if (res && res.signatures) {
        dispatch(setElectronicSignatures(res.signatures));
      } else {
        setAppError();
      }
    } catch (error) {
      setAppError();
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {selectedPage === 1 && (
        <IconButton
          label="Cargar y firmar documento"
          icon="fas fa-cloud-upload-alt"
          className="electronic-signature-upload-button b-primary-hover"
          color={"secondary"}
          onClick={() => {
            setModal({
              uploadFileModal: {
                show: true,
              },
            });
          }}
        />
      )}
      {selectedPage === 4 && (
        <IconButton
          label="Nueva firma"
          icon="fa fa-signature"
          className="electronic-signature-upload-button b-primary-hover"
          color={"primary"}
          onClick={() => {
            setSelectedPage(1);
          }}
        />
      )}
      <div>
        <p className="dashboard-message">
          <i className="fas fa-play secondary margin-r" />
          {selectedPage === 1 && (
            <>
              Mostrando{" "}
              <span className=" document-manager-length">
                {documents ? documents.length : 0}{" "}
              </span>{" "}
              Documentos
            </>
          )}
          {selectedPage === 2 && (
            <>En progreso y firmados en detalle (Visualización de a 5 firmas)</>
          )}
          {selectedPage === 4 && (
            <>Visualización de todas las firmas que fueron generadas:</>
          )}
        </p>
      </div>
      <Box
        className="electronic-signature-action-box"
        mt={4}
        zIndex={9999}
        position={"relative"}
        onClick={() => {
          setSelectedPage(4);
        }}
      >
        {documents && selectedPage === 1 && (
          <Box
            sx={{
              cursor: "pointer",
              fontSize: "0.9rem",
              color: colorPallette.primary,
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="mr1" /> Volver a
            Firmas iniciadas
          </Box>
        )}
      </Box>
      {documents && selectedPage === 1 && (
        <ESignatureTable
          documents={documents}
          setEsignDocumentData={setEsignDocumentData}
        />
      )}
      {documents && selectedPage === 4 && (
        <AllSignaturesPage getSignatures={getSignatures} />
      )}
    </>
  );
};

const mapDispatchToProps = {
  setModal,
};

export default connect(null, mapDispatchToProps)(NewElectronicSignatureScreen);
