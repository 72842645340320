import React from "react";
import Text from "../../../uiComponents/Text";
import "../styles.css";
import { isSafari } from "react-device-detect";

const mes = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];

type DateProps = {
  question: any;
  onChange: any;
  sectionId: any;
  currentSectionAnswers: any;
  currentAnswer?: any;
};

const DateWithoutSpace: React.FC<DateProps> = ({
  question,
  onChange,
  sectionId,
  currentSectionAnswers,
}) => {
  const dateFormater = (e: any) => {
    let dateFormat = `${new window.Date(e.target.value).getUTCDate()} de ${
      mes[new window.Date(e.target.value).getUTCMonth()]
    } del ${new window.Date(e.target.value).getUTCFullYear()}`;
    return dateFormat;
  };

  return (
    <div>
      {isSafari && (
        <Text blue className="margin-v">
          Introducir la fecha con el siguiente formato: 23 del agosto de 2021
        </Text>
      )}
      <div className="question-format-form">
        {!isSafari && (
          <input
            required
            className="question-format-input"
            onChange={(e) => {
              onChange({
                [sectionId]: {
                  ...currentSectionAnswers,
                  [question.id]: {
                    question: question.question,
                    answer: dateFormater(e),
                  },
                },
              });
            }}
            type="date"
          ></input>
        )}
        {isSafari && (
          <input
            required
            className="question-format-input"
            onChange={(e) => {
              onChange({
                [sectionId]: {
                  ...currentSectionAnswers,
                  [question.id]: {
                    question: question.question,
                    answer: e.target.value,
                  },
                },
              });
            }}
          ></input>
        )}
      </div>
    </div>
  );
};

export default DateWithoutSpace;
