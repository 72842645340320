import React, { useEffect } from "react";
import Image from "../../../uiComponents/Image/Image";
import { Box, Button } from "@mui/material";
import { cardStyle, colorPallette, gradients } from "../../../Common/theme";
import pattern from "../../../Assets/svg/pattern.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import "./styles.css";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box
      sx={{
        background: gradients.dark,
        pt: 6,
        pb: 10,
        minHeight: "100vh",
      }}
    >
      <Box
        component={"img"}
        sx={{
          width: "1800px",
          opacity: 0.4,
          maxWidth: "100%",
          position: "absolute",
          display: { xs: "none", sm: "none", md: "block" },
          filter: "invert(1)",
        }}
        src={pattern}
        alt="chat icon"
        title="Icono del chat"
      />
      <h1 style={{ color: colorPallette.white }}>Contacto</h1>
      <Box className="intro" px={2}>
        Contactate para solicitar una demo o un presupuesto. Estamos para lo que
        necesites.
      </Box>
      <Box
        className="center"
        sx={{
          maxWidth: 500,
          width: "90vw",
          mt: 5,
          borderRadius: "8px",
          pt: 4,
          ...cardStyle.dark,
        }}
      >
        <Image name="AndesSquareWeb.png" style={{ width: 120 }} />
        <div className="contact-grid center"></div>
        <div className="contact-grid-2 center">
          <div>
            <p className="contact-item contact-item-1">
              <i className="fas fa-phone-alt primary margin-icon"></i>
              Telefono:
            </p>
            <p className="contact-item  contact-item-1">
              <i className="far fa-envelope primary margin-icon"></i>
              Email:
            </p>
          </div>
          <div>
            <p className="contact-item contact-item-2">+54 11 3107 3107</p>
            <p className="contact-item contact-item-2">
              nicolas.gaggini@andesdocs.com
            </p>
          </div>
        </div>
        <p className="margin-v">Buenos Aires, Argentina</p>
        <Button
          variant="outlined"
          sx={{ width: "90%", mb: 3, mt: 4, height: 58 }}
          onClick={() =>
            window.open(
              "https://api.whatsapp.com/send?phone=5491131073107&text=Hola,%20te%20contacto%20por%20Andes%20Docs!"
            )
          }
        >
          Contactate por Whatsapp
          <FontAwesomeIcon
            icon={faBolt}
            style={{
              marginLeft: 8,
              color: colorPallette.white,
              fontSize: 12,
            }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default Contact;
