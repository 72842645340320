import { Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { colorPallette } from "../../Common/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { useAtom } from "jotai";
import { profilePicModal } from "../../jotai/atoms.jotai";
import AvatarEditorModal from "./AvatarEditorModal";

type Props = {
  name: string;
  editable: boolean;
  size?: number;
};

// Colores a rotar
const colors = [
  colorPallette.primary,
  colorPallette.lightRed,
  colorPallette.dark,
];

// Función para obtener el color basado en la letra
const getColorForLetter = (letter: string): string => {
  const letterIndex = letter.charCodeAt(0) - "A".charCodeAt(0); // Obtener índice basado en la letra
  const colorIndex = letterIndex % colors.length; // Rotar entre colores
  return colors[colorIndex];
};

export const AvatarLetter: React.FC<Props> = ({ name, editable, size }) => {
  const namesArray = name.split(" ");
  const firstLetter = namesArray[0]
    ? namesArray[0].charAt(0).toUpperCase()
    : "";
  const secondLetter = namesArray[1]
    ? namesArray[1].charAt(0).toUpperCase()
    : "";
  const initials = firstLetter + secondLetter;

  const [modalOpen, setModalOpen] = useAtom(profilePicModal);
  const [bgColor, setBgColor] = useState<string>("");

  useEffect(() => {
    // Asignar color basado en la primera letra
    const color = getColorForLetter(firstLetter);
    setBgColor(color);
  }, [firstLetter]);

  const updateAvatar = (imgSrc: string) => {
    // Aquí puedes manejar la lógica para actualizar el avatar si es necesario
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: size,
          height: size,
          minWidth: 40,
          minHeight: 40,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: bgColor,
          borderRadius: "50%",
          color: "#fff",
          fontWeight: "bold",
        }}
      >
        {initials}
      </Box>
      {editable && (
        <IconButton
          sx={{
            position: "absolute",
            color: colorPallette.white,
            minWidth: "30px",
            minHeight: "30px",
            bottom: -12,
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: colorPallette.primary,
            "&:hover": {
              backgroundColor: colorPallette.dark,
            },
          }}
          onClick={() => setModalOpen(true)}
        >
          <FontAwesomeIcon icon={faPencil} fontSize={15} />
        </IconButton>
      )}
      {modalOpen && (
        <AvatarEditorModal
          updateAvatar={updateAvatar}
          closeModal={() => setModalOpen(false)}
        />
      )}
    </Box>
  );
};
