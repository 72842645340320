import React, { useEffect, useState } from "react";
import { colorPallette } from "../../Common/theme";
import { Box, Typography } from "@mui/material";

const ChangingWord = () => {
  const [show, setShow] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setShow((prevBox: number) => (prevBox === 0 ? 1 : 0));
    }, 400); // Change every 500ms
    return () => clearInterval(interval);
  }, []);

  const words = ["Potente", "Rápido", "Seguro", "Intuitivo", "Eficiente"];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState("");
  const [charIndex, setCharIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const handleTyping = () => {
      if (!isDeleting && charIndex < words[currentWordIndex].length) {
        // Typing effect: add one character
        setDisplayedText((prev) => prev + words[currentWordIndex][charIndex]);
        setCharIndex((prev) => prev + 1);
      } else if (isDeleting && charIndex > 0) {
        // Deleting effect: remove one character
        setDisplayedText((prev) => prev.slice(0, -1));
        setCharIndex((prev) => prev - 1);
      } else if (!isDeleting && charIndex === words[currentWordIndex].length) {
        // Start deleting after a pause
        setTimeout(() => setIsDeleting(true), 1000);
      } else if (isDeleting && charIndex === 0) {
        // Move to the next word and reset
        setIsDeleting(false);
        setCurrentWordIndex((prev) => (prev + 1) % 5);
      }
    };

    const typingSpeed = isDeleting ? 40 : 90;
    const typingInterval = setInterval(handleTyping, typingSpeed);

    return () => clearInterval(typingInterval);
  }, [charIndex, isDeleting, currentWordIndex]);

  return (
    <Box
      width={295}
      sx={{
        ml: { xs: 0, md: 2 },
      }}
    >
      <Typography
        variant="h1"
        sx={{
          mb: 2,
          mt: { xs: 0, md: 4 },
          color: colorPallette.primary,
          fontWeight: 900,
          fontSize: { xs: "2.1rem", md: "4.5rem" },
          textAlign: { xs: "center", md: "left" },
        }}
        className="roboto"
        mb={8}
      >
        {" "}
        {displayedText}
        <Box
          component={"span"}
          sx={{
            display: "inline-block",
            transform: { xs: "translateY(-2px)", md: "translateY(-7px)" },
            color: colorPallette.dark,
            fontWeight: "lighter",
            opacity: show,
            transition: "opacity 0.4s",
          }}
        >
          |
        </Box>
      </Typography>
    </Box>
  );
};

export default ChangingWord;
