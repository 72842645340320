import { ProfilePicAndName } from "../../../../../uiComponents/ProfilePicAndName/ProfilePicAndName";
import {
  findUserNameById,
  findUserPhotoUrl,
} from "../../../../../Utils/commonTableActions";

import "./styles.css";

type CreatorFieldProps = {
  document: any;
};

const CreatorField: React.FC<CreatorFieldProps> = ({ document }) => {
  const userName = findUserNameById(document?.userId);
  const profileImage = findUserPhotoUrl(document?.userId);

  return (
    <div className="detail-field-wrapper">
      <div className="creator-field-wrapper b-secondary">
        <div className="creator-field-text-box">
          <p className="creator-field-message">Creado por</p>
          <ProfilePicAndName image={profileImage} name={userName} />
        </div>
      </div>
    </div>
  );
};

export default CreatorField;
