import React from "react";
import "./styles.css";
import { setDetails } from "../../../../../redux/fileDetail/fileDetail.actions";
import { connect } from "react-redux";

type TextAreaFieldProps = {
  field: string;
  fieldName: string;
  edit: boolean;
  editable: boolean;
  setDetails: any;
  logicalName: string;
  details: any;
};

const TextAreaField: React.FC<TextAreaFieldProps> = ({
  field,
  fieldName,
  edit,
  editable,
  setDetails,
  logicalName,
  details,
}) => {
  console.log("field", field);

  let text = field ? field : "Sin Definir";
  if (text === "null") {
    text = "Sin Definir";
  }
  console.log("text", text);
  return (
    <div>
      {(edit || !field) && (
        <div
          className="text-area-field-wrapper"
          style={!field && !edit ? { marginTop: 50 } : { marginTop: 15 }}
        >
          <i className="far fa-play-circle secondary margin-r"></i>
          <div>
            {fieldName}:{" "}
            {!edit && (
              <span>
                {!field && (
                  <span className="text-area-field-undefined">Sin Definir</span>
                )}
              </span>
            )}
          </div>
        </div>
      )}
      {field && !edit && (
        <div className="text-area-field-text b-primary">
          <p className="text-area-field-text-title">{fieldName}</p>
          <p>{text}</p>
        </div>
      )}
      {edit && editable && (
        <textarea
          className="text-area-field-input"
          maxLength={500}
          onChange={(e) => {
            setDetails({ [logicalName]: e.target.value });
          }}
          defaultValue={details[logicalName] ? details[logicalName] : text}
        />
      )}
      {edit && !editable && (
        <p className="bold text-area-field-not-editable">
          {text}{" "}
          <span className="text-area-field-not-remark">- No editable </span>
        </p>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    details: state.details,
  };
};

const mapDispatchToProps = {
  setDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(TextAreaField);
