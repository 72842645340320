import {
  AlignmentType,
  BorderStyle,
  Footer,
  Header,
  ImageRun,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType,
} from "docx";
import {
  toribio_achaval_footer,
  toribio_achaval_header,
} from "../docImages/toribio-achaval";

export const styles_toribio = {
  companyId: ["12390"],
  companyName: "ToribioAchaval",
  font: "Arial",
  titleSize: 22,
  textSize: 22,
  lineSpacing: 300,
  marginRight: 1500,
  marginLeft: 1500,
  beforeParagraph: 300,

  header: new Header({
    children: [
      new Paragraph({
        spacing: {
          before: 300,
          after: 500,
        },
        alignment: AlignmentType.LEFT,
        children: [
          new ImageRun({
            data: toribio_achaval_header,
            transformation: {
              width: 225,
              height: 40,
            },
          }),
        ],
      }),
    ],
  }),

  footer: new Footer({
    children: [
      new Table({
        alignment: AlignmentType.CENTER,
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  // Párrafo vacío para agregar espacio arriba de la imagen
                  new Paragraph({
                    spacing: { before: 0, after: 200 }, // Ajusta estos valores según el espacio que necesites
                    children: [],
                  }),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new ImageRun({
                        data: toribio_achaval_footer,
                        transformation: {
                          width: 170,
                          height: 20,
                        },
                      }),
                    ],
                  }),
                ],
                width: { size: 20, type: WidthType.PERCENTAGE },
                borders: {
                  top: { size: 0, color: "ffffff", style: BorderStyle.NONE },
                  bottom: { size: 0, color: "ffffff", style: BorderStyle.NONE },
                  left: { size: 0, color: "ffffff", style: BorderStyle.NONE },
                  right: { size: 0, color: "ffffff", style: BorderStyle.NONE },
                },
                margins: {
                  left: 100,
                  right: 100,
                },
              }),
              new TableCell({
                children: [],
                width: { size: 30, type: WidthType.PERCENTAGE },
                borders: {
                  top: { size: 0, color: "ffffff", style: BorderStyle.NONE },
                  bottom: { size: 0, color: "ffffff", style: BorderStyle.NONE },
                  left: { size: 0, color: "ffffff", style: BorderStyle.NONE },
                  right: { size: 0, color: "ffffff", style: BorderStyle.NONE },
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    spacing: { before: 400, after: 100 },
                    children: [
                      new TextRun({
                        text: "Av. Callao 1515. Piso 4 (011) 4819 4000",
                        size: 22,
                        font: "Helvetica Neue",
                      }),
                    ],
                  }),
                ],
                width: { size: 50, type: WidthType.PERCENTAGE },
                borders: {
                  top: { size: 0, color: "ffffff", style: BorderStyle.NONE },
                  bottom: { size: 0, color: "ffffff", style: BorderStyle.NONE },
                  left: { size: 0, color: "ffffff", style: BorderStyle.NONE },
                  right: { size: 0, color: "ffffff", style: BorderStyle.NONE },
                },
                margins: {
                  left: 100,
                  right: 100,
                },
              }),
            ],
          }),
        ],
        borders: {
          top: { size: 0, color: "ffffff", style: BorderStyle.NONE },
          bottom: { size: 0, color: "ffffff", style: BorderStyle.NONE },
          left: { size: 0, color: "ffffff", style: BorderStyle.NONE },
          right: { size: 0, color: "ffffff", style: BorderStyle.NONE },
        },
      }),
    ],
  }),
};
