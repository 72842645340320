import {  Header, Paragraph, Footer } from "docx";


export const styles_flechabus = {
  companyId: ["12391"], 
  font: "Times New Roman",
  titleSize: 25,
  textSize: 21,
  lineSpacing: 440,
  marginRight: 880,
  marginLeft: 880,
  beforeParagraph: 300,
  
  header: new Header({
    children: [
      new Paragraph({
        spacing: {
          before: 0,
          after: 550,
        },
  }),
]}),

footer: new Footer({
    children: [],
  }),
};
