import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import AutomaticForm, { Field } from "../Form/AutomaticForm";
import { useForm } from "react-hook-form";
import { colorPallette } from "../../Common/theme";

type EditUserModalProps = {
  user: any;
  isOpen: boolean;
  onClose: () => void;
  onSave: (updatedUser: any) => void;
};

const roleOptions = [
  { value: "admin", label: "Admin" },
  { value: "user", label: "User" },
];

const documentShareOptions = [
  { value: "true", label: "Todos los documentos de la empresa" },
  { value: "false", label: "Solo los documentos del usuario" },
];

const EditUserModal: React.FC<EditUserModalProps> = ({
  user,
  isOpen,
  onClose,
  onSave,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      role: user?.role,
      documentShare: user?.documentShare ? "true" : "false",
    },
  });

  useEffect(() => {
    setValue("role", user?.role);
    setValue("documentShare", user?.documentShare ? "true" : "false");
  }, [user, setValue]);

  const handleSave = async (data: any) => {
    setLoading(true);
    await onSave({
      ...user,
      role: data.role,
      documentShare: data.documentShare === "true",
    });
    setLoading(false);
  };

  const fields: Field[] = [
    {
      name: "role",
      label: "Rol",
      type: "options",
      required: true,
      options: roleOptions,
    },
    {
      name: "documentShare",
      label: "Acceso a",
      type: "options",
      required: true,
      options: documentShareOptions,
    },
  ];

  const truncateName = (name: string | undefined) => {
    if (!name) return "";
    return name.length > 15 ? `${name.slice(0, 15)}...` : name;
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          padding: 4,
          backgroundColor: "white",
          margin: "auto",
          top: "20%",
          position: "relative",
          width: "450px",
        }}
      >
        <i
          className="far fa-window-close confirmation-modal-close"
          onClick={onClose}
        ></i>
        <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
          <Typography variant="h6" mb={"40px"}>
            Editar Usuario:
          </Typography>
          <Typography variant="h6" color={colorPallette.primary}>
            {truncateName(user?.name)}
          </Typography>
        </Box>

        <AutomaticForm fields={fields} control={control} />
        <Button
          onClick={handleSubmit(handleSave)}
          variant="contained"
          color="primary"
          sx={{ mt: "40px", width: "100%" }}
          startIcon={
            loading ? <CircularProgress size={20} color="secondary" /> : null
          }
        >
          {loading ? null : "Guardar Cambios"}
        </Button>
      </Box>
    </Modal>
  );
};

export default EditUserModal;
