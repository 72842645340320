import {
  AlignmentType,
  Footer,
  Header,
  ImageRun,
  PageNumber,
  Paragraph,
  TextRun,
} from "docx";
import { izrastzoffHeader } from "../docImages/izrastzoff";

export const styles_izrastzoff = {
  companyId: ["12401", "12407"],
  companyName: "Izrastzoff",
  font: "Times New Roman",
  titleSize: 21,
  textSize: 20,
  lineSpacing: 310,
  marginRight: 880,
  marginLeft: 880,
  beforeParagraph: 300,

  header: new Header({
    children: [
      new Paragraph({
        spacing: {
          before: 0,
          after: 450,
        },
        alignment: AlignmentType.CENTER,
        children: [
          new ImageRun({
            data: izrastzoffHeader,
            transformation: {
              width: 400,
              height: 80,
            },
          }),
        ],
      }),
    ],
  }),

  footer: new Footer({
    children: [
      new Paragraph({
        spacing: {
          before: 300,
          after: 0,
        },
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            text: "www.izr.com.ar",
            size: 22,
            font: "Arial",
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.RIGHT,
        children: [
          new TextRun({
            children: [PageNumber.CURRENT],
          }),
        ],
      }),
    ],
  }),
};
