import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

type LogInButtonProps = {
  className: string;
  label?: any;
  style?: any;
};

const LogInButton: React.FC<LogInButtonProps> = ({
  className = "",
  label,
  style = {},
}) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div
      onClick={() => {
        loginWithRedirect();
      }}
      style={style}
      className={className}
    >
      {label}
    </div>
  );
};

export default LogInButton;
