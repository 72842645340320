import { Box, Button, Modal, Typography } from "@mui/material";
import { useAtom } from "jotai";
import React from "react";
import { useHistory } from "react-router-dom";
import { profilePicModal } from "../../jotai/atoms.jotai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { colorPallette } from "../../Common/theme";
import { faFaceLaugh } from "@fortawesome/free-regular-svg-icons";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const ProfilePicAlertModal: React.FC<Props> = ({ open, onClose }) => {
  const history = useHistory();

  const [, setProfilePicModal] = useAtom(profilePicModal);

  return (
    // <Modal
    //   open={open}
    //   onClose={onClose}
    //   aria-labelledby="modal-modal-title"
    //   aria-describedby="modal-modal-description"
    // >
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        onClick: () => null, // Disable backdrop clicks
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          height: 230,
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          px: 3,
          py: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "10px",
          border: "none",
        }}
      >
        {/* <i
          className="far fa-window-close confirmation-modal-close"
          onClick={onClose}
        ></i> */}
        <Typography
          id="modal-modal-title"
          variant="h5"
          fontWeight="bold"
          component="h2"
        >
          Aun no cargaste tu foto de perfil
          <FontAwesomeIcon
            icon={faFaceLaugh}
            className="ml1"
            color={colorPallette.primary}
          />
        </Typography>
        <p>*Tambien podes editar tu nombre!</p>
        <Button
          onClick={() => {
            history.push("/dashboard/profile");
            setProfilePicModal(true);
            onClose();
          }}
          sx={{ width: "100%" }}
          variant="contained"
        >
          Cargar ahora!
          <FontAwesomeIcon icon={faBolt} className="ml1" />
        </Button>
      </Box>
    </Modal>
  );
};
