import React from "react";
import "../styles.css";

type QuestionProps = {
  question: any;
  onChange: any;
  currentAnswer: any;
  sectionId: any;
  currentSectionAnswers: any;
};

const Question: React.FC<QuestionProps> = ({
  question,
  onChange,
  currentAnswer,
  sectionId,
  currentSectionAnswers,
}) => {
  return (
    <div className="question-format-form">
      <input
        required
        type="text"
        value={currentAnswer}
        className="question-format-input"
        maxLength={150}
        onChange={(e) => {
          let valueNormalized =
            typeof e.target.value === "string" ? e.target.value.split("") : [];
          let newValue = valueNormalized
            .filter((val) =>
              /^[.áÁéÉíÍóÓúÚñÑa-zA-Z0-9,!?@$¿¡(): ´%+"-_]*$/.test(val)
            )
            .join("");
          onChange({
            [sectionId]: {
              ...currentSectionAnswers,
              [question.id]: {
                question: question.question,
                answer: newValue,
              },
            },
          });
        }}
      ></input>
    </div>
  );
};

export default Question;
