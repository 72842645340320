import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faChevronCircleRight,
  faCheckSquare,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../uiComponents/Button/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { APIPostWithError } from "../../../../Services/authenticated";
import { setAppError, setLoading } from "../../../../Utils/modalMethods";
import { Document } from "../../../../Types/Document.types";
import { Signer } from "../../../../Types/Signer.types";
import { useHistory } from "react-router-dom";
import { useAtom } from "jotai";
import { signersAtom } from "../../../../jotai/atoms.jotai";

type ConfirmESignatureProps = {
  eSignersData: Signer[] | null;
  document: Document;
  isSequential: boolean;
};

const ConfirmESignature: React.FC<ConfirmESignatureProps> = ({
  eSignersData,
  document,
  isSequential,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const [, setSigners] = useAtom(signersAtom);

  const onSubmit = async () => {
    setLoading(true);
    try {
      const params = {
        signers: eSignersData,
        fileName: document.fileName,
        documentId: document.documentId,
        filePath: document.filePath,
        format: document.documentType,
        isSequential,
      };

      const accesToken = await getAccessTokenSilently();
      const res = await APIPostWithError(
        "/electronic-signature/request-new-signature",
        accesToken,
        params
      );
      if (res && res.status === "ok") {
        setSigners(res.data);
        setLoading(false);
        window.scrollTo(0, 0);
        history.push("/dashboard/ESignersDataLinks");
      } else {
        setAppError();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="e-signature-confirm-wrapper">
      <div>
        <h4 className="margin-v margin-l">
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="margin-icon primary"
          />
          Todo listo
        </h4>
        <p className="margin-l">
          Antes de seguir <span className="primary">recuerde:</span>
        </p>
        <div className="e-sign-form-card b-white">
          <p>
            <FontAwesomeIcon
              icon={faChevronCircleRight}
              className="margin-icon primary"
            />
            Confirmar que el documento seleccionado es la version final y no
            contiene errores
          </p>
          <p>
            <FontAwesomeIcon
              icon={faChevronCircleRight}
              className="margin-icon primary"
            />
            Los datos ingresados de los firmantes son los correctos
          </p>
          <ul>
            {eSignersData &&
              eSignersData.map((signer, index) => {
                return (
                  <li className="margin-v" key={index}>
                    <FontAwesomeIcon
                      icon={faCheckSquare}
                      className="margin-icon primary"
                    />
                    {signer.name} :{" "}
                    <span className="bold roboto">{signer.email}</span>
                  </li>
                );
              })}
          </ul>
        </div>
        <Button
          label="Generar Firma Electrónica"
          size="large"
          className="center custom-button"
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default ConfirmESignature;
