import React from "react";
import { connect } from "react-redux";
import QuestionSelector from "../QuestionSelector/QuestionSelector";

type QuestionMapperProps = {
  data: any;
  currentSection: any;
  sectionId: any;
  setCurrentSection: any;
  answers: any;
};

const QuestionMapper: React.FC<QuestionMapperProps> = ({
  data,
  currentSection,
  sectionId,
  setCurrentSection,
  answers,
}) => {
  const thisSection = data.sections[currentSection - 1];

  // this gets all the answers of the section, it is needed to spread it inside the objecto of answers
  const currentSectionAnswers = answers.hasOwnProperty(sectionId)
    ? answers[sectionId]
    : {};
  return (
    <div>
      {thisSection.questions.map((question: any) => {
        if (question.type === "documentName") {
          return (
            <QuestionSelector
              question={question}
              key={question.id}
              sectionId={sectionId}
            />
          );
        }
        if (
          question.depend &&
          !question.dependInclude &&
          currentSectionAnswers[question.depend.id] &&
          currentSectionAnswers[question.depend.id].answer ===
            question.depend.answer
        ) {
          return (
            <QuestionSelector
              question={question}
              key={question.id}
              sectionId={sectionId}
            />
          );
        }
        // depend include is used for cases where you want a depend to be dependant upon a multiple options
        //question
        if (
          question.dependInclude &&
          currentSectionAnswers[question.dependInclude.id] &&
          currentSectionAnswers[question.dependInclude.id].answer.includes(
            question.dependInclude.answer
          )
        ) {
          return (
            <QuestionSelector
              question={question}
              key={question.id}
              sectionId={sectionId}
            />
          );
        }

        if (
          question.notBe &&
          currentSectionAnswers[question.notBe.id] &&
          currentSectionAnswers[question.notBe.id].answer !==
            question.notBe.answer
        ) {
          return (
            <QuestionSelector
              question={question}
              key={question.id}
              sectionId={sectionId}
            />
          );
        }

        if (
          question.doubleDepend &&
          currentSectionAnswers[question.doubleDepend[0].id] &&
          currentSectionAnswers[question.doubleDepend[1].id] &&
          currentSectionAnswers[question.doubleDepend[0].id].answer ===
            question.doubleDepend[0].answer &&
          currentSectionAnswers[question.doubleDepend[1].id].answer ===
            question.doubleDepend[1].answer
        ) {
          return (
            <QuestionSelector
              question={question}
              key={question.id}
              sectionId={sectionId}
            />
          );
        }
        // a type of selector where depend is double
        // with an array dependency and an option dependency
        if (
          question.dependIncludeDouble &&
          currentSectionAnswers[question.dependIncludeDouble[0].id] &&
          currentSectionAnswers[
            question.dependIncludeDouble[0].id
          ].answer.includes(question.dependIncludeDouble[0].answer) &&
          currentSectionAnswers[question.dependIncludeDouble[1].id] &&
          currentSectionAnswers[question.dependIncludeDouble[1].id].answer ===
            question.dependIncludeDouble[1].answer
        ) {
          return (
            <QuestionSelector
              question={question}
              key={question.id}
              sectionId={sectionId}
            />
          );
        }

        if (
          !question.depend &&
          !question.notBe &&
          !question.doubleDepend &&
          !question.dependInclude &&
          !question.dependIncludeDouble
        )
          return (
            <QuestionSelector
              question={question}
              key={question.id}
              sectionId={sectionId}
            />
          );
      })}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    answers: state.answers,
  };
};

export default connect(mapStateToProps)(QuestionMapper);
