import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { colorPallette } from "../../Common/theme";

import "./button.css";

interface ButtonProps {
  onClick?: () => Promise<void> | void;
  color?: "primary" | "secondary" | "transparent" | "tertiary" | string;
  customColor?: string;
  size?: "small" | "medium" | "large";
  shadow?: boolean;
  label?: string;
  animated?: boolean;
  style?: React.CSSProperties;
  className?: string;
  inactive?: boolean;
  center?: boolean;
  type?: "button" | "submit" | "reset";
  to?: string;
  whatsapp?: boolean;
  [x: string]: any; // To allow any other props
}

/**
 * Primary UI component for user interaction
 */
const Button: React.FC<ButtonProps> = ({
  onClick,
  color,
  customColor,
  size,
  shadow,
  label,
  animated,
  style,
  className,
  inactive,
  center,
  type,
  to,
  whatsapp,
  ...props
}) => {
  const history = useHistory();

  const hasShadow = shadow ? "storybook-button--shadow" : null;
  const isInactive = inactive ? { opacity: 0.3 } : null;

  const buttonColor = () => {
    if (color === "primary") {
      return "b-primary b-primary-hover";
    }
    if (color === "secondary") {
      return "b-secondary b-secondary-hover";
    }
    if (color === "transparent") {
      return "button-transparent";
    }
    if (color === "tertiary") {
      return "b-tertiary";
    }
    return "b-primary b-primary-hover";
  };

  const clicker = () => {
    if (onClick) {
      onClick();
      return;
    }
    if (to) {
      history.push(`${to}`);
    }
  };

  return (
    <div className={`button-center-${center}`}>
      <div
        className={[
          `storybook-button--${size}`,
          `${buttonColor()}`,
          hasShadow,
          "storybook-button",
          className,
        ].join(" ")}
        style={{ backgroundColor: customColor, ...isInactive, ...style }}
        {...props}
        onClick={!inactive ? clicker : undefined}
      >
        <p
          className={
            !inactive && animated
              ? `button-label label--${size}`
              : "button-label label--still"
          }
        >
          {label}
          {whatsapp && (
            <i className="fab fa-whatsapp white" style={{ marginLeft: 10 }}></i>
          )}
        </p>
        {!inactive && animated && (size === "small" || size === "medium") && (
          <FontAwesomeIcon icon={faArrowRight} className="button-icon-medium" />
        )}
        {!inactive && animated && size === "large" && (
          <FontAwesomeIcon icon={faArrowRight} className="button-icon-large" />
        )}
      </div>
    </div>
  );
};

// Default props
Button.defaultProps = {
  customColor: colorPallette.primary,
  size: "small",
  shadow: true,
  color: "black",
  onClick: undefined,
  label: "button",
  animated: false,
  inactive: false,
  center: false,
  type: "button",
  to: undefined,
  whatsapp: false,
};

export default Button;
