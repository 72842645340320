import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface TriggersData {
  editedDocumentName: string | null;
}

const initialState: TriggersData = {
  editedDocumentName: null,
};

export const triggersSlice = createSlice({
  name: "editorData",
  initialState,
  reducers: {
    setEditedDocumentName: (state, action: PayloadAction<any>) => {
      state.editedDocumentName = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setEditedDocumentName } = triggersSlice.actions;

export default triggersSlice.reducer;
