import React, { useEffect } from "react";
import Text from "../../../uiComponents/Text";
import Question from "../Question/Question";
import Options from "../Options/Options";
import Date from "../Date/Date";
import DateWithoutSpace from "../DateWithoutSpace/DateWithoutSpace";
import Number from "../Number/Number";
import Money from "../Money/Money";
import TextArea from "../TextArea/TextArea";
import MultipleOptions from "../MultipleOptions/MultipleOptions";
import { connect } from "react-redux";
import { setAnswers } from "../../../redux/answers/answers.actions";
import Pesos from "../Pesos/Pesos";
import Catastro from "../Catastro/Catastro";
import Percentage from "../Percentage/Percentage";
import MoneyAndDates from "../MoneyAndDates/MoneyAndDates";
import MoneyAndDatesWithIVA from "../MoneyAndDatesWithIVA/MoneyAndDatesWithIVA";
import InfoNotReadyWrapper from "../InfoNotReadyWrapper/InfoNotReadyWrapper";
import SearchServerOptions from "../SearchServerOptions/SearchServerOptions";
import AdvancedOptions from "../AdvancedOptions/AdvancedOptions";
import DateCalculator from "../DateCalculator/DateCalculator";
import DocumentName from "../DocumentName/DocumentName";
import NumberOf from "../NumberOf/NumberOf";

import "../styles.css";

//Question selector basically gets a question and decides which type of input it should have

type QuestionSelectorProps = {
  question: any;
  answers: any;
  setAnswers: any;
  sectionId: any;
};

const QuestionSelector: React.FC<QuestionSelectorProps> = ({
  question,
  answers,
  setAnswers,
  sectionId,
}) => {
  const setActiveInputs = (questionId: any) => {
    if (sessionStorage.activeInputs) {
      const prevInputs = JSON.parse(sessionStorage.activeInputs);
      prevInputs.push(question.id);
      sessionStorage.setItem("activeInputs", JSON.stringify(prevInputs));
    } else {
      const newActiveInputs = [questionId];
      sessionStorage.setItem("activeInputs", JSON.stringify(newActiveInputs));
    }
  };

  // this checks the current answer of the question and returns it
  const currentAnswer =
    question.type !== "documentName" &&
    answers.hasOwnProperty(sectionId) &&
    answers[sectionId].hasOwnProperty(question.id)
      ? answers[sectionId][question.id].answer
      : null;

  const currentAnswerObject =
    answers.hasOwnProperty(sectionId) &&
    answers[sectionId].hasOwnProperty(question.id)
      ? answers[sectionId][question.id]
      : {};

  // this gets all the answers of the section, it is needed to spread it inside the objecto of answers
  const currentSectionAnswers = answers.hasOwnProperty(sectionId)
    ? answers[sectionId]
    : {};

  const inputEraser = () => {
    if (sessionStorage.activeInputs) {
      const actualActiveInputs = JSON.parse(sessionStorage.activeInputs);
      const index = actualActiveInputs.indexOf(question.id);
      if (index > -1) {
        actualActiveInputs.splice(index, 1);
      }
      sessionStorage.setItem(
        "activeInputs",
        JSON.stringify(actualActiveInputs)
      );
    }
  };

  useEffect(() => {
    setActiveInputs(question.id);
    // here we set the question Id of each question
    // to our activeInputsArray
    // When any question is unmounted
    // we will clean up its id from our active inputs array
    return () => {
      inputEraser();
    };
  }, []);

  return (
    <div className="question-card">
      <Text className="question-format-text">
        <i
          className="fas fa-chevron-circle-right primary"
          style={{ marginRight: 10 }}
        ></i>
        {question.question}
      </Text>
      {question.info && (
        <Text size="small" className="margin-v question-format-info">
          * {question.info}
        </Text>
      )}
      {question?.answer &&
        question?.answer.map((e: any) => {
          return (
            <Text size="small" className="margin-v question-format-info">
              * {e}
            </Text>
          );
        })}
      {question.type === "question" && (
        <InfoNotReadyWrapper
          question={question}
          onChange={setAnswers}
          currentAnswer={currentAnswer}
          sectionId={sectionId}
          currentSectionAnswers={currentSectionAnswers}
        >
          <Question
            question={question}
            onChange={setAnswers}
            currentAnswer={currentAnswer}
            sectionId={sectionId}
            currentSectionAnswers={currentSectionAnswers}
          />
        </InfoNotReadyWrapper>
      )}
      {question.type === "number" && (
        <Number
          question={question}
          onChange={setAnswers}
          currentAnswer={currentAnswer}
          sectionId={sectionId}
          currentSectionAnswers={currentSectionAnswers}
        />
      )}
      {question.type === "numberOf" && (
        <NumberOf
          question={question}
          onChange={setAnswers}
          currentAnswer={currentAnswer}
          sectionId={sectionId}
          currentSectionAnswers={currentSectionAnswers}
        />
      )}
      {question.type === "money" && (
        <Money
          question={question}
          onChange={setAnswers}
          currentAnswer={currentAnswer}
          sectionId={sectionId}
          currentSectionAnswers={currentSectionAnswers}
          currentAnswerObject={currentAnswerObject}
        />
      )}
      {question.type === "documentName" && <DocumentName />}
      {question.type === "moneyAndDates" && (
        <MoneyAndDates
          question={question}
          onChange={setAnswers}
          currentAnswer={currentAnswer}
          sectionId={sectionId}
          currentSectionAnswers={currentSectionAnswers}
          currentAnswerObject={currentAnswerObject}
        />
      )}
      {question.type === "moneyAndDatesWithIVA" && (
        <MoneyAndDatesWithIVA
          question={question}
          onChange={setAnswers}
          currentAnswer={currentAnswer}
          sectionId={sectionId}
          currentSectionAnswers={currentSectionAnswers}
          currentAnswerObject={currentAnswerObject}
        />
      )}
      {question.type === "pesos" && (
        <Pesos
          question={question}
          onChange={setAnswers}
          currentAnswer={currentAnswer}
          sectionId={sectionId}
          currentSectionAnswers={currentSectionAnswers}
          currentAnswerObject={currentAnswerObject}
        />
      )}
      {question.type === "percentage" && (
        <Percentage
          question={question}
          onChange={setAnswers}
          currentAnswer={currentAnswer}
          sectionId={sectionId}
          currentSectionAnswers={currentSectionAnswers}
          currentAnswerObject={currentAnswerObject}
        />
      )}
      {question.type === "textarea" && (
        <TextArea
          question={question}
          onChange={setAnswers}
          currentAnswer={currentAnswer}
          sectionId={sectionId}
          currentSectionAnswers={currentSectionAnswers}
        />
      )}
      {question.type === "options" && (
        <Options
          question={question}
          onChange={setAnswers}
          currentAnswer={currentAnswer}
          sectionId={sectionId}
          currentSectionAnswers={currentSectionAnswers}
        />
      )}
      {question.type === "date" && (
        <InfoNotReadyWrapper
          question={question}
          onChange={setAnswers}
          currentAnswer={currentAnswer}
          sectionId={sectionId}
          currentSectionAnswers={currentSectionAnswers}
        >
          <Date
            question={question}
            onChange={setAnswers}
            currentAnswer={currentAnswer}
            sectionId={sectionId}
            currentSectionAnswers={currentSectionAnswers}
          />
        </InfoNotReadyWrapper>
      )}
      {question.type === "dateWithoutSpace" && (
        <DateWithoutSpace
          question={question}
          onChange={setAnswers}
          currentAnswer={currentAnswer}
          sectionId={sectionId}
          currentSectionAnswers={currentSectionAnswers}
        />
      )}
      {question.type === "dateCalculator" && (
        <DateCalculator
          question={question}
          onChange={setAnswers}
          currentAnswer={currentAnswer}
          sectionId={sectionId}
          currentSectionAnswers={currentSectionAnswers}
        />
      )}
      {question.type === "multipleOptions" && (
        <MultipleOptions
          question={question}
          onChange={setAnswers}
          currentAnswer={currentAnswer}
          sectionId={sectionId}
          currentSectionAnswers={currentSectionAnswers}
        />
      )}
      {question.type === "advancedOptions" && (
        <AdvancedOptions
          question={question}
          onChange={setAnswers}
          currentAnswer={currentAnswer}
          sectionId={sectionId}
          currentSectionAnswers={currentSectionAnswers}
        />
      )}
      {question.type === "searchServerOptions" && (
        <SearchServerOptions
          question={question}
          onChange={setAnswers}
          currentAnswer={currentAnswer}
          sectionId={sectionId}
          currentSectionAnswers={currentSectionAnswers}
        />
      )}
      {question.type === "catastro" && (
        <Catastro
          question={question}
          onChange={setAnswers}
          currentAnswer={currentAnswer}
          sectionId={sectionId}
          currentSectionAnswers={currentSectionAnswers}
        />
      )}

      {currentAnswer &&
        question.type !== "multipleOptions" &&
        question.type !== "textarea" &&
        question.type !== "catastro" &&
        question.type !== "options" &&
        question.type !== "searchServerOptions" &&
        question.type !== "question" &&
        question.type !== "moneyAndDatesWithIVA" &&
        question.type !== "dateCalculator" &&
        question.type !== "advancedOptions" &&
        question.type !== "moneyAndDates" && (
          <Text className="currentAnswer">
            <i
              className="far fa-check-circle currentAnswer-check primary"
              style={{ marginRight: 10 }}
            ></i>
            Respuesta Actual: {currentAnswer}
          </Text>
        )}
      {question.type === "multipleOptions" && (
        <Text
          blue
          size="small"
          className="margin-v question-format-info-bottom"
        >
          *Si ninguna de las respuestas aplica, NO responder
        </Text>
      )}

      {question.type === "searchServerOptions" && (
        <Text
          blue
          size="small"
          className="margin-v question-format-info-bottom"
        >
          *Puede escribir para buscar entre las opciones
        </Text>
      )}

      {/* <div className="question-divider-line" /> */}
    </div>
  );
};

const mapDispatchToProps = {
  setAnswers,
};

const mapStateToProps = (state: any) => {
  return {
    answers: state.answers,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionSelector);
