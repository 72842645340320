import { AlignmentType, Footer, Header, ImageRun, Paragraph } from "docx";
import { cadema_logo } from "../docImages/cadema";

export const styles_cadema = {
  companyId: ["12415"],
  companyName: "Cadema",
  font: "Calibri",
  titleSize: 40,
  textSize: 22,
  lineSpacing: 220,
  marginRight: 900,
  marginLeft: 900,
  beforeParagraph: 150,

  header: new Header({
    children: [
      new Paragraph({
        spacing: {
          before: 0,
          after: 550,
        },
        alignment: AlignmentType.CENTER,
        children: [
          new ImageRun({
            data: cadema_logo,
            transformation: {
              width: 350,
              height: 350,
            },
            floating: {
              horizontalPosition: {
                offset: 2000000,
              },
              verticalPosition: {
                offset: 3000000,
              },
            },
          }),
        ],
      }),
    ],
  }),

  footer: new Footer({
    children: [],
  }),
};
