import { useAuth0 } from "@auth0/auth0-react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { APIPostWithError } from "../../../../Services/authenticated";
import Modal from "../../../../uiComponents/Modal/Modal";
import {
  closeModal,
  setAppError,
  setLoading,
} from "../../../../Utils/modalMethods";
import SignatureRow from "./SignatureRow/SignatureRow";
import "./styles.css";

type Props = {
  signature: any;
};

const SignatureStatusModal: React.FC<Props> = ({ signature }) => {
  const [currentDetails, setCurrentDetails] = useState<any>(null);

  const { getAccessTokenSilently } = useAuth0();

  const getDetails = async () => {
    if (signature.documentToken) {
      try {
        setLoading(true);
        const accessToken = await getAccessTokenSilently();
        const data = await APIPostWithError(
          "/electronic-signature/get-signature-details",
          accessToken,
          { token: signature.documentToken }
        );

        if (data?.details) {
          setCurrentDetails({
            ...data.details,
            userId: signature.userId,
            createdBy: signature.createdBy,
            creatorPhotoUrl: signature.creatorPhotoUrl,
            dateCreated: signature.dateCreated,
          });
        } else {
          closeModal();
          setLoading(false);
          setAppError();
        }
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signature]);

  return (
    <>
      {currentDetails && (
        <Modal>
          <div className="signature-status-modal">
            <div
              className="signature-status-modal-close"
              onClick={() => closeModal()}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <SignatureRow data={currentDetails} reload={false} isModal />
          </div>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    signature: state.modal.signatureStatusModal.signature,
  };
};

export default connect(mapStateToProps, null)(SignatureStatusModal);
