import React from "react";
import Image from "../Image/Image";
import "./styles.css";

type SelectorBoxProps = {
  title: string;
  description?: string;
  image: any;
  onClick: any;
  className?: string;
  style?: any;
  backgroundColor?: string;
  selected: string;
};

const SelectorBox: React.FC<SelectorBoxProps> = ({
  title,
  description,
  image,
  onClick,
  className,
  style,
  backgroundColor,
  selected,
}) => {
  return (
    <div
      style={style}
      className={`selector-box white selector-box-${selected} ${className} `}
      onClick={onClick}
    >
      {image && (
        <div className="flex-center">
          <Image name={image} className="selector-box-image" />
        </div>
      )}
      <div className="flex-center-v">
        <div className="selector-box-title">{title}</div>
        <div className="selector-box-desc">
          {description && <div>{description}</div>}
        </div>
      </div>
    </div>
  );
};

export default SelectorBox;
