import React, { CSSProperties } from "react";
import "./text.css";

type TextProps = {
  children?: React.ReactNode;
  size?: "small" | "medium" | "large";
  style?: CSSProperties;
  customColor?: string;
  align?: "left" | "center" | "right";
  white?: boolean;
  golden?: boolean;
  grey?: boolean;
  red?: boolean;
  green?: boolean;
  blue?: boolean;
  className?: string;
  roboto?: boolean;
};

const getColor = (
  golden?: boolean,
  white?: boolean,
  grey?: boolean,
  red?: boolean,
  green?: boolean,
  blue?: boolean,
  customColor?: string
) => {
  if (golden) return "#ddc080";
  if (white) return "#ffffff";
  if (grey) return "#707070";
  if (red) return "#ff4848";
  if (green) return "#71d17a";
  if (blue) return "#155ec5";

  return customColor;
};

/**
 * Primary UI component for user interaction
 */
const Text: React.FC<TextProps> = ({
  children,
  size = "medium",
  style,
  customColor,
  align = "left",
  white,
  golden,
  grey,
  red,
  green,
  blue,
  className = "",
  roboto,
  ...rest
}) => {
  const color = getColor(golden, white, grey, red, green, blue, customColor);

  return (
    <p
      style={{ color, ...style }}
      className={`default-text text-size-${size} text-align-${align} ${className} roboto-${roboto}`}
      {...rest}
    >
      {children}
    </p>
  );
};

Text.defaultProps = {
  size: "medium",
  children: "Please, input some text as content",
  align: "left",
};

export default Text;
