import React, { useEffect, useState } from "react";

import DocumentsManager from "./DocumentsManager/DocumentsManager";
import { Route, useLocation } from "react-router-dom";
import DashboarSectionTitle from "./DashboardSectionTitle/DashboardSectionTitle";
import DashboardSummary from "./DashboardSummary/DashboardSummary";
import DashboardSelector from "../DocumentSelector/DashboardSelector/DashboardSelector";
import Profile from "../../Authentication/Profile/Profile";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { setLoading } from "../../Utils/modalMethods";
import NoDocumentsCreated from "./DocumentsManager/NoDocumentsCreated/NoDocumentsCreated";
import ElectronicSignature from "./ElectronicSignature/ElectronicSignature";
import Alarms from "../Alarms/Alarms.screen";
import { useDispatch, useSelector } from "react-redux";
import { setDocuments, setFolders } from "../../redux/slices/masterData";
import DocxEditorMain from "../../components/DocxEditor/DocxEditorMain";
import { getMasterDataAndUser } from "../../Utils/getMasterData";
import DashboardEditSelector from "../DocumentSelector/DashboardEditSelector/DashboarEditSelector";
import { AlarmsModal } from "../../uiComponents/AlarmsModal/AlarmsModal";
import Tutorials from "./Tutorials/Tutorials";
import UsersManagement from "./UsersManagement/UsersManagement";
import { ProfilePicAlertModal } from "../../uiComponents/ProfilePic/ProfilePicAlertModal";
import UsersManagementDeIn from "./UsersManagement/UsersManagementDeIn";
import { APIGetWithError } from "../../Services/authenticated";

import "./styles.css";
import { LeftBar } from "./LeftBar/LeftBar";
import { ESignersDataLinks } from "../ESignersDataLinks/ESignersDataLinks";

const Dashboard = () => {
  const [userData, setuserData] = useState(null);
  const { documents, companyDocuments, spaceUsage, company } = useSelector(
    (state) => state.masterData
  );

  const reduxUser = useSelector((state) => state.masterData.user);
  const [hasDeInAccess, setHasDeInAccess] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [initialFolders, setInitialFolders] = useState(null);
  const [initialDocuments, setInitialDocuments] = useState(null);
  const [searchIsActive, setSearchIsActive] = useState(false);
  const [isProfilePic, setIsProfilePic] = useState(false);
  const history = useHistory();
  const { user, getAccessTokenSilently, logout } = useAuth0();

  const [notifyAlarms, setNotifyAlarms] = useState(false);

  const dispatch = useDispatch();

  const location = useLocation();

  const searchDocuments = (searchTerm) => {
    setSearchIsActive(true);
    const prevDocs = initialDocuments;
    if (searchTerm.trim() === "" && initialDocuments) {
      dispatch(setDocuments(initialDocuments));
      return;
    }

    const docFilterFunction = (term, doc) => {
      if (doc.fileName.toLowerCase().includes(term.toLowerCase())) {
        return true;
      }
      if (doc.documentType.toLowerCase().includes(term.toLowerCase())) {
        return true;
      }
      if (doc.createdBy.toLowerCase().includes(term.toLowerCase())) {
        return true;
      }
      return false;
    };
    if (prevDocs) {
      const newDocs = prevDocs.filter((doc) => {
        return docFilterFunction(searchTerm, doc);
      });
      dispatch(setDocuments([...newDocs]));
    }
  };

  const searchFolders = (searchTerm) => {
    setSearchIsActive(true);
    const prevFolders = initialFolders;
    if (searchTerm.trim() === "" && initialDocuments) {
      dispatch(setFolders(prevFolders));
      return;
    }

    const folderFilterFunction = (term, folder) => {
      if (folder.folderName.toLowerCase().includes(term.toLowerCase())) {
        return true;
      }
      return false;
    };
    if (prevFolders) {
      const newFolders = prevFolders.filter((folder) => {
        return folderFilterFunction(searchTerm, folder);
      });
      dispatch(setFolders([...newFolders]));
    }
  };

  const sortDocuments = (
    sortKey,
    ascending = true,
    sortType = "alphabetical"
  ) => {
    const prevDocs = initialDocuments;
    let sort;
    if (!ascending && sortType === "alphabetical") {
      sort = prevDocs.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    } else if (ascending && sortType === "alphabetical") {
      sort = prevDocs.sort((a, b) => -1 * a[sortKey].localeCompare(b[sortKey]));
    } else if (ascending && sortType === "numeric") {
      sort = prevDocs.sort((a, b) => b[sortKey] - a[sortKey]);
    } else if (!ascending && sortType === "numeric") {
      sort = prevDocs.sort((a, b) => -1 * b[sortKey] - a[sortKey]);
    } else if (ascending && sortType === "numeric string") {
      sort = prevDocs.sort(
        (a, b) => parseInt(b[sortKey]) - parseInt(a[sortKey])
      );
    } else if (!ascending && sortType === "numeric string") {
      sort = prevDocs.sort(
        (a, b) => -1 * parseInt(b[sortKey]) - parseInt(a[sortKey])
      );
    }
    dispatch(setDocuments([...sort]));
  };

  const loadMasterData = async () => {
    setLoading(true);
    try {
      const accesToken = await getAccessTokenSilently();

      const masterDataResult = await getMasterDataAndUser(
        accesToken,
        setInitialDocuments,
        setInitialFolders,
        user,
        history,
        logout,
        reduxUser
      );

      if (masterDataResult === "config") {
        history.push("/support-config");
        return;
      }

      if (!masterDataResult) {
        history.push("/");
        return;
      }

      const checkAdminAccess = async () => {
        const accessToken = await getAccessTokenSilently();
        const response = await APIGetWithError(
          "/users/restrict-user-management",
          accessToken
        );

        if (response) {
          setIsAdmin(response.access);
        }
      };

      const checkDeInAccess = async () => {
        const accessToken = await getAccessTokenSilently();
        const response = await APIGetWithError(
          "/users/validate-deinmo-access",
          accessToken
        );

        if (response) {
          setHasDeInAccess(response.hasAccess);
        }
      };

      checkAdminAccess();
      checkDeInAccess();
    } finally {
      setLoading(false);
    }
  };

  const checkAndSetNotifyAlarms = () => {
    const today = new Date().toLocaleDateString();
    const lastVisit = localStorage.getItem("lastVisit");

    if (!lastVisit || lastVisit !== today) {
      localStorage.setItem("lastVisit", today);
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (user) {
      const meta = user["https://andesdocs.com/meta2"].metadata;
      setuserData(meta);
      loadMasterData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (reduxUser) {
      setIsUserLoaded(true);
      if (
        reduxUser.photoUrl !== undefined &&
        reduxUser.photoUrl !== "<empty>" &&
        reduxUser.photoUrl !== ""
      ) {
        setIsProfilePic(false);
      } else {
        setIsProfilePic(true);
      }
    } else {
      setIsProfilePic(true);
    }
  }, [reduxUser]);

  useEffect(() => {
    const notify = checkAndSetNotifyAlarms();
    setNotifyAlarms(notify);
  }, []);

  useEffect(() => {}, [user, getAccessTokenSilently]);

  const handleCloseAlarmsModal = () => {
    setNotifyAlarms(false);
  };

  const handleCloseAlertPicModal = () => {
    setIsProfilePic(false);
  };

  return (
    <div className="dashboard-max-window-size b-tertiary">
      {isUserLoaded && isProfilePic ? (
        <ProfilePicAlertModal
          open={isProfilePic}
          onClose={handleCloseAlertPicModal}
        />
      ) : (
        notifyAlarms &&
        documents &&
        documents.length > 0 && (
          <AlarmsModal open={notifyAlarms} onClose={handleCloseAlarmsModal} />
        )
      )}
      <div className="dashboard-no-show-mobile b-tertiary">
        {userData && company && (
          <div className="dashboard-wrapper">
            <LeftBar isAdmin={isAdmin} />
            <div
              className={`dashboard-wrapper-box b-tertiary ${
                location.pathname.includes("docx-editor")
                  ? "dashboard-wrapper-box-docx-editor"
                  : "null"
              }`}
            >
              <Route path="/dashboard/finished">
                <DashboarSectionTitle title="Documentos Terminados" />
                {documents && (documents.length > 0 || searchIsActive) && (
                  <DocumentsManager
                    documents={documents}
                    searchDocuments={searchDocuments}
                    initialDocuments={initialDocuments}
                    searchFolders={searchFolders}
                    sortDocuments={sortDocuments}
                    spaceUsage={spaceUsage}
                    isAndesDocker={userData?.andes_docker ? true : false}
                  />
                )}
                {documents && documents.length < 1 && !searchIsActive && (
                  <NoDocumentsCreated />
                )}
              </Route>
              <Route path="/dashboard/documents">
                <DashboarSectionTitle title="Generar nuevo documento" />
                <DashboardSelector companyDocuments={companyDocuments} />
              </Route>

              <Route path="/dashboard/editor">
                <DashboarSectionTitle title="Editar generador de documento" />
                <DashboardEditSelector companyDocuments={companyDocuments} />
              </Route>

              <Route path="/dashboard/esignature">
                <DashboarSectionTitle title="Firma Electrónica" />
                <ElectronicSignature
                  documents={documents}
                  searchDocuments={searchDocuments}
                  company={company}
                />
              </Route>
              <Route path="/dashboard/alarms">
                <Alarms />
              </Route>
              <Route path="/dashboard/profile">
                <DashboarSectionTitle title="Mi Perfil" />
                <DashboardSummary
                  company={company}
                  user={user}
                  companyDocumentsLength={companyDocuments?.length}
                  documentsLength={documents?.length}
                  spaceUsage={spaceUsage}
                />
                <div className="dashboard-profile-warpper">
                  <Profile />
                </div>
              </Route>
              <Route path="/dashboard/docx-editor">
                <DocxEditorMain />
              </Route>
              <Route path="/dashboard/tutorials">
                <DashboarSectionTitle title="Tutoriales" />
                <Tutorials />
              </Route>

              <Route path="/dashboard/EsignersDataLinks">
                <ESignersDataLinks />
              </Route>

              <Route path="/dashboard/usersManagement">
                {hasDeInAccess ? <UsersManagementDeIn /> : <UsersManagement />}
              </Route>
            </div>
          </div>
        )}
      </div>
      <div className="dashboard-show-mobile">
        <h1 style={{ marginTop: 40 }}>Por favor, agrande el Navegador</h1>
        <p style={{ marginTop: 50, padding: 50 }}>
          Para visualizar el Dashboard abra Andes Docs en pantalla completa en
          una computadora. Si aún no ve el dashboard establezca el zoom del
          navegador en 100% o contactese con soporte.
        </p>
      </div>
    </div>
  );
};

export default Dashboard;
