import { useState, useEffect } from "react";
import { Box, Avatar, IconButton } from "@mui/material";
import { colorPallette } from "../../Common/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import AvatarEditorModal from "./AvatarEditorModal";
import { useAtom } from "jotai";
import { profilePicModal } from "../../jotai/atoms.jotai";

interface ProfilePicProps {
  photoUrl: string | null;
  editable: boolean;
  size: number;
}

const ProfilePic: React.FC<ProfilePicProps> = ({
  photoUrl,
  editable,
  size,
}) => {
  const [avatarUrl, setAvatarUrl] = useState<string | null>(() => {
    return localStorage.getItem("avatarUrl") || null;
  });
  const [modalOpen, setModalOpen] = useAtom(profilePicModal);

  useEffect(() => {
    if (photoUrl) {
      const timestamp = new Date().getTime();
      const cacheBustedUrl = `${photoUrl}?${timestamp}`;
      setAvatarUrl(cacheBustedUrl);
      localStorage.setItem("avatarUrl", cacheBustedUrl);
    }
  }, [photoUrl]);

  const updateAvatar = (imgSrc: string) => {
    setAvatarUrl(imgSrc);
    localStorage.setItem("avatarUrl", imgSrc);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box position="relative">
        <Avatar
          src={avatarUrl || ""}
          alt="Avatar"
          sx={{
            border: `1px solid ${colorPallette.primary}`,
            width: size,
            height: size,
          }}
        />
        {editable && (
          <IconButton
            sx={{
              position: "absolute",
              color: colorPallette.white,

              minWidth: "30px",
              minHeight: "30px",
              bottom: -12,
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: colorPallette.primary,
              "&:hover": {
                backgroundColor: colorPallette.dark,
              },
            }}
            onClick={() => setModalOpen(true)}
          >
            <FontAwesomeIcon icon={faPencil} fontSize={15} />
          </IconButton>
        )}
      </Box>
      {modalOpen && (
        <AvatarEditorModal
          updateAvatar={updateAvatar}
          closeModal={() => setModalOpen(false)}
        />
      )}
    </Box>
  );
};

export default ProfilePic;
