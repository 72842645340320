import React, { useEffect, useState } from "react";
import DocumentCreator from "../DocumentCreator/DocumentCreator";

interface DocumentQuestionData {
  name: string;
  codeName: string;
  url: string;
  sections: { section: string }[];
}

const CompanyDocumentCreator = () => {
  const [documentQuestionData, setDocumentQuestionData] =
    useState<DocumentQuestionData | null>(null);
  const [showDocumentCreator, setShowDocumentCreator] = useState(false);

  useEffect(() => {
    const docData = sessionStorage.getItem("currentCompanyQuestionData");
    if (docData) {
      try {
        const parsed: DocumentQuestionData = JSON.parse(docData);
        setDocumentQuestionData(parsed);
        setShowDocumentCreator(true);
      } catch (error) {
        console.error("Error parsing document data: ", error);
      }
    }
  }, []);

  return (
    <div>
      {showDocumentCreator && documentQuestionData && (
        <DocumentCreator data={documentQuestionData} />
      )}
    </div>
  );
};

export default CompanyDocumentCreator;
