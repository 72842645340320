import React from "react";
import Modal from "../../uiComponents/Modal/Modal";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import IconButton from "../../uiComponents/IconButton/IconButton";
import { APIPostWithError } from "../../Services/authenticated";
import { useAuth0 } from "@auth0/auth0-react";
import {
  modalOpen,
  closeModal,
  setAppError,
  setLoading,
} from "../../Utils/modalMethods";
import { editReduxDocumentAlarm } from "../../Utils/documentMethods";

import "./styles.css";

type ExpirationModalProps = { document: any; shouldShowSuccess: any };

const ExpirationModal: React.FC<ExpirationModalProps> = ({
  document,
  shouldShowSuccess,
}) => {
  const { handleSubmit, register } = useForm();

  const { getAccessTokenSilently } = useAuth0();

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const date = new Date(data.expirationDate);
      const dateToSave = date.valueOf() + 14400000;
      const accesToken = await getAccessTokenSilently();
      const res = await APIPostWithError("/doc-ref/edit-document", accesToken, {
        ...document,
        expirationDate: dateToSave,
        isExpirationHandled: false,
      });
      if (res.documentSaved === true) {
        editReduxDocumentAlarm(document, String(dateToSave));
        if (shouldShowSuccess) {
          setTimeout(() => {
            modalOpen({
              successModal: {
                show: true,
                message: "Alarma creada exitósamente",
                submessage:
                  "Podés ver tus proximos vencimientos en la seccion ALARMAS",
                disclaimer: "",
                shouldReload: false,
              },
            });
          }, 1000);
        }
        closeModal();
      } else {
        setTimeout(() => {
          setAppError();
        }, 1000);
        closeModal();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal>
      <div className="expiration-modal">
        <i
          className="far fa-window-close folder-modal-close"
          onClick={() => closeModal()}
        ></i>
        <p className="bold expiration-modal-title">
          Crear vencimieto / alarma
          <i className="fas fa-stopwatch icon-margin-l primary"></i>
        </p>
        <p className="expiration-modal-name primary">
          {document.fileName}
          {}
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label>Fecha de vencimiento:</label>
          <input
            {...register(`expirationDate`, { required: true })}
            maxLength={80}
            className="expiration-modal-input"
            type="date"
          />
          <IconButton
            label="Crear"
            icon="fas fa-bolt"
            className="expiration-modal-button"
            color="secondary"
            type={"submit"}
          />
        </form>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    document: state.modal.expirationModal.document,
    shouldShowSuccess: state.modal.expirationModal.shouldShowSuccess,
  };
};

export default connect(mapStateToProps, null)(ExpirationModal);
