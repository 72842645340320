import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import OptionCard from "./InputTypes/OptionCard/OptionCard";
import Button from "../../../../uiComponents/Button/Button";
import Image from "../../../../uiComponents/Image/Image";
import SignerDataForm from "./SignerDataForm";
import { setAppError } from "../../../../Utils/modalMethods";
import ConfirmESignature from "./ConfirmESignature";
import { Document } from "../../../../Types/Document.types";
import { Signer } from "../../../../Types/Signer.types";
import Select from "../../../../uiComponents/Select/Select";

import "./styles.css";

const signerOptions = Array.from({ length: 20 }, (_, i) => ({
  value: (i + 1).toString(),
  label: `${i + 1} Firmante${i > 0 ? "s" : ""}`,
}));

type ESignCardProps = {
  question: any;
  children: any;
};

const ESignCard: React.FC<ESignCardProps> = ({ question, children }) => {
  return (
    <div className="e-sign-form-card b-white">
      <p className="font-m bold margin-b">
        <FontAwesomeIcon
          icon={faChevronCircleRight}
          className="margin-icon primary"
        />
        {question}
      </p>
      {children}
    </div>
  );
};

type ElectronicSignatureFormProps = {
  document: Document;
  company: string;
};

const ElectronicSignatureForm: React.FC<ElectronicSignatureFormProps> = ({
  document,
  company,
}) => {
  const [currentSection, setCurrentSection] = useState(0);
  const [numberOfSigners, setNumberOfSigners] = useState(1);
  const [eSignersData, setEsignersData] = useState<Signer[] | null>(null);
  const [isSequential, setIsSequential] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentSection]);

  const goTonextSection = () => {
    if (numberOfSigners > 0) {
      setCurrentSection(1);
    } else {
      setAppError(
        "Seleccionar cantidad de firmantes",
        "Antes de continuar debe especificar cuantas personas van a firmar el documento."
      );
    }
  };
  const getDate = (formatdate: string) => {
    const date = new Date(parseInt(formatdate));

    return `${date.getDate()} /  ${
      date.getMonth() + 1
    } / ${date.getFullYear()}`;
  };

  return (
    <div>
      <div className="e-sign-form-wrapper center">
        <div className="width-100 e-sign-form-top-box">
          <div className="flex e-sign-form-title-box">
            <Image
              name={"signature-icon.png"}
              className=" e-sign-form-title-icon"
            />
            <p className="e-sign-form-title">
              {document.fileName}
              <span
                className="primary"
                style={{ fontSize: 15, fontWeight: 400, marginLeft: 12 }}
              >
                {" "}
                ({document.createdBy} - {getDate(document.dateCreated)})
              </span>
            </p>
          </div>
        </div>
        {currentSection === 0 && (
          <>
            <ESignCard question="¿Qué voy a necesitar?">
              <ul className="e-sign-form-reqs">
                <li>
                  <span>Nombre y apellido</span> de cada firmante
                </li>
                <li>
                  <span>Email</span> de cada firmante
                </li>
              </ul>
            </ESignCard>
            <ESignCard question="¿Cuantas personas van a firmar el documento?">
              <Select
                options={signerOptions}
                onChange={(selectedOption) =>
                  setNumberOfSigners(parseInt(selectedOption?.value || "0"))
                }
                placeholder="Seleccionar número de firmantes"
                defaultValue={signerOptions[0]}
              />
            </ESignCard>
            <ESignCard question="¿Desea que la firma sea sequencial? ">
              <ul className="e-sign-form-reqs">
                <li>
                  Solo podrá firmar el <span>siguiente firmante</span>, una vez
                  que el anterior hay terminado de firmar.
                </li>
              </ul>
              <OptionCard
                selected={isSequential}
                id={"Si"}
                handleClick={() => {
                  setIsSequential(true);
                }}
              />
              <OptionCard
                selected={!isSequential}
                id={"No"}
                handleClick={() => {
                  setIsSequential(false);
                }}
              />
            </ESignCard>

            <div className="width100 flex-space-between">
              <div></div>
              <Button
                label="Siguiente"
                className="flex-end"
                onClick={goTonextSection}
              />
            </div>
          </>
        )}
        {currentSection === 1 && (
          <SignerDataForm
            numberOfSigners={numberOfSigners}
            setCurrentSection={setCurrentSection}
            setEsignersData={setEsignersData}
          />
        )}
        {currentSection === 2 && (
          <ConfirmESignature
            eSignersData={eSignersData}
            document={document}
            isSequential={isSequential}
          />
        )}
      </div>
    </div>
  );
};

export default ElectronicSignatureForm;
