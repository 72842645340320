import store from "../redux/store";
import * as documentMethods from "./documentMethods";
import { closeModal, setAppError, setLoading } from "./modalMethods";
import { downloadWordS3 } from "./S3Download";

export const downloadDocumentFromS3 = async (
  document: string,
  accessToken: any
) => {
  try {
    setLoading(true);
    await downloadWordS3(document, accessToken);
  } catch (error) {
    setAppError();
  } finally {
    setLoading(false);
  }
};

export const deleteDocumentAndReference = async (
  document: any,
  accessToken: any
) => {
  closeModal();
  const newDocument = { ...document, expirationDate: null };
  await documentMethods.deleteDocument(newDocument, accessToken);
};

export const deleteDocumentAlarm = async (document: any, accessToken: any) => {
  closeModal();
  const newDocument = { ...document, expirationDate: null };
  await documentMethods.editDocument(
    newDocument,
    accessToken,
    documentMethods.deleteReduxDocumentAlarm
  );
};

export const deleteSignature = async (signature: any, accessToken: any) => {
  closeModal();
  await documentMethods.deleteDBSignature(signature, accessToken);
};

export const markDocumentAlarmAsHandled = async (
  document: any,
  accessToken: any
) => {
  closeModal();
  const newDocument = { ...document, isExpirationHandled: true };
  await documentMethods.editDocument(
    newDocument,
    accessToken,
    documentMethods.markReduxDocumentAlarmAsHandled
  );
};

export const toggleDocumentStatus = async (
  document: any,
  accessToken: any,
  approvedBy: string,
  status: string
) => {
  const newDocument = { ...document, status, approvedBy };
  return await documentMethods.editDocument(
    newDocument,
    accessToken,
    documentMethods.editReduxDocument
  );
};

export const filterAlarmDocuments = (documents: any, range = 7) => {
  const today = Date.now();

  return documents.filter((doc: any) => {
    let expDate = doc.expirationDate;
    if (typeof expDate === "string") {
      expDate = parseInt(expDate);
    }
    const daysToExpiration = Math.floor(
      (expDate - today) / (1000 * 60 * 60 * 24)
    ); // Convert to days

    return daysToExpiration <= range && daysToExpiration >= -range;
  });
};

export const findUserPhotoUrl = (userId: string) => {
  const { allCompanyUsers } = store.getState().masterData;
  const user = allCompanyUsers.find((user) => user.userId === userId);
  return user ? user.photoUrl : "Sin foto";
};

export const findUserNameById = (userId: string) => {
  const { allCompanyUsers } = store.getState().masterData;
  const user = allCompanyUsers.find((user) => user.userId === userId);
  return user ? user.name : "Usuario Removido";
};
