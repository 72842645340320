import React from "react";
import { connect } from "react-redux";

import "./styles.css";

type DocumentSectionProps = {
  name: string;
  number: number;
  currentSection: number;
  onClick: () => void;
  answers: any;
  checkSection?: boolean;
};

const DocumentSection: React.FC<DocumentSectionProps> = ({
  name,
  number,
  currentSection,
  onClick,
  answers,
  checkSection,
}) => {
  const selected =
    currentSection === number ? "document-section-selected-div" : null;
  const checkSectionIsSelected = checkSection
    ? "document-section-selected-div"
    : null;

  const isClickable = currentSection > number;
  return (
    <div
      className={`document-section-text-div ${selected} ${checkSectionIsSelected} document-section-hoverer-${isClickable}`}
      onClick={onClick}
    >
      <div className="document-section-text">
        {number > 0 && `${number}.`} {name}
      </div>
      <div>
        {isClickable && (
          <i className="far fa-check-circle document-section-check primary"></i>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    answers: state.answers,
  };
};

export default connect(mapStateToProps)(DocumentSection);
