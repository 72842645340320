import React from "react";
import Button from "../../../uiComponents/Button/Button";
import Image from "../../../uiComponents/Image/Image";
import "./styles.css";

const SubscribeScreen = () => {
  return (
    <div className="subscribe-screen-wrapper">
      <h1 className="subscribe-screen-title">¡Suscribite hoy!</h1>
      <p className="subscribe-screen-text">
        Contactate con nuestros representantes para que te guien en el proceso
        de suscripción
      </p>
      <p className="subscribe-screen-text">
        ¡Bienvenido a la comunidad Andes Docs!
      </p>
      <Button
        whatsapp
        label="Comunicate por whatsapp!"
        size="large"
        center
        onClick={() => {
          window.open(
            "https://api.whatsapp.com/send?phone=5491131073107&text=Hola,%20te%20contacto%20por%20Andes%20Docs!"
          );
        }}
      />
      <p className="grey f-small margin-v">
        *serás guiado en el proceso de pago y suscripción
      </p>
      <Image name="people.png" className="subscribe-screen-people" />
    </div>
  );
};

export default SubscribeScreen;
