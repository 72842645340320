import {
  AlignmentType,
  Footer,
  Header,
  ImageRun,
  PageNumber,
  Paragraph,
  TextRun,
} from "docx";
import { okeefeLogo } from "../docImages/okeefe";

export const styles_okeefe = {
  companyId: ["12400"],
  companyName: "Okeefe",
  font: "Verdana",
  titleSize: 16,
  textSize: 16,
  lineSpacing: 280,
  marginRight: 1600,
  marginLeft: 1600,
  beforeParagraph: 200,

  header: new Header({
    children: [
      new Paragraph({
        spacing: {
          before: 0,
          after: 0,
        },
        alignment: AlignmentType.CENTER,
        children: [
          new ImageRun({
            data: okeefeLogo,
            transformation: {
              width: 300,
              height: 110,
            },
          }),
        ],
      }),
    ],
  }),

  footer: new Footer({
    children: [
      new Paragraph({
        spacing: {
          before: 300,
          after: 0,
        },
        alignment: AlignmentType.CENTER,
        children: [],
      }),
      new Paragraph({
        alignment: AlignmentType.RIGHT,
        children: [
          new TextRun({
            children: [PageNumber.CURRENT],
          }),
        ],
      }),
    ],
  }),
};
