import React, { useMemo } from "react";
import { MRT_ColumnDef } from "material-react-table";
import TableEntryName from "../../../uiComponents/AndesTable/TableEntries/Name.component";
import TableDate from "../../../uiComponents/AndesTable/TableEntries/TableDate.component";
import TableActions, {
  TableAction,
} from "../../../uiComponents/AndesTable/TableEntries/TableActions.components";

import AndesTable, {
  renderHeader,
} from "../../../uiComponents/AndesTable/AndesTable.component";
import {
  faTrashAlt,
  faDownload,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import SignatureStatusEntry from "../TableEntries/SignatureStatusEntry";
import { modalOpen, setAppError } from "../../../Utils/modalMethods";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  findUserNameById,
  findUserPhotoUrl,
} from "../../../Utils/commonTableActions";
import { ProfilePicAndName } from "../../../uiComponents/ProfilePicAndName/ProfilePicAndName";
import { useAuth0 } from "@auth0/auth0-react";

type Props = {
  documents: any[];
};

const AllSignaturesTable: React.FC<Props> = ({ documents }) => {
  const alarmTableActions: TableAction[] = [
    {
      name: "Descargar Firmado",
      icon: faDownload,
      // onClickAsync: (data: any, accessToken: any) =>
      //   downloadDocumentFromS3(data, accessToken),
      onClickWithParams: (data: any) => {
        if (!data || !data.signedFile) {
          setAppError(
            "El documento aun no ha sido firmado",
            "Debe esperar a que por lo menos 1 firmante firme el documento"
          );
        } else {
          window.open(data.signedFile);
        }
      },
    },
    // {
    //   name: "Ver Detalle",
    //   icon: faPlus,
    //   onClickWithParams: (data: any) =>
    //     modalOpen({
    //       signatureStatusModal: {
    //         show: true,
    //         signature: data,
    //       },
    //     }),
    // },
    {
      name: "Eliminar",
      icon: faTrashAlt,
      onClickWithParams: (data: any) =>
        modalOpen({
          confirmModal: {
            show: true,
            message: "Confirme eliminación",
            submessage: "¿Seguro desea eliminar la firma seleccionada?",
            disclaimer:
              "*No se podrá deshacer, asegursese que no necesitará la firma en el futuro.",
            document: data,
            isAsync: true,
            confirmMethodName: "deleteSignature",
            fileName: data.fileName,
          },
        }),
      color: "light-red",
    },
  ];

  const columns = useMemo<MRT_ColumnDef<any>[]>(() => {
    return [
      {
        accessorKey: "fileName",
        header: "Nombre",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        Cell: ({ cell }: any) => {
          return (
            <TableEntryName name={cell.getValue()} image={"signature.png"} />
          );
        },
        maxSize: 400,
        minSize: 310,
      },
      {
        accessorKey: "status",
        header: "Estado",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 170,
        maxSize: 170,
        Cell: ({ cell }: any) => {
          return <SignatureStatusEntry status={cell.getValue()} />;
        },
      },

      {
        accessorKey: "dateCreated",
        header: "Creación",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 160,
        maxSize: 160,
        Cell: ({ cell }: any) => {
          return <TableDate date={cell.getValue()} fallback="Sin definir" />;
        },
      },
      {
        accessorKey: "userId",
        header: "Creado por",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 180,
        Cell: ({ cell }: any) => {
          const userName = findUserNameById(cell.getValue());
          const profileImage = findUserPhotoUrl(cell.getValue());
          return <ProfilePicAndName image={profileImage} name={userName} />;
        },
      },
      {
        header: "Acciones",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        enableColumnActions: false,
        minSize: 90,
        maxSize: 120,
        Cell: ({ cell }) => (
          <TableActions
            actions={alarmTableActions}
            rowData={cell.row.original}
          />
        ),
      },
      {
        header: "Firmantes",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        enableColumnActions: false,
        minSize: 240,
        Cell: ({ cell }) => (
          <div>
            <div
              className="btn-outline"
              onClick={() => {
                modalOpen({
                  signatureStatusModal: {
                    show: true,
                    signature: cell.row.original,
                  },
                });
              }}
            >
              <FontAwesomeIcon icon={faPlus} className="margin-icon" />
              Ver Detalle Firmantes
            </div>
          </div>
        ),
      },
    ];
  }, []);

  return (
    <AndesTable
      documents={documents}
      columns={columns}
      initialSort={[{ id: "dateCreated", desc: true }]}
    />
  );
};

export default AllSignaturesTable;
