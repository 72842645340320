import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../redux/store";
import { User } from "../../Types/User.types";

type BarElementProps = {
  element: {
    name: string;
    to: string;
    icon: string;
  };
  index: number;
  selected: number;
  setSelected: (index: number) => void;
};

export const BarElement: React.FC<BarElementProps> = ({
  element,
  index,
  selected,
  setSelected,
}) => {
  const { internalUser: isInternalUser, role: isAdminOrSuperAdmin } =
    useSelector((state: RootState) => state.masterData.user as User);

  const isSelected = index === selected;

  const history = useHistory();

  if (!isInternalUser && element.name === "Editor") {
    return null;
  }

  if (
    isAdminOrSuperAdmin === "user" &&
    element.name === "Control de Usuarios"
  ) {
    return null;
  }

  return (
    <div
      className={`left-bar-element left-bar-element-selected-${isSelected} ${
        isSelected && "b-primary"
      }`}
      onClick={() => {
        setSelected(index);
        history.push(element.to);
      }}
    >
      <div
        className={`left-bar-element-content left-bar-element-content-selected-${isSelected}`}
      >
        <div className="left-bar-element-icon-box">
          <i
            className={`${element.icon} ${isSelected ? "white" : "primary"}`}
          />
        </div>
      </div>
      <div className="left-bar-name">{element.name}</div>
    </div>
  );
};
