import { ThemeOptions, createTheme } from "@mui/material/styles";

export const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#a8bbfd",
      dark: "#5b55a0",
      light: "#e8eeff",
    },
    secondary: {
      main: "#e8eeff",
      light: "#f6f9fc",
    },
    error: {
      main: "#fcb4ae",
    },
    warning: {
      main: "#fcb4ae",
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "contained" && {
            color: "#fff",
            fontWeight: "400",
            textTransform: "initial",
            fontSize: "0.9rem",
            height: "54px",
          }),
          ...(ownerState.color === "secondary" && {
            color: "grey",
          }),
          ...(ownerState.variant === "text" && {
            fontSize: "1rem",
            // color: "#5b55a0",
          }),
          fontSize: "1rem",
          textTransform: "initial",
        }),
      },
      defaultProps: {
        disableElevation: true,

        // The props to change the default for.
      },
    },
  },
};

export const colorPallette = {
  primary: "#a8bbfd",
  secondary: "#e8eeff",
  tertiary: "#f6f9fc",
  lightRed: "#fcb4ae",
  greenHover: "#fcfcab",
  dark: "#5b55a0",
  white: "#fff",
  veryDark: "#141230",
  darkest: "#0A091A",
  green: "#50c950",
  yellow: "#fcfcab",
};

export const theme = createTheme(themeOptions);

export const andesPallette = {
  main: "#5b55a0",
  primary: "#a8bbfd",
  secondary: "#e8eeff",
  tertiary: "#f6f9fc",
};

export const gradients = {
  dark: "linear-gradient(120deg, rgba(10,9,26,1) 0%, rgba(24,22,57,1) 90%, rgba(24,22,57,0.98) 100%)",
  light:
    "linear-gradient(90deg, rgba(168,187,253,1) 0%, rgba(246,249,252,1) 100%)",
};

export const cardStyle = {
  dark: {
    backgroundColor: colorPallette.veryDark,
    border: "solid 1px",
    borderColor: colorPallette.dark,
    color: "white",
  },
};
