import React, { useEffect, useState } from "react";
import DocumentCard from "../DocumentCard/DocumentCard";
import { APIPostWithError } from "../../../Services/authenticated";
import { useAuth0 } from "@auth0/auth0-react";
import { setAppError, setLoading } from "../../../Utils/modalMethods";
import { useHistory } from "react-router-dom";
import { getAndSetSheetDataByCompanyId } from "../../../Utils/getSheetData";
import { Document } from "../../../Types/Document.types";

import "./styles.css";

type DashboardSelectorProps = {
  companyDocuments: Document[];
};

const DashboardSelector: React.FC<DashboardSelectorProps> = ({
  companyDocuments,
}) => {
  const [companyLogo, setCompanyLogo] = useState<string | null>(null);
  const { user, getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const [displayCompanyDocuments, setDisplayCompanyDocuments] = useState<
    Document[] | null
  >(null);

  useEffect(() => {
    window.sessionStorage.removeItem("custom_document_name");
    setLoading(true);
    if (localStorage.getItem("currentCompanyLogo")) {
      setCompanyLogo(localStorage.getItem("currentCompanyLogo"));
    }

    if (companyDocuments) {
      const sortedDocuments = [...companyDocuments].sort((a, b) =>
        a.documentName1.localeCompare(b.documentName1)
      );
      setDisplayCompanyDocuments(sortedDocuments);
    }

    setLoading(false);
  }, [companyDocuments, user]);

  const getDocumentData = async (
    dataPath: string,
    generatorVersion: string,
    sheetId: string
  ) => {
    setLoading(true);
    try {
      const accesToken = await getAccessTokenSilently();
      const doc = await APIPostWithError(
        "/company-docs/get-company-document",
        accesToken,
        { dataPath, generatorVersion }
      );
      if (generatorVersion && generatorVersion === "2.0") {
        if (doc) {
          localStorage.setItem(
            "generator-form-document",
            JSON.stringify(doc.document)
          );
          localStorage.setItem(
            "generator-form-data",
            JSON.stringify(doc.questions)
          );
          localStorage.setItem(
            "generator-form-sections",
            JSON.stringify(doc.sections)
          );
          localStorage.removeItem("generator-form-answers");
          if (sheetId) {
            const companyData = await getAndSetSheetDataByCompanyId(
              sheetId,
              accesToken
            );
            if (companyData)
              localStorage.setItem(
                "companyData",
                JSON.stringify({ ...companyData, type: "sheetsDataBase" })
              );
          }
          setLoading(false);
          history.push("/generator-form");
        }
      } else {
        if (doc) {
          sessionStorage.setItem("lastDataPathForSave", dataPath);
          sessionStorage.setItem(
            "currentCompanyQuestionData",
            JSON.stringify(doc.questions)
          );
          sessionStorage.setItem(
            "currentCompanyDocumentData",
            JSON.stringify(doc.document)
          );

          if (window.sessionStorage.getItem("expirationDate")) {
            window.sessionStorage.removeItem("expirationDate");
          }
          setLoading(false);
          history.push("/company-document-creator");
        }
        if (!doc) {
          setAppError();
          setLoading(false);
        }
      }
    } catch {
      setAppError();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {displayCompanyDocuments && (
        <div className="dashboard-select0r-wrapper">
          <p className="dashboard-message">
            <i className="fas fa-play secondary margin-r" /> Elegí el documento
            que quieras generar
          </p>
          <div className="dashboard-selector">
            <div className="dashboard-selector-grid">
              {displayCompanyDocuments &&
                displayCompanyDocuments.length > 0 &&
                displayCompanyDocuments.map((doc, index) => {
                  return (
                    <DocumentCard
                      key={index}
                      title1={doc.documentName1 || ""}
                      title2={doc.documentName2 || ""}
                      onClick={() => {
                        localStorage.setItem(
                          "document-update-data",
                          JSON.stringify(doc)
                        );
                        localStorage.setItem(
                          "removeLogo",
                          String(doc.removeLogo || false)
                        );
                        getDocumentData(
                          doc.dataPath,
                          doc.generatorVersion,
                          doc.sheetId
                        );
                      }}
                      index={index}
                      logo={companyLogo}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      )}
      {/* <p className="dashboard-selector-category" style={{ marginTop: 40 }}>
        <i className="fas fa-play secondary margin-r" />
        Documentos biblioteca Andes Docs:
      </p> */}
      {/* <div className="dashboard-selector">
        <div className="dashboard-selector-grid center">
          {documents.map((doc, index) => {
            return (
              <DocumentCard
                title1={doc.title1}
                title2={doc.title2}
                to={doc.to}
                price={price}
                index={index}
              />
            );
          })}
        </div>
      </div> */}
    </div>
  );
};

export default DashboardSelector;
