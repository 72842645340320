import {
  setCompany,
  setCompanyDocuments,
  setDocuments,
  setFolders,
  setSpaceUsage,
  setUser,
  setAllCompanyUsers,
} from "../redux/slices/masterData";
import store from "../redux/store";
import { APIGetWithError, APIPostWithError } from "../Services/authenticated";

export const getOrCreateUser = async (accessToken: any, userData: any) => {
  try {
    if (!accessToken) return null;

    const user = await APIPostWithError(
      "/users/get-or-create-user",
      accessToken,
      { userData }
    );

    if (!user || !user.user) {
      // aca van a caer los users que no existen o que ya tenian otro usuario con el mismo mail
      return null;
    }

    if (user.user.companyId === false) {
      return "config";
    }

    if (user && user.user.companyId) {
      store.dispatch(setUser(user.user));
    }

    if (user.user.photoUrl) {
      localStorage.setItem("currentUserPhoto", user.user.photoUrl);
    }

    if (user.user.name) {
      localStorage.setItem("currentUserName", user.user.name);
    }
  } catch {}
};

export const getMasterDataAndUser = async (
  accessToken: any,
  setInitialDocuments: (docs: any) => void,
  setInitialFolders: (docs: any) => void,
  userData: any,
  history: any,
  logout: () => void,
  hasUser?: boolean,
) => {
    if (!accessToken) return null;
    if (!hasUser) {
      const user = await APIPostWithError(
        "/users/get-or-create-user",
        accessToken,
        { userData }
      );
      if (!user || !user.user) {
       // aca van a caer los users que no existen o que ya tenian otro usuario con el mismo mail
       // El timeout es para que lean el mensaje.  
       setTimeout(()=>{
          logout();
        }, 5000)
        return null;
      }

      if (user.user.companyId === false) {
        return "config";
      }

      if (user && user.user.companyId) {
        store.dispatch(setUser(user.user));
      }

      if (user.user.photoUrl) {
        localStorage.setItem("currentUserPhoto", user.user.photoUrl);
      }

      if (user.user.name) {
        localStorage.setItem("currentUserName", user.user.name);
      }
    }
    const master = await APIGetWithError(
      "/master/get-master-data",
      accessToken
    );

    const {
      company,
      documentReferences,
      folders,
      companyCustomDocs,
      allCompanyUsers,
    } = master;

    if (
      !company ||
      !documentReferences ||
      !folders ||
      !companyCustomDocs ||
      !allCompanyUsers
    ) {
      return null;
    }

    //this section sets the company and logo
    store.dispatch(setCompany(company));

    if (allCompanyUsers && allCompanyUsers.length > 0) {
      store.dispatch(setAllCompanyUsers(allCompanyUsers));
    }
    if (company.logo) {
      const storedCompanyLogo = localStorage.getItem("currentCompanyLogo");
      if (!storedCompanyLogo || storedCompanyLogo !== company.logo) {
        localStorage.setItem("currentCompanyLogo", company.logo);
      }
    }

    // this sections sets documents, initial documents and space usage
    if (documentReferences && documentReferences.length > 0) {
      setInitialDocuments(documentReferences);
      store.dispatch(setDocuments(documentReferences));

      const docs = documentReferences;

      let finalSize = 0;

      if (docs.length > 1) {
        const arrayOfSizes = docs.map((doc: any) => {
          if (doc.size) return parseInt(doc.size);
          return 0;
        });

        const totalSpaceUsage = arrayOfSizes.reduce(
          (accum: any, newValue: any) => {
            return accum + newValue;
          }
        );
        finalSize = totalSpaceUsage;
      } else if (docs.length === 1) {
        finalSize = parseInt(docs[0].size);
      }
      store.dispatch(setSpaceUsage(finalSize));
    } else if (documentReferences && documentReferences.length === 0) {
      setInitialDocuments([]);
      store.dispatch(setDocuments([]));
    }

    // thsi section sets folders and initial folders
    if (folders && folders.length > 0) {
      const sortedArray = folders.sort((a: any, b: any) => {
        return b.folderId - a.folderId;
      });
      setInitialFolders(sortedArray);
      store.dispatch(setFolders(sortedArray));
    }

    // this section sets company custom docs
    if (companyCustomDocs && companyCustomDocs.length > 0) {
      store.dispatch(setCompanyDocuments(companyCustomDocs));
    }

    return true;
  
};
