import React, { useEffect, useState } from "react";
import CONSTANTS from "../../../Common/constants";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestion,
  faCheckCircle,
  faComment,
} from "@fortawesome/free-solid-svg-icons";

type InfoNotReadyWrapperProps = {
  children: any;
  question: any;
  onChange: any;
  currentAnswer: any;
  sectionId: any;
  currentSectionAnswers: any;
};

const InfoNotReadyWrapper: React.FC<InfoNotReadyWrapperProps> = ({
  children,
  question,
  onChange,
  currentAnswer,
  sectionId,
  currentSectionAnswers,
}) => {
  const [showInput, setShowInput] = useState(true);

  useEffect(() => {
    if (currentAnswer === CONSTANTS.EMPTY_ANSWER) {
      setShowInput(false);
    }
  }, []);

  return (
    <div className="info-not-ready-wrapper">
      {!showInput && (
        <div className="info-not-ready-text-box">
          <p className="info-not-ready-title ">
            <FontAwesomeIcon icon={faCheckCircle} className="margin-icon " />
            Respuesta Vacia
          </p>
          <p className="info-not-ready-sub-title f-small primary">
            *Agregaremos a tu documento (Word) un espacio para que completes
            este dato manualmente
          </p>
        </div>
      )}
      {showInput && children}
      <div
        className="info-not-ready-button b-secondary  b-secondary-hover"
        onClick={() => {
          if (showInput) {
            onChange({
              [sectionId]: {
                ...currentSectionAnswers,
                [question.id]: {
                  question: question.question,
                  answer: CONSTANTS.EMPTY_ANSWER,
                },
              },
            });
          } else {
            onChange({
              [sectionId]: {
                ...currentSectionAnswers,
                [question.id]: {
                  question: question.question,
                  answer: "",
                },
              },
            });
          }
          setShowInput((prev) => !prev);
        }}
      >
        <FontAwesomeIcon
          icon={showInput ? faQuestion : faComment}
          className="margin-icon "
        />
        {showInput ? "Aún no tengo esta respuesta" : "Responder a la pregunta"}
      </div>
    </div>
  );
};

export default InfoNotReadyWrapper;
