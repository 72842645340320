import React, { useEffect } from "react";
import "../../styles.css";

type PathOptionsProps = {
  question: any;
  onChange: any;
  currentAnswer: any;
  sectionId: any;
  currentSectionAnswers: any;
  setDependantQuestions: any;
};

const PathOptions: React.FC<PathOptionsProps> = ({
  question,
  onChange,
  currentAnswer,
  sectionId,
  currentSectionAnswers,
  setDependantQuestions,
}) => {
  useEffect(() => {
    question.options.forEach((e: any) => {
      if (currentAnswer === e.name) {
        setDependantQuestions(e.questions);
      }
    });
  }, [currentAnswer, question.options, setDependantQuestions]);
  return (
    <div className="question-format-form">
      <select
        required
        value={currentAnswer}
        className="question-format-input"
        onChange={(e) => {
          onChange({
            [sectionId]: {
              ...currentSectionAnswers,
              [question.id]: {
                question: question.question,
                answer: e.target.value,
              },
            },
          });
        }}
      >
        <option value="">Seleccionar</option>
        {question.options.map((e: any) => {
          return <option value={e.name}>{e.name}</option>;
        })}
      </select>
    </div>
  );
};

export default PathOptions;
