import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import LogInButton from "../../Authentication/LogIn/LogInButton";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import MenuDrawer from "../MenuDrawer/MenuDrawer";
import { colorPallette, gradients } from "../../Common/theme";
import CompanyChanger from "./CompanyChanger";
import ProfilePic from "../../uiComponents/ProfilePic/ProfilePic";
import LogOutButton from "../../Authentication/LogOut/LogOutButton";
import { CompanyUser } from "../../redux/slices/masterData";
import { RootState } from "../../redux/store";
import { AvatarLetter } from "../../uiComponents/ProfilePic/AvatarLetter";

import "./Navbar.css";

export default function Navbar() {
  const history = useHistory();
  const { allCompanyUsers } = useSelector(
    (state: RootState) => state.masterData
  );
  const reduxUser = useSelector((state: RootState) => state.masterData.user);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { user, isAuthenticated } = useAuth0();

  const profilePic = allCompanyUsers.find(
    (u: CompanyUser) => reduxUser?.userId === u.userId
  )?.photoUrl;

  function handleDrawer() {
    setDrawerOpen((prev) => !prev);
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(".animated-box");
    hiddenElements.forEach((el) => observer.observe(el));
  }, [isAuthenticated]);

  const darkRoutes = [
    "/",
    "/contacto",
    "/suscripcion",
    "/productTour",
    "/support-config",
  ];

  return (
    <div
      className="header b-white"
      id="header"
      style={
        darkRoutes.includes(window?.location?.pathname)
          ? {
              background: gradients.dark,
              boxShadow: "none",
            }
          : {}
      }
    >
      <div className="navbar-dual-box">
        <img
          src={process.env.PUBLIC_URL + "/images/andesdocs.png"}
          alt="logo-word"
          title="Logo Andes"
          className={`logo-word ${
            window.location.pathname === "/" ? "hidden" : ""
          }`}
          onClick={() => history.push("/")}
        ></img>

        {isAuthenticated &&
          localStorage.getItem("currentCompanyLogo") &&
          !darkRoutes.includes(window?.location?.pathname) && (
            <div className={`navbar-company-divide-wrapper animated-box`}>
              <div className="navbar-company-divide b-primary" />
              <div className="navbar-company-divide-2" />
            </div>
          )}
        {isAuthenticated &&
          localStorage.getItem("currentCompanyLogo") &&
          !darkRoutes.includes(window?.location?.pathname) && (
            <img
              src={
                localStorage.getItem("currentCompanyLogo") ||
                "default-image-url.png"
              }
              alt="logo-word"
              title="Logo Andes 2"
              className="navbar-logo-company animated-box"
              onClick={() => history.push("/")}
            ></img>
          )}
      </div>
      {isAuthenticated &&
        user &&
        reduxUser &&
        reduxUser.internalUser &&
        !darkRoutes.includes(window?.location?.pathname) && <CompanyChanger />}
      <div className="navbar">
        {!isAuthenticated && (
          <>
            <div
              onClick={() => history.push("/contacto")}
              className="nav-element"
            >
              Contacto
            </div>
            <div
              onClick={() => history.push("/suscripcion")}
              className="nav-element"
            >
              Planes
            </div>
            <LogInButton
              className="nav-element-login mobile-disapear"
              label="Registrarse"
              style={{
                backgroundColor: colorPallette.dark,
                color: colorPallette.white,
              }}
            />
            <LogInButton
              className="nav-element-login b-primary mobile-disapear"
              label="Iniciar Sesión"
            />
          </>
        )}

        {isAuthenticated && (
          <>
            <div
              className="nav-element"
              onClick={() => history.push("/dashboard/finished")}
            >
              Dashboard
            </div>
            <div
              onClick={() => {
                if (!isAuthenticated) {
                  history.push("/document-selector");
                } else {
                  history.push("/dashboard/documents");
                }
              }}
              className="nav-element"
            >
              Documentos
            </div>
            <LogOutButton
              sx={{
                width: "100%",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
                fontWeight: "bold",
                cursor: "pointer",
                transition: "all 0.2s",
                backgroundColor: colorPallette.primary,
                marginLeft: "20px",

                mr: "30px",
                mb: 1,
                "&:hover": {
                  backgroundColor: colorPallette.yellow,
                  color: colorPallette.dark,
                  "& .MuiTypography-root": {
                    color: colorPallette.dark,
                  },
                },
              }}
            />
            {(profilePic || reduxUser?.name) && (
              <div
                onClick={() => history.push("/dashboard/profile")}
                className="mobile-disapear"
              >
                <div className="nav-user">
                  {profilePic ? (
                    <ProfilePic
                      photoUrl={profilePic}
                      editable={false}
                      size={50}
                    />
                  ) : (
                    <AvatarLetter
                      name={reduxUser?.name || ""}
                      editable={false}
                    />
                  )}{" "}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <MenuDrawer
        isOpen={drawerOpen}
        toggleDrawer={handleDrawer}
        isAuthenticated={isAuthenticated}
      />

      <i onClick={handleDrawer} className="fas fa-bars"></i>
    </div>
  );
}
