import React from "react";
import Button from "../../uiComponents/Button/Button";

const ErrorScreen = () => {
  return (
    <div style={{ padding: 60 }}>
      <h1 className="grey">
        Error <i className="fas fa-times primary margin-l"></i>
      </h1>
      <p>Ha habido un error por favor intentelo nuevamente</p>
      <Button to="/" label="Volver al Home" center className="margin-v-l" />
    </div>
  );
};

export default ErrorScreen;
