import {
  faSave,
  faSignature,
  faDownload,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Image from "../../uiComponents/Image/Image";
import { modalOpen } from "../../Utils/modalMethods";
import "./DocxToolbar.styles.css";

type Props = {
  document: any;
  onDownload: () => void;
  updateDocument: (bool: boolean) => void;
  sendToEsign: () => void;
};

const DocxToolbar: React.FC<Props> = ({
  document,
  onDownload,
  updateDocument,
  sendToEsign,
}) => {
  const [docName, setDocName] = useState<string>("");

  const { editedDocumentName } = useSelector(
    (state: RootState) => state.triggers
  );

  useEffect(() => {
    if (editedDocumentName) {
      setDocName(editedDocumentName);
    } else if (document.fileName) {
      setDocName(document.fileName);
    }
  }, [document, editedDocumentName]);

  return (
    <div className="docx-toolbar">
      <div className="docx-toolbar-title">
        <Image name="word-icon.png" className="docx-toolbar-word" />
        <div className="docx-toolbar-name">{docName}</div>
        <div className="docx-toolbar-extension">Word</div>
      </div>
      <div className="docx-toolbar-buttons">
        <button onClick={() => updateDocument(false)}>
          <FontAwesomeIcon icon={faSave} className="docx-toolbar-icon" />
          Guardar
        </button>
        {/* <button>
          <FontAwesomeIcon icon={faCopy} className="docx-toolbar-icon" />
          Guardar Como
        </button> */}
        <button onClick={sendToEsign}>
          <FontAwesomeIcon icon={faSignature} className="docx-toolbar-icon" />
          Firmar
        </button>
        <button onClick={onDownload}>
          <FontAwesomeIcon icon={faDownload} className="docx-toolbar-icon" />
          Descargar
        </button>
        <button
          onClick={() => {
            modalOpen({
              documentDetailModal: {
                show: true,
                document: document,
              },
            });
          }}
        >
          <FontAwesomeIcon icon={faPencil} className="docx-toolbar-icon" />
          Detalles
        </button>
      </div>
    </div>
  );
};

export default DocxToolbar;
