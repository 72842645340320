import React from "react";
import "./styles.css";

type AvailableSpaceProps = {
  spaceUsage: number;
};

const AvailableSpace: React.FC<AvailableSpaceProps> = ({ spaceUsage }) => {
  const calculateWidth = (space: number) => {
    const Mb = Math.round(spaceUsage / 10000) / 100;
    const toLimit = Mb / 1000;
    if (toLimit > 0.1) return toLimit;
    return 0.1;
  };

  return (
    <div className="available-space-wrapper">
      <div className="available-space-line-back" style={{ width: 300 }}>
        <div
          className="available-space-line-inner b-primary"
          style={{ width: 300 * calculateWidth(spaceUsage) }}
        ></div>
      </div>
      <div className="available-space-text">
        Espacio Cloud utilizado:{" "}
        <span className="primary">
          {spaceUsage ? Math.round(spaceUsage / 10000) / 100 : 0} MB / 1000 MB{" "}
        </span>
      </div>
    </div>
  );
};

export default AvailableSpace;
