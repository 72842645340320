import React from "react";
import Text from "../../../../../../uiComponents/Text";
import "./styles.css";

type StatusBarProps = {
  progress: any;
};

const StatusBar: React.FC<StatusBarProps> = ({ progress }) => {
  return (
    <div className="status-bar-div">
      <div className="status-bar-outer">
        <div className="status-bar-mid b-white">
          <div
            style={{ width: 152 * progress }}
            className="status-bar-inner b-secondary"
          ></div>
        </div>
      </div>
      <Text>
        <span className="primary">{Math.ceil(progress * 100)}% </span>Completado
      </Text>
    </div>
  );
};

export default StatusBar;
