import React, { useState } from "react";
import IconButton from "../../../../uiComponents/IconButton/IconButton";
import Modal from "../../../../uiComponents/Modal/Modal";
import { useAuth0 } from "@auth0/auth0-react";
import { resetModals, setModal } from "../../../../redux/modal/modal.actions";
import Loader from "react-loader-spinner";
import { APIPost } from "../../../../Services/authenticated";
import { connect } from "react-redux";

import "./styles.css";

type NewFolderModalProps = {
  resetModals: any;
  setModal: any;
  data: any;
};

const NewfolderModal: React.FC<NewFolderModalProps> = ({
  resetModals,
  setModal,
  data,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [folderName, setFolderName] = useState<string | null>(null);

  const { getAccessTokenSilently } = useAuth0();

  const onSaveFolder = async (newFolderName: string) => {
    setIsLoading(true);
    const payload = { folderName: newFolderName };
    try {
      const accessToken = await getAccessTokenSilently();
      await APIPost("/folders/create-folder", accessToken, payload);
      if (!data.document) {
        window.location.reload();
      }
      if (data.document) {
        setModal({
          folderModal: {
            show: true,
            document: data.document,
          },
          newFolderModal: {
            show: false,
            document: null,
          },
        });
      }
    } catch (e) {
      setIsLoading(false);
      alert("Error, intentelo nuevamente");
    }
  };

  return (
    <Modal>
      <div className="new-folder-modal-body">
        <i
          className="far fa-window-close new-folder-modal-close"
          onClick={() => resetModals()}
        ></i>
        <p className="bold new-folder-modal-title">Nueva Carpeta</p>
        {isLoading && (
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
            }}
          >
            <Loader type="Bars" color="#e8eeff" height={40} width={40} />
            <span>Cargando ...</span>
          </div>
        )}
        {!isLoading && (
          <div>
            <p className="new-folder-modal-input-label primary">
              Nombre de la carpeta:
            </p>
            <input
              type="text"
              className="new-folder-modal-input"
              maxLength={40}
              onChange={(e) => {
                setFolderName(e.target.value);
              }}
            />
            <IconButton
              label="Crear Carpeta"
              icon="fas fa-plus"
              className="new-folder-modal-create"
              color="secondary"
              onClick={() => {
                if (folderName) {
                  onSaveFolder(folderName);
                } else {
                  alert("El nombre de la carpeta no puede estar vacío");
                }
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    data: state.modal.newFolderModal,
  };
};

const mapDispatchToProps = {
  resetModals,
  setModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewfolderModal);
