import { useEffect, useState } from "react";
import "../styles.css";
import "./styles.css";

type CardProps = { item: any; handleClick: any; currentAnswer: any };

export const Card: React.FC<CardProps> = ({
  item,
  handleClick,
  currentAnswer,
}) => {
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    // this bit of code checks whether the item was already selected to mark it as so.
    if (currentAnswer === item) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [currentAnswer]);
  return (
    <div
      onClick={() => {
        setSelected((prev) => !prev);
        if (!selected) {
          handleClick(item);
        } else {
          handleClick("");
        }
      }}
      className={` multiple-options-option-card purple multiple-options-option-card-${selected}`}
    >
      {!selected ? (
        <i className="far fa-square multiple-options-square primary"></i>
      ) : (
        <i className="far fa-check-square multiple-options-check primary"></i>
      )}
      {
        <input
          type="checkbox"
          className="big-options-checkbox"
          checked={selected}
          value={String(selected)}
          style={{ marginRight: 10 }}
          onChange={() => {
            if (!selected) {
              handleClick(item);
            } else {
              handleClick("");
            }
          }}
        />
      }
      {item}
    </div>
  );
};
