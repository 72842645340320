import React from "react";
import "./styles.css";

type OptionCardProps = {
  selected: boolean;
  handleClick: () => void;
  id: string | number;
};

const OptionCard: React.FC<OptionCardProps> = ({
  selected,
  handleClick,
  id,
}) => {
  return (
    <div
      onClick={() => {
        handleClick();
      }}
      className={` e-sign-option-option-card b-secondary e-sign-option-option-card-${selected}`}
    >
      {!selected ? (
        <i className="far fa-square e-sign-option-square"></i>
      ) : (
        <i className="far fa-check-square e-sign-option-check"></i>
      )}
      {id}
    </div>
  );
};

export default OptionCard;
